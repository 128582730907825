import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { CombineColumnsConfiguration, ReportColumn } from "@/types/evaluate";
import { formatInputVariable } from "@/utils/evaluate";
import { PlusIcon, XIcon } from "@heroicons/react/outline";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { useMemo, useState } from "react";
import { useBandaid } from "../utils/BandaidContext";
import { ModalStep } from "./ModalRouter";

const CombineColumnsBuilder = ({
  columnData,
  availableColumns,
  navigatePrevious,
  saveColumnAction,
  editable,
}: {
  columnData: Partial<ReportColumn>;
  availableColumns: ReportColumn[];
  navigatePrevious: () => void;
  saveColumnAction: (newColumnDataArg: ReportColumn) => void;
  editable: boolean;
}) => {
  const bandaid = useBandaid();
  const [name, setName] = useState<string | null>(columnData.name || null);
  const [columnNames, setColumnNames] = useState<string[]>(
    columnData.configuration?.sources || [null],
  );

  const [dataIsValid, setDataIsValid] = useState<{
    name: boolean;
    columns: boolean;
  }>({
    name: true,
    columns: true,
  });

  const addColumnName = () => {
    setColumnNames([...columnNames, ""]);
    setDataIsValid({ ...dataIsValid, columns: true });
  };

  const removeColumnName = (index: number) => {
    const newColumnNames = [...columnNames];
    newColumnNames.splice(index, 1);
    setColumnNames(newColumnNames);
  };

  const updateColumnName = (index: number, newName: string) => {
    const newColumnNames = [...columnNames];
    newColumnNames[index] = newName;
    setColumnNames(newColumnNames);
  };

  const saveEndpointAction = () => {
    if (!name) {
      setDataIsValid({ name: false, columns: true });
      return;
    }
    if (
      columnNames.some((columnName) => columnName === null || columnName === "")
    ) {
      setDataIsValid({ name: true, columns: false });
      return;
    }

    setDataIsValid({ name: true, columns: true });

    saveColumnAction({
      ...columnData,
      name: name,
      configuration: {
        sources: columnNames,
      } as CombineColumnsConfiguration,
    } as ReportColumn);
  };

  const notYetChosenColumns = useMemo(() => {
    return availableColumns.filter(
      (column) => !columnNames.includes(column.name),
    );
  }, [columnNames, availableColumns]);

  return (
    <ModalStep
      navigatePrevious={navigatePrevious}
      navigateNext={saveEndpointAction}
      nextButtonText={editable ? "Save Step" : "Done"}
    >
      <div className="grid grid-cols-3 items-center gap-4">
        <div className="col-span-3">
          <div className="text-lg font-semibold">Combine Columns</div>
          <div className="max-w-md text-sm text-gray-500">
            <div className="max-w-md text-sm text-gray-500">
              This step combines the values of {bandaid ? "nodes" : "columns"}{" "}
              into a single column into an object such that keys are the column
              names and values are the column values.
            </div>
          </div>
        </div>
        <label
          htmlFor="name"
          className="col-span-1"
          style={{ display: bandaid ? "none" : undefined }}
        >
          Column name:
        </label>
        <input
          id="name"
          className={`col-span-2 rounded border ${
            !dataIsValid.name ? "border-red-500" : "border-gray-300"
          } px-2 py-1 disabled:cursor-not-allowed disabled:bg-gray-50`}
          value={name || ""}
          onChange={(e) => setName(e.target.value)}
          disabled={!editable}
          style={{ display: bandaid ? "none" : undefined }}
        />
        {!dataIsValid.name && (
          <>
            <div className="col-span-1 -mt-4">&nbsp;</div>
            <div className="col-span-2 -mt-4 text-left text-xs text-red-500">
              Name is required
            </div>
          </>
        )}
        <div className="col-span-1 flex flex-col justify-between">
          <div>Combine {bandaid ? "nodes" : "columns"}:</div>
          {!dataIsValid.columns && (
            <div className="py-2 text-xs text-red-500">
              <ExclamationTriangleIcon className="mr-1 inline-block h-4 w-4" />
              {bandaid ? "Node" : "Column"} input is required
            </div>
          )}
        </div>
        <div className="col-span-2 flex flex-col gap-y-1">
          {columnNames.map((columnName, index) => (
            <div
              key={index}
              className="flex items-center justify-between gap-x-1"
            >
              <DropdownMenu>
                <DropdownMenuTrigger disabled={!editable} className="w-full">
                  {columnName || (
                    <span className="font-normal text-gray-500">
                      Select a {bandaid ? "node" : "column"}...
                    </span>
                  )}
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  {notYetChosenColumns.map((column) => (
                    <DropdownMenuItem
                      key={column.name}
                      onSelect={() => updateColumnName(index, column.name)}
                    >
                      {formatInputVariable(column.column_type, column.name)}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
              <XIcon
                onClick={() => removeColumnName(index)}
                className="h-5 w-5 cursor-pointer text-red-500"
              />
            </div>
          ))}
          <div className="py-1">
            <Button
              variant="secondaryLightOutline"
              size="sm"
              onClick={addColumnName}
            >
              Add {bandaid ? "node" : "column"}
              <PlusIcon className="ml-1 h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
    </ModalStep>
  );
};

export default CombineColumnsBuilder;
