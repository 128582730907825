import { Edge, Node } from "@xyflow/react";
import { positionNodes } from "../../../utils";
import { useWorkflow } from "../../workflow-context";
import { useMemo } from "react";

const useInitialNodes = (readonly = false) => {
  const { nodes, edges: workflowEdges } = useWorkflow();

  const positionedNodesAndEdges = useMemo(() => {
    const sortedNodes = [...nodes].sort((a, b) => a.name.localeCompare(b.name));
    const positionedNodes = positionNodes(sortedNodes).map((node) => ({
      ...node,
      draggable: !readonly,
    }));
    const edges = workflowEdges.map((edge) => ({
      id: edge.id,
      source: edge.source_node_name,
      target: edge.target_node_name,
    }));

    return [positionedNodes, edges] as [Node[], Edge[]];
  }, [nodes, readonly, workflowEdges]);

  return positionedNodesAndEdges;
};

export default useInitialNodes;
