import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { MathOperatorConfiguration, ReportColumn } from "@/types/evaluate";
import { formatInputVariable } from "@/utils/evaluate";
import { useEffect, useState } from "react";
import { useBandaid } from "../../utils/BandaidContext";
import { ModalStep } from "../ModalRouter";

const MathOperatorBuilder = ({
  columnData,
  availableColumns,
  navigatePrevious,
  saveColumnAction,
  editable,
}: {
  columnData: Partial<ReportColumn>;
  availableColumns: ReportColumn[];
  navigatePrevious: () => void;
  saveColumnAction: (newColumnDataArg: ReportColumn) => void;
  editable: boolean;
}) => {
  const [name, setName] = useState<string | null>(columnData.name || null);
  const [operator, setOperator] = useState<"lt" | "le" | "gt" | "ge" | null>(
    columnData.configuration?.operator ?? null,
  );
  const [column, setColumn] = useState<string | null>(
    columnData.configuration?.sources[0] || null,
  );
  const [value, setValue] = useState<number | string | null>(
    columnData.configuration?.value ??
      (columnData.configuration?.sources?.length === 2
        ? columnData.configuration.sources[1]
        : null),
  );

  const [compareWithColumn, setCompareWithColumn] = useState(
    !!columnData.configuration?.sources[1],
  );

  useEffect(() => {
    if (columnData.configuration?.sources?.length === 2) {
      setCompareWithColumn(true);
      setValue(columnData.configuration.sources[1]);
    } else if (columnData.configuration?.value !== undefined) {
      setCompareWithColumn(false);
      setValue(columnData.configuration.value);
    }
  }, [columnData.configuration]);

  const [dataIsValid, setDataIsValid] = useState<{
    name: boolean;
    operator: boolean;
    column: boolean;
    value: boolean;
    secondColumn: boolean;
  }>({
    name: true,
    operator: true,
    column: true,
    value: true,
    secondColumn: true,
  });

  const saveEndpointAction = () => {
    if (!name) {
      setDataIsValid({
        name: false,
        operator: true,
        column: true,
        value: true,
        secondColumn: true,
      });
      return;
    } else if (!operator) {
      setDataIsValid({
        name: true,
        operator: false,
        column: true,
        value: true,
        secondColumn: true,
      });
      return;
    } else if (!column) {
      setDataIsValid({
        name: true,
        operator: true,
        column: false,
        value: true,
        secondColumn: true,
      });
      return;
    } else if (!compareWithColumn && !value) {
      setDataIsValid({
        name: true,
        operator: true,
        column: true,
        value: false,
        secondColumn: true,
      });
      return;
    } else if (compareWithColumn && !value) {
      setDataIsValid({
        name: true,
        operator: true,
        column: true,
        value: true,
        secondColumn: false,
      });
      return;
    }

    setDataIsValid({
      name: true,
      operator: true,
      column: true,
      value: true,
      secondColumn: true,
    });

    const configuration: MathOperatorConfiguration = {
      operator,
      value: compareWithColumn ? null : (value as number),
      sources: [column, compareWithColumn ? (value as string) : null],
    };

    saveColumnAction({
      ...columnData,
      name: name,
      configuration,
    } as ReportColumn);
  };

  const bandaid = useBandaid();

  return (
    <ModalStep
      navigatePrevious={navigatePrevious}
      navigateNext={saveEndpointAction}
      nextButtonText={editable ? "Save Step" : "Done"}
    >
      <div className="grid grid-cols-3 items-center gap-4">
        <div className="col-span-3">
          <div className="text-lg font-semibold">
            Configure Comparison Operator
          </div>
          <div className="max-w-md text-sm text-gray-500">
            This step will apply a comparison operator to a{" "}
            {bandaid ? "node" : "column"} in your report
          </div>
        </div>
        <div
          className="col-span-3 flex items-center gap-x-8"
          style={{ display: bandaid ? "none" : "flex" }}
        >
          <label htmlFor="name" className="mr-2">
            Column name:
          </label>
          <input
            id="name"
            className={`flex-grow rounded border ${
              !dataIsValid.name ? "border-red-500" : "border-gray-300"
            } px-2 py-1 disabled:cursor-not-allowed disabled:bg-gray-50`}
            value={name || ""}
            onKeyDown={(e) => {
              if (e.key === " ") {
                // Only prevent default and handle manually for spaces
                e.preventDefault();
                setName(name + " ");
              }
              // Let all other keys (including backspace) work normally
            }}
            onChange={(e) => setName(e.target.value)}
            disabled={!editable}
          />
        </div>
        {!dataIsValid.name && (
          <div className="col-span-3 -mt-4 text-left text-xs text-red-500">
            Name is required
          </div>
        )}
        <div className="col-span-3 grid grid-cols-3 items-center gap-2">
          <div className="flex flex-col items-center">
            <DropdownMenu>
              <DropdownMenuTrigger disabled={!editable} className="w-full">
                {column || (
                  <span className="font-normal text-gray-500">
                    {bandaid ? "Node..." : "Column..."}
                  </span>
                )}
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {availableColumns.map((column) => (
                  <DropdownMenuItem
                    key={column.name}
                    onSelect={() => setColumn(column.name)}
                  >
                    {formatInputVariable(column.column_type, column.name)}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
            {!dataIsValid.column && (
              <div className="text-left text-xs text-red-500">
                {bandaid ? "Node" : "Column"} is required
              </div>
            )}
          </div>
          <div className="flex flex-col items-center">
            <DropdownMenu>
              <DropdownMenuTrigger className="w-full">
                {types.find((t) => t.value === operator)?.label || (
                  <span className="font-normal text-gray-500">Operator...</span>
                )}
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {types.map((type) => (
                  <DropdownMenuItem
                    key={type.value}
                    onSelect={() => setOperator(type.value)}
                  >
                    {type.label}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
            {!dataIsValid.operator && (
              <div className="text-left text-xs text-red-500">
                Operator is required
              </div>
            )}
          </div>
          <div className="col-span-1 flex flex-col items-center">
            {compareWithColumn ? (
              <DropdownMenu>
                <DropdownMenuTrigger disabled={!editable} className="w-full">
                  {value || (
                    <span className="font-normal text-gray-500">Column...</span>
                  )}
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  {availableColumns.map((column) => (
                    <DropdownMenuItem
                      key={column.name}
                      onSelect={() => setValue(column.name)}
                    >
                      {formatInputVariable(column.column_type, column.name)}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            ) : (
              <input
                id="value"
                placeholder="Value"
                className={`w-full rounded border font-normal ${
                  !dataIsValid.value ? "border-red-500" : "border-gray-300"
                } px-2 py-1.5 disabled:cursor-not-allowed disabled:bg-gray-50`}
                value={value || ""}
                step="0.01"
                type="number"
                onChange={(e) => setValue(parseFloat(e.target.value))}
                disabled={!editable}
              />
            )}
            {!dataIsValid.value && !compareWithColumn && (
              <div className="text-left text-xs text-red-500">
                Value is required
              </div>
            )}
            {!dataIsValid.secondColumn && compareWithColumn && (
              <div className="text-left text-xs text-red-500">
                Second column is required
              </div>
            )}
          </div>
        </div>
        <div className="col-span-3 flex items-center space-x-2">
          <input
            type="checkbox"
            id="compareWithColumn"
            checked={compareWithColumn}
            onChange={(e) => setCompareWithColumn(e.target.checked)}
          />
          <label
            htmlFor="compareWithColumn"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Compare with column
          </label>
        </div>
      </div>
    </ModalStep>
  );
};

const types = [
  { value: "lt", label: "<" },
  { value: "le", label: "<=" },
  { value: "gt", label: ">" },
  { value: "ge", label: ">=" },
] as const;

export default MathOperatorBuilder;
