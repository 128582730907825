import { FC } from "react";
import { InformationCircleIcon } from "@heroicons/react/solid";

const RIZA_DOCS_URL =
  "https://docs.riza.io/reference/limits#execution-environment-limitations";

interface RuntimeDetailsProps {
  language: string;
}

const RuntimeDetails: FC<RuntimeDetailsProps> = ({ language }) => {
  const handleShowMore = () => {
    window.open(RIZA_DOCS_URL, "_blank");
  };

  const runtimeDescriptions: Record<string, string> = {
    PYTHON: "The Python runtime runs Python 3.12.0.",
    JAVASCRIPT:
      "The JavaScript runtime is built on Mozilla's SpiderMonkey engine.",
  };

  const runtimeText = runtimeDescriptions[language] || "Unknown runtime.";

  return (
    <div>
      {runtimeText}{" "}
      <button
        className="inline-flex items-center gap-1 text-blue-500 hover:text-blue-600 hover:underline"
        onClick={handleShowMore}
      >
        Learn more
        <InformationCircleIcon className="h-5 w-5" />
      </button>
    </div>
  );
};

export default RuntimeDetails;
