import { WorkflowNodeStatus } from "@/components/Workflows/types";
import { getEdgeColor } from "@/components/Workflows/utils";
import { useWorkflow } from "@/components/Workflows/WorkflowCanvas/workflow-context";
import { getStatusColor } from "../utils";

const useEdgeStyle = (node_id: string, edge_id: string) => {
  const workflow = useWorkflow();
  const status = workflow.getNodeById(node_id)?.status;
  const edge = workflow.getEdge(edge_id);
  const isEdgeSelected = workflow.isEdgeSelected(edge_id);
  const edgeStatusColor = getStatusColor(edge?.status);

  const isNotDefaultEdgeStatusColor =
    edgeStatusColor !== "#2563EB" && !isEdgeSelected;

  return isNotDefaultEdgeStatusColor
    ? edgeStatusColor
    : getEdgeColor(
        status as WorkflowNodeStatus,
        workflow.isEdgeSelected(edge_id),
      );
};
export default useEdgeStyle;
