import { action, makeObservable, observable, transaction } from "mobx";
import { SchemaEditorMode } from "./types";
import SchemaParameterStore, {
  ISchemaParameter,
} from "./SchemaParameter/schema-parameter-store";
import {
  convertJsonToParameter,
  stripDummyFields,
  validatePresetFields,
} from "./utils";

class SchemaEditorStore {
  @observable mode: SchemaEditorMode = "interactive";
  @observable name: string = "";
  @observable description: string = "";
  @observable strict: boolean = false;
  @observable allowAdditionalProperties: boolean = false;
  @observable schemaParameters: ISchemaParameter[] = [];
  @observable initialJSONSchema: object = {};
  @observable isStandardSchema: boolean = true;
  private _readonly: boolean = false;
  private _isFunction: boolean = false;

  constructor(
    initialJSONSchema?: object,
    isFunction = false,
    readonly = false,
  ) {
    makeObservable(this);
    this._readonly = readonly;
    this._isFunction = isFunction;
    this.initialJSONSchema = initialJSONSchema || {};
    this.applyJsonToState(this.initialJSONSchema);

    if (
      initialJSONSchema &&
      JSON.stringify(this.definition, null, 2) !==
        JSON.stringify(this.initialJSONSchema, null, 2) // if there is a difference between the base JSON schema and the parsed, fallback to default JSON schema.
    ) {
      this.isStandardSchema = false;

      this.applyJsonToState(initialJSONSchema, false);
    }
  }

  @action setAutomatic(value: boolean) {
    transaction(() => {
      if (value) this.applyJsonToState(this.initialJSONSchema);
      this.isStandardSchema = value;
    });
  }

  get isFunction() {
    return this._isFunction;
  }

  get isReadonly() {
    return this._readonly;
  }

  @action
  deleteParameter(parameterToDelete: ISchemaParameter) {
    // Handle top-level parameters
    this.schemaParameters = this.schemaParameters.filter(
      (param) => param !== parameterToDelete,
    );

    // Handle nested parameters
    this.schemaParameters.forEach((param) => {
      this.deleteNestedParameter(param, parameterToDelete);
    });
  }

  @action setDescription(description: string) {
    this.description = description;
  }

  @action setName(name: string) {
    this.name = name;
  }

  @action setStrict(strict: boolean) {
    this.strict = strict;
  }

  @action setAllowAdditionalProperties(allowAdditionalProperties: boolean) {
    this.allowAdditionalProperties = allowAdditionalProperties;
  }

  @action toggleMode() {
    if (this.mode === "interactive") {
      this.initialJSONSchema = this.definition;
    }
    this.mode = this.mode === "interactive" ? "json" : "interactive";
  }

  @action toggleAllowAdditionalProperties() {
    this.allowAdditionalProperties = !this.allowAdditionalProperties;
  }

  @action toggleStrict() {
    this.strict = !this.strict;
  }

  @action
  addParameter() {
    const newParam = new SchemaParameterStore(
      0,
      false,
      new SchemaParameterStore(-1),
    );
    newParam.setDepth(0);
    this.schemaParameters.push(newParam);
  }

  private deleteNestedParameter(
    currentParam: ISchemaParameter,
    parameterToDelete: ISchemaParameter,
  ) {
    if (Array.isArray(currentParam.content)) {
      currentParam.content = currentParam.content.filter(
        (param) => param !== parameterToDelete,
      );

      // Recursively check nested parameters
      currentParam.content.forEach((param) => {
        this.deleteNestedParameter(param, parameterToDelete);
      });
    }
  }

  @action
  applyJsonToState(jsonSchema: Record<string, any>, automatic = true) {
    if (!automatic) {
      this.initialJSONSchema = jsonSchema;
      this.isStandardSchema = false;
      return;
    }

    jsonSchema = stripDummyFields(jsonSchema);

    if (
      !validatePresetFields(jsonSchema, [
        "items",
        "name",
        "description",
        "title",
        "type",
        "required",
        "properties",
        "schema",
        "parameters",
        "additionalProperties",
        "strict",
        "enums",
      ]) &&
      !this.isStandardSchema
    ) {
      this.initialJSONSchema = jsonSchema;
      return;
    } else if (!this.isStandardSchema) this.isStandardSchema = true;

    const topLevel = jsonSchema.parameters || jsonSchema.schema;
    this.name = jsonSchema.name || "";
    this.description = jsonSchema.description || "";
    this.strict = jsonSchema.strict || false;
    if (topLevel) {
      const parent = new SchemaParameterStore(-1);

      this.schemaParameters =
        (topLevel.properties &&
          Object.entries(topLevel.properties).map(
            ([key, value]: [string, any]) => {
              const param = convertJsonToParameter({ ...value }, parent);
              param.name = key;
              param.setDepth(0);
              return param;
            },
          )) ||
        [];
      parent.setRequired(
        this.schemaParameters.filter(
          (p) => topLevel.required?.includes(p.name),
        ),
      );
      this.initialJSONSchema = jsonSchema;
    }
  }

  get definition() {
    if (!this.isStandardSchema) return this.initialJSONSchema;
    // Create base schema structure
    const schemaObj = {
      type: "object",
      properties: {} as Record<string, any>,
      required: [] as string[],
      additionalProperties: this.allowAdditionalProperties,
    };

    // Process each parameter (propogate additionalProperties from editor to nested parameters)
    for (const param of this.schemaParameters) {
      // Add parameter definition to properties
      schemaObj.properties[param.name] = {
        ...JSON.parse(
          JSON.stringify(param.schema).replace(
            /"additionalProperties":\s*(true|false)/g,
            `"additionalProperties":${this.allowAdditionalProperties}`,
          ),
        ),
      };

      // Track required parameters
      if (param.required) {
        schemaObj.required.push(param.name);
      }
    }

    // Return complete schema definition
    return {
      name: this.name,
      description: this.description,
      [this._isFunction ? "parameters" : "schema"]: schemaObj,
      strict: this.strict,
    };
  }
}

export default SchemaEditorStore;
