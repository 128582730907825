import { ChangeEvent, FC, FormEvent, useState } from "react";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useAuth } from "@/context/auth-context";
import { ToastType } from "@/enums";
import { useEditDatasetRow } from "@/queries";
import { displayErrorToast, displayToast } from "@/utils/toast";
import { Textarea } from "./ui/textarea";

type EditDatasetRowModalProps = {
  datasetRowId: number;
  setOpen: (isOpen: boolean) => void;
  variables: Record<string, any>;
};

const EditDatasetRowModal: FC<EditDatasetRowModalProps> = ({
  datasetRowId,
  setOpen,
  variables,
}) => {
  const initialData = Object.fromEntries(
    Object.entries(variables).map(([key, value]) => [
      key,
      typeof value === "object"
        ? JSON.stringify(value, null, 2)
        : String(value),
    ]),
  );

  const [data, setData] = useState<any>(initialData);
  const authContext = useAuth();
  const userToken = authContext!.userToken;
  const { mutateAsync: editDatasetRow, isLoading } = useEditDatasetRow(
    userToken!,
  );

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const processedData = Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
        try {
          return [key, JSON.parse(value as string)];
        } catch {
          return [key, value];
        }
      }),
    );

    try {
      const response = await editDatasetRow({
        datasetRowId,
        variables: processedData,
      });

      if (response.success) {
        displayToast("Row updated", ToastType.success);
      } else {
        displayErrorToast(
          response.error || "There was an error updating the row",
        );
      }

      setOpen(false);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  const renderFormInputs = () => {
    return Object.keys(initialData).map((variableName) => (
      <div key={variableName} className="mb-4">
        <label
          className="block text-sm font-medium text-gray-700"
          htmlFor={variableName}
        >
          {variableName}
        </label>
        <Textarea
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          id={variableName}
          name={variableName}
          onChange={handleChange}
          value={data[variableName]}
        />
      </div>
    ));
  };

  return (
    <Dialog onOpenChange={handleClose} open={true}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Row</DialogTitle>
        </DialogHeader>
        <div className="max-h-[60vh] overflow-y-auto">
          <form onSubmit={handleSubmit}>
            {renderFormInputs()}
            <div className="mt-4 text-center">
              <Button isLoading={isLoading} type="submit">
                Submit
              </Button>
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditDatasetRowModal;
