import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { usePromptLabels } from "@/queries";

export const useLabels = ({ promptId }: { promptId: number }) => {
  const user = useUser();
  const workspaceId = user?.activeWorkspaceId!;
  const auth = useAuth();
  const userToken = auth?.userToken!;
  const { data, refetch } = usePromptLabels(userToken, workspaceId, promptId);
  return { data: data ?? [], refetch };
};
