import { Button } from "@/components/ui/button";
import { useEffect, useState } from "react";
import useLocalStorage from "react-use-localstorage";

import workflows from "../assets/new_feature_popup/workflows.gif";
import codeExecution from "../assets/new_feature_popup/code-execution.png";

const featureVersion = "11-26-2024";

export default function NewFeatureModal() {
  const [lastSeenFeatureVersion, setLastSeenFeatureVersion] = useLocalStorage(
    "lastSeenFeatureVersion",
    "",
  );
  const [isOpen, setIsOpen] = useState(false);
  const [activeModal, setActiveModal] = useState(0);

  const modals: Array<{
    title: string;
    subtitle: string;
    image: string;
    link?: string;
    content?: string;
  }> = [
    {
      title: "Agents (Beta)",
      subtitle:
        "Build and version chains of prompts. Visualize complex workflows. Mix-and-match models.",
      image: workflows,
      link: "https://docs.promptlayer.com/why-promptlayer/workflows",
    },
    {
      title: "Code Execution",
      subtitle:
        "Run custom Python or Javascript code as a step in your eval pipelines.",
      image: codeExecution,
    },
  ];

  useEffect(() => {
    if (lastSeenFeatureVersion !== featureVersion) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [lastSeenFeatureVersion, setIsOpen]);

  const closeModal = () => {
    setLastSeenFeatureVersion(featureVersion);
  };

  const nextModal = () => {
    setActiveModal((prev) => (prev + 1) % modals.length);
  };

  const prevModal = () => {
    setActiveModal((prev) => (prev === 0 ? modals.length - 1 : prev - 1));
  };

  const renderCarouselControls = () => {
    if (modals.length <= 1) return null;

    return (
      <div className="flex items-center justify-between">
        <button
          className="text-2xl text-blue-500 hover:text-blue-600"
          onClick={prevModal}
        >
          &#8592;
        </button>
        <div className="flex space-x-2">
          {modals.map((_, index) => (
            <button
              key={index}
              className={`h-3 w-3 rounded-full focus:outline-none ${
                index === activeModal ? "bg-blue-500" : "bg-gray-300"
              }`}
              onClick={() => setActiveModal(index)}
            ></button>
          ))}
        </div>
        <button
          className="text-2xl text-blue-500 hover:text-blue-600"
          onClick={nextModal}
        >
          &#8594;
        </button>
      </div>
    );
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="fixed inset-0 bg-gray-800/30 backdrop-blur-sm"></div>
          <div className="relative z-10 mx-4 bg-white p-6 shadow-lg md:mx-auto md:w-full md:max-w-md md:rounded-lg lg:max-w-2xl">
            <h2 className="mb-4 border-b pb-2 text-2xl font-semibold">
              New features! 🍰
            </h2>
            <h2 className="mb-2 text-xl font-medium">
              {modals[activeModal].title}
              {modals[activeModal].link && (
                <a
                  target="_blank"
                  href={modals[activeModal].link}
                  className="cursor-pointer pl-2 text-xs text-blue-500 hover:text-blue-400"
                  rel="noreferrer"
                >
                  (Read the docs)
                </a>
              )}
            </h2>
            <h3 className="text-md">{modals[activeModal].subtitle}</h3>
            <img
              className="mx-auto mt-4 h-auto max-h-96 w-full object-contain"
              src={modals[activeModal].image}
              alt={modals[activeModal].title}
            />
            <p className="mb-6">{modals[activeModal].content}</p>
            {renderCarouselControls()}
            <div className="mt-6 flex justify-end">
              <Button onClick={closeModal}>Got it!</Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
