import InputVariables from "./InputVariables";
import RunWorkflow from "./RunWorkflow";
import UndoRedo from "./UndoRedo";

interface ControlsProps {
  readonly: boolean;
}

const Controls = ({ readonly }: ControlsProps) => {
  return (
    <div className="pointer-events-none absolute left-3 right-3 top-4 z-[1]  flex flex-col gap-y-4">
      <div className="flex justify-start">{!readonly && <RunWorkflow />}</div>
      <div className="flex flex-row justify-between">
        <div className="flex flex-row gap-x-6">
          {!readonly && <UndoRedo />}
          <InputVariables readonly={readonly} />
        </div>
      </div>
    </div>
  );
};

export default Controls;
