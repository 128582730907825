import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

import { CurlyBracesIcon } from "lucide-react";
import { observer } from "mobx-react-lite";
import { useWorkflow } from "../../../workflow-context";
import InputVariableConfigurator from "./InputVariableConfigurator";
import {
  ReadOnlyVariableList,
  TooltipVariableList,
} from "./InputVariablesList";

interface InputVariablesProps {
  readonly: boolean;
}

const InputVariables = ({ readonly }: InputVariablesProps) => {
  const { input_variables } = useWorkflow();
  const variableCount = Object.keys(input_variables).length;

  return (
    <Dialog>
      <TooltipProvider>
        <Tooltip defaultOpen={false}>
          <TooltipTrigger asChild>
            <DialogTrigger className="pointer-events-auto relative flex h-[36px] w-[36px] items-center justify-center rounded-md border-gray-100 bg-white shadow-md transition-colors duration-200 hover:bg-gray-50">
              <CurlyBracesIcon className="h-4 w-4 text-gray-600" />
              {variableCount > 0 && (
                <div className="absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full bg-blue-500 text-[10px] text-white">
                  {variableCount}
                </div>
              )}
            </DialogTrigger>
          </TooltipTrigger>
          <TooltipContent side="bottom">
            {variableCount > 0 ? (
              <TooltipVariableList variables={input_variables} />
            ) : (
              <div>No input variables defined</div>
            )}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <DialogContent className="w-full max-w-2xl">
        {readonly ? (
          <div className="h-full w-full overflow-auto rounded-lg bg-white p-6 shadow-sm">
            <h2 className="mb-2 text-2xl font-semibold text-gray-800">
              Input Variables
              <span className="ml-2 text-sm font-normal text-gray-500">
                (Read-only)
              </span>
            </h2>
            <p className="mb-6 text-sm text-gray-600">
              These are the input variables defined for this workflow.
            </p>
            {variableCount > 0 ? (
              <>
                <div className="mb-2 grid grid-cols-3 gap-4 rounded-t-lg bg-gray-100 p-3 text-sm font-medium text-gray-700">
                  <div>Name</div>
                </div>
                <ReadOnlyVariableList variables={input_variables} />
              </>
            ) : (
              <div className="flex h-[200px] items-center justify-center rounded-lg border-2 border-gray-300 bg-gray-50">
                <p className="text-center text-gray-500">
                  No input variables defined. Add some to get started!
                </p>
              </div>
            )}
          </div>
        ) : (
          <InputVariableConfigurator />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default observer(InputVariables);
