import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "@/components/ui/select";
import { observer } from "mobx-react-lite";
import { SCHEMA_PARAMETER_TYPES, SchemaParameterType } from "../types";

interface TypeSelectorProps {
  value: SchemaParameterType;
  onChange: (value: SchemaParameterType) => void;
  disabled?: boolean;
}
const TypeSelector = observer((props: TypeSelectorProps) => {
  return (
    <Select
      value={props.value}
      onValueChange={props.onChange}
      disabled={props.disabled}
    >
      <SelectTrigger>{props.value}</SelectTrigger>
      <SelectContent>
        {SCHEMA_PARAMETER_TYPES.map((type) => (
          <SelectItem key={type} value={type}>
            {type}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
});

export default TypeSelector;
