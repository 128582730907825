import { SchemaDefinition } from "@/types";
import { observer, useLocalObservable } from "mobx-react-lite";
import React from "react";

import { Button } from "../ui/button";
import CompareColumnLayout from "../ui/CompareColumnLayout";
import InteractiveEditor from "./InteractiveEditor";
import JsonEditor from "./JsonEditor";
import SchemaEditorStore from "./schema-editor-store";

const SchemaEditorStoreContext = React.createContext<SchemaEditorStore | null>(
  null,
);

export const SchemaEditorProvider = ({
  value,
  children,
}: {
  value: SchemaEditorStore;
  children: React.ReactNode;
}) => {
  return (
    <SchemaEditorStoreContext.Provider value={value}>
      {children}
    </SchemaEditorStoreContext.Provider>
  );
};

export const useSchemaEditor = () => {
  const schemaEditorStore = React.useContext(SchemaEditorStoreContext);
  if (!schemaEditorStore) {
    throw new Error(
      "useSchemaEditorStore must be used within a SchemaEditorProvider",
    );
  }
  return schemaEditorStore;
};

interface SchemaEditorProps {
  schema?: object;
  onSubmit?: (schema: SchemaDefinition) => void;
  isFunction?: boolean;
  readonly?: boolean;
  children?: React.ReactNode;
}

const SchemaEditor = ({
  schema,
  onSubmit,
  isFunction = false,
  children,
  readonly,
}: SchemaEditorProps) => {
  const schemaEditorStore = useLocalObservable(
    () => new SchemaEditorStore(schema, isFunction, readonly),
  );

  return (
    <SchemaEditorProvider value={schemaEditorStore}>
      <div className="flex h-full min-h-[600px] w-full flex-row gap-4 pb-6 ">
        <CompareColumnLayout
          defaultOpen={
            !schemaEditorStore.isStandardSchema
              ? { json: true }
              : {
                  interactive: true,
                  json: false,
                }
          }
          disableInteractive={!schemaEditorStore.isStandardSchema}
          columnNames={["Interactive Editor", "JSON Editor"]}
          leftComponent={<InteractiveEditor />}
          rightComponent={<JsonEditor />}
        />
        <div className="pointer-events-none fixed bottom-4 left-12 right-3 z-20 flex flex-row justify-end gap-3">
          <div className="pointer-events-auto flex gap-x-2">
            {children}
            {!readonly && (
              <Button
                size="lg"
                className="pointer-events-auto"
                onClick={() => {
                  onSubmit?.(schemaEditorStore.definition);
                }}
              >
                {isFunction ? "Add Function" : "Set Schema"}
              </Button>
            )}
          </div>
        </div>
      </div>
    </SchemaEditorProvider>
  );
};

export default observer(SchemaEditor);
