import LoadingSpinner from "@/components/LoadingSpinner";
import { ColumnType } from "@/types/evaluate";
import {
  Background,
  ConnectionLineType,
  ConnectionMode,
  Controls,
  MiniMap,
  ReactFlow,
  useEdgesState,
  useNodesState,
  useReactFlow,
  ViewportPortal,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { AnimatePresence, motion } from "framer-motion";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useWorkflow } from "../workflow-context";
import ContextMenu from "./ContextMenu";
import useContextMenu from "./hooks/useContextMenu";
import useCreateNodeOnEdgeDrop from "./hooks/useCreateNodeOnEdgeDrop";
import useExecutionStatus from "./hooks/useExecutionStatus";
import useInitialNodes from "./hooks/useInitialNodes";
import useStateMirroring from "./hooks/useStateMirroring";
import Node from "./Node";
import ConnectionLine from "./Node/ConnectionLine";
import Edge from "./Node/Edge";
import {
  CompletedNode,
  PendingNode,
  QueuedNode,
  RunningNode,
} from "./Node/NodeTypes";

const nodeTypes = {
  basic: Node,
  PENDING: PendingNode,
  COMPLETED: CompletedNode,
  RUNNING: RunningNode,
  QUEUED: QueuedNode,
  ...Object.values(ColumnType)
    .map((type) => ({ [type]: Node }))
    .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
};

const edgeTypes = {
  default: Edge,
};

interface CanvasContentProps {
  readonly?: boolean;
}

const CanvasContent = ({ readonly }: CanvasContentProps) => {
  const [initialNodes, initialEdges] = useInitialNodes(readonly);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const workflow = useWorkflow();
  const [isLoading, setIsLoading] = useState(true);

  const [ref, Preview, props] = useCreateNodeOnEdgeDrop([setNodes, setEdges]);
  const [[menuOpen, setMenuOpen], props2] = useContextMenu(ref);
  const { zoomOut } = useReactFlow();

  useEffect(() => {
    const checkLoading = async () => {
      await new Promise((resolve) => setTimeout(resolve, 250)); // Reduced artificial delay
      setIsLoading(!(workflow.version_id && workflow.nodes));
    };
    checkLoading();
  }, [workflow.version_id, workflow.nodes]);

  useEffect(() => {
    if (!isLoading) {
      requestAnimationFrame(() => {
        zoomOut({ duration: 450 }); // Reduced duration for faster response
      });
    }
  }, [isLoading, zoomOut]);

  const onNodeClick = useCallback(
    (_event: React.MouseEvent, node: any) => {
      if (menuOpen) setMenuOpen(null);

      const workflowNode = workflow.getNodeById(node.id);
      workflow.setActiveNode(workflowNode);
      workflow.openSidebar();
    },
    [menuOpen, setMenuOpen, workflow],
  );

  const onPaneClick = useCallback(() => {
    if (menuOpen) setMenuOpen(null);
    runInAction(() => {
      workflow.removeActiveNode();
      workflow.closeSidebar();
    });
  }, [menuOpen, setMenuOpen, workflow]);

  const handleOnNodesChange = useStateMirroring(onNodesChange);
  useExecutionStatus();

  const memoizedReactFlow = useMemo(
    () => (
      <ReactFlow
        nodes={nodes}
        edges={edges}
        ref={ref}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onNodesChange={readonly ? undefined : handleOnNodesChange}
        onEdgesChange={readonly ? undefined : onEdgesChange}
        onNodeClick={onNodeClick}
        connectionLineComponent={ConnectionLine}
        connectionMode={ConnectionMode.Strict}
        connectionLineType={ConnectionLineType.SmoothStep}
        fitViewOptions={{
          padding: 0.2,
          duration: 400, // Reduced duration for faster response
          nodes: workflow.rootNodes.length > 0 ? workflow.rootNodes : undefined,
        }}
        minZoom={(readonly && 0.3) || 0.15}
        maxZoom={(readonly && 0.75) || 1}
        proOptions={{ hideAttribution: true }}
        fitView
        onPaneClick={onPaneClick}
        edgesFocusable={!readonly}
        deleteKeyCode={null}
        nodesDraggable={!readonly}
        onNodeDrag={() => {
          if (menuOpen) setMenuOpen(null);
        }}
        nodesConnectable={!readonly}
        {...(readonly ? {} : { ...props, ...props2 })}
      >
        {!readonly && <MiniMap position="bottom-right" />}
        <Controls showInteractive={!readonly} />
        {menuOpen && (
          <ContextMenu {...menuOpen} onSelect={() => setMenuOpen(null)} />
        )}
        <Background gap={12} size={1} className="bg-gray-50" />
        {!readonly && <Preview />}
        {nodes.length === 0 && !readonly && workflow.version_id && (
          <ViewportPortal>
            <motion.div
              initial={{ opacity: 0, y: -20, scale: 0.9 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              transition={{ duration: 0.3, ease: "easeOut" }} // Reduced duration
              className="absolute inset-0 m-auto flex h-[200px] w-[450px] items-center justify-center rounded-lg border border-dashed border-gray-300 p-2 text-sm text-gray-400"
            >
              <svg
                className="mr-2 h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
              Right-click anywhere to add a new node
            </motion.div>
          </ViewportPortal>
        )}
      </ReactFlow>
    ),
    [
      nodes,
      edges,
      ref,
      readonly,
      handleOnNodesChange,
      onEdgesChange,
      onNodeClick,
      workflow.rootNodes,
      workflow.version_id,
      onPaneClick,
      props,
      props2,
      menuOpen,
      Preview,
      setMenuOpen,
    ],
  );

  return (
    <div className={`h-full w-full ${readonly ? "" : "pointer-events-auto"}`}>
      <AnimatePresence mode="wait">
        {isLoading ? (
          <motion.div
            key="loading"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.2, ease: "easeInOut" }} // Reduced duration
            className="flex h-full w-full items-center justify-center"
          >
            <LoadingSpinner />
          </motion.div>
        ) : (
          <motion.div
            key="content"
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.2, ease: "easeInOut" }} // Reduced duration
            className="h-full w-full"
          >
            {memoizedReactFlow}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default observer(CanvasContent);
