import { JSONSchema } from "@/types";
import { usePromptBlueprint } from "../../hooks";
import { modelConfigs } from "@/modelConfig";

const useJSONSchema = () => {
  const form = usePromptBlueprint();
  const metadata = form?.watch("metadata");
  const provider = metadata?.model?.provider;
  const model = metadata?.model?.name;

  if (!provider || !model) return false;
  if (!modelConfigs[provider] || !modelConfigs[provider][model]) return false;

  const modelConfig =
    modelConfigs[provider][model]?.params?.response_format?.options;

  const hasJSONSchema =
    modelConfig && Object.keys(modelConfig).includes("JSON Schema");

  return (
    (metadata?.model?.parameters?.response_format?.json_schema ||
      hasJSONSchema) ??
    (false as JSONSchema | false)
  );
};

export default useJSONSchema;
