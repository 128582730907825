import { EdgeCondition, WorkflowEdge } from "@/components/Workflows/types";
import Banner from "@/components/ui/banner";
import { PlusIcon } from "lucide-react";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { useWorkflow } from "../../../workflow-context";
import { useEdge } from "../edge-conditions-context";
import AndOrToggle from "./AndOrToggle";
import SingleCondition from "./SingleCondition";
import useEdgeCleanup from "./hooks/useEdgeCleanup";

export interface ConditionsProps {
  conditions: EdgeCondition[];
  onAddCondition: () => void;
  onUpdateCondition: (
    position: number,
    field: keyof EdgeCondition,
    value: any,
  ) => void;
  readonly?: boolean;
  onRemoveCondition: (conditionId: string) => void;
}

const MAX_WIDTH = 600;

const isConditionValid = (condition: EdgeCondition): boolean => {
  const { left_config, right_config } = condition;

  const hasInvalidStaticLeft =
    "static_value" in left_config && !left_config.static_value;
  const hasInvalidSourceLeft =
    "source" in left_config && !left_config.source.name;
  const hasInvalidSourceRight =
    "source" in right_config && !right_config.source.name;
  const hasInvalidStaticRight =
    "static_value" in right_config && !right_config.static_value;

  return !(
    hasInvalidStaticLeft ||
    hasInvalidSourceLeft ||
    hasInvalidSourceRight ||
    hasInvalidStaticRight
  );
};

const isEdgeComplete = (edge: WorkflowEdge) => {
  return edge.conditionals.every((condition) => isConditionValid(condition));
};

const Conditions = ({
  conditions,
  onAddCondition,
  onUpdateCondition,
  onRemoveCondition,
  readonly,
}: ConditionsProps) => {
  const edge = useEdge();
  const workflow = useWorkflow();
  useEdgeCleanup(edge, workflow);

  const handleAndOrChange = useCallback(() => {
    if (edge && !readonly) workflow.toggleEdgeIsAnd(edge.id);
  }, [edge, workflow, readonly]);
  const isComplete = edge && isEdgeComplete(edge);

  return (
    <div
      className="flex h-full flex-col overflow-hidden"
      style={{ maxWidth: MAX_WIDTH }}
    >
      <div className="flex justify-between border-b border-gray-200 bg-gray-50 px-4 py-3">
        <h3 className="text-md font-semibold text-gray-800">Conditions</h3>
      </div>

      {!isComplete && !readonly && (
        <Banner className="mx-2 mt-2">Incomplete conditions</Banner>
      )}
      <AndOrToggle checked={!!edge?.is_and} onChange={handleAndOrChange} />

      <div
        className={`flex-1 overflow-y-auto p-2 ${
          readonly ? "pointer-events-none" : "pointer-events-auto"
        }`}
      >
        {conditions.map((condition, index) => (
          <SingleCondition
            key={condition.id || index}
            condition={condition}
            readonly={readonly}
            onUpdateCondition={onUpdateCondition}
            onRemoveCondition={onRemoveCondition}
          />
        ))}

        {!readonly && (
          <button
            className="mt-2 inline-flex w-full items-center justify-center rounded-lg px-4 py-3 text-sm font-medium text-blue-600 transition-colors duration-200 hover:bg-blue-50"
            onClick={onAddCondition}
            aria-label="Add new condition"
          >
            <PlusIcon className="mr-2 h-4 w-auto" />
            Add Condition
          </button>
        )}
      </div>
    </div>
  );
};

export default observer(Conditions);
