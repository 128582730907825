import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { CountConfiguration, ReportColumn } from "@/types/evaluate";
import { formatInputVariable } from "@/utils/evaluate";
import { useState } from "react";
import { useBandaid } from "../../utils/BandaidContext";
import { ModalStep } from "../ModalRouter";

const CountBuilder = ({
  columnData,
  availableColumns,
  navigatePrevious,
  saveColumnAction,
  editable,
}: {
  columnData: Partial<ReportColumn>;
  availableColumns: ReportColumn[];
  navigatePrevious: () => void;
  saveColumnAction: (newColumnDataArg: ReportColumn) => void;
  editable: boolean;
}) => {
  const [name, setName] = useState<string | null>(columnData.name || null);
  const [type, setType] = useState<string | null>(
    columnData.configuration?.type ?? null,
  );
  const [column, setColumn] = useState<string | null>(
    columnData.configuration?.source || null,
  );

  const [dataIsValid, setDataIsValid] = useState<{
    name: boolean;
    type: boolean;
    column: boolean;
  }>({
    name: true,
    type: true,
    column: true,
  });

  const saveEndpointAction = () => {
    if (!name) {
      setDataIsValid({ name: false, type: true, column: true });
      return;
    } else if (!type) {
      setDataIsValid({ name: true, type: false, column: true });
      return;
    } else if (!column) {
      setDataIsValid({ name: true, type: true, column: false });
      return;
    }

    setDataIsValid({ name: true, type: true, column: true });

    saveColumnAction({
      ...columnData,
      name: name,
      configuration: {
        type,
        source: column,
      } as CountConfiguration,
    } as ReportColumn);
  };

  const bandaid = useBandaid();

  return (
    <ModalStep
      navigatePrevious={navigatePrevious}
      navigateNext={saveEndpointAction}
      nextButtonText={editable ? "Save Step" : "Done"}
    >
      <div className="grid grid-cols-4 items-center gap-4">
        <div className="col-span-4">
          <div className="text-lg font-semibold">Configure Count</div>
          <div className="max-w-md text-sm text-gray-500">
            Count the number of characters, words, or paragraphs in a{" "}
            {bandaid ? "node" : "column"}.
          </div>
        </div>
        <div
          style={{ display: bandaid ? "none" : "block" }}
          className="col-span-1"
        >
          <label htmlFor="name">Column name:</label>
        </div>
        <div
          style={{ display: bandaid ? "none" : "block" }}
          className="col-span-3"
        >
          <input
            id="name"
            className={`w-full rounded border ${
              !dataIsValid.name ? "border-red-500" : "border-gray-300"
            } px-2 py-1 disabled:cursor-not-allowed disabled:bg-gray-50`}
            value={name || ""}
            onKeyDown={(e) => {
              if (e.key === " ") {
                // Only prevent default and handle manually for spaces
                e.preventDefault();
                setName(name + " ");
              }
              // Let all other keys (including backspace) work normally
            }}
            onChange={(e) => setName(e.target.value)}
            disabled={!editable}
          />
        </div>
        {!dataIsValid.name && (
          <>
            <div className="col-span-1">&nbsp;</div>
            <div className="col-span-3 -mt-4 text-left text-xs text-red-500">
              Name is required
            </div>
          </>
        )}

        <label htmlFor="type" className="col-span-1">
          Count of:
        </label>
        <div className="col-span-3">
          <DropdownMenu>
            <DropdownMenuTrigger className="w-full">
              {types.find((t) => t.value === type)?.label || (
                <span className="font-normal text-gray-500">
                  Select a type...
                </span>
              )}
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {types.map((type) => (
                <DropdownMenuItem
                  key={type.value}
                  onSelect={() => setType(type.value)}
                >
                  {type.label}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        {!dataIsValid.type && (
          <>
            <div className="col-span-1">&nbsp;</div>
            <div className="col-span-3 -mt-4 text-left text-xs text-red-500">
              Type is required
            </div>
          </>
        )}

        <label htmlFor="column" className="col-span-1">
          {bandaid ? "Node" : "Column"}:
        </label>
        <div className="col-span-3">
          <DropdownMenu>
            <DropdownMenuTrigger disabled={!editable} className="w-full">
              {column || (
                <span className="font-normal text-gray-500">
                  Select a {bandaid ? "node" : "column"}...
                </span>
              )}
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {availableColumns.map((column) => (
                <DropdownMenuItem
                  key={column.name}
                  onSelect={() => setColumn(column.name)}
                >
                  {formatInputVariable(column.column_type, column.name)}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        {!dataIsValid.column && (
          <>
            <div className="col-span-1">&nbsp;</div>
            <div className="col-span-3 -mt-4 text-left text-xs text-red-500">
              {bandaid ? "Node" : "Column"} is required
            </div>
          </>
        )}
      </div>
    </ModalStep>
  );
};

const types = [
  { value: "chars", label: "Characters" },
  { value: "words", label: "Words" },
  { value: "sentences", label: "Sentences" },
  { value: "paragraphs", label: "Paragraphs" },
] as const;

export default CountBuilder;
