import { useUser } from "@/context/user-context";
import { Workspace } from "@/types/workspaces";
import { Listbox } from "@headlessui/react";
import { CheckIcon, PlusIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import WorkspaceModal from "./WorkspaceModal";
import { Button } from "./ui/button";

// Used to fix z-index of dropdown
const PortalDropdown = ({
  children,
  triggerRef,
}: {
  children: React.ReactNode;
  triggerRef: React.RefObject<any>;
}) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [isPositionSet, setIsPositionSet] = useState(false);

  useEffect(() => {
    if (triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      setPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });
      // Delay the rendering of the dropdown to avoid flash
      setTimeout(() => setIsPositionSet(true), 100);
    }
  }, [triggerRef]);

  return isPositionSet
    ? createPortal(
        <div
          style={{
            position: "absolute",
            top: `${position.top}px`,
            left: `${position.left}px`,
            transition: "all 0.2s ease-in-out",
          }}
        >
          {children}
        </div>,
        document.body,
      )
    : null;
};

const CircleLetter = ({
  letter,
  selected,
}: {
  letter: string;
  selected: boolean;
}) => (
  <div
    className={`mr-2 flex h-6 w-6 items-center justify-center rounded-full border border-gray-50 bg-white shadow-sm`}
  >
    <span
      className={`text-sm font-medium uppercase ${selected && "text-blue-500"}`}
    >
      {letter}
    </span>
  </div>
);

export const WorkspaceSelector = () => {
  const navigate = useNavigate();
  const userContext = useUser();
  const [workspaceFilter, setWorkspaceFilter] = useState(
    userContext.activeWorkspaceId,
  );
  const [isWorkspaceModalOpen, setWorkspaceModalOpen] = useState(false);
  const triggerButtonRef = useRef(null); // Reference to the button that triggers the dropdown

  const onWorkspaceChange = (workspaceId: number) => {
    const currentPath = window.location.pathname;
    if (currentPath.match(/\/(version|edit|request)\//)) {
      navigate(`/workspace/${workspaceId}/home`);
    } else if (currentPath.match(/\/(blueprints|reports)\//)) {
      navigate(`/workspace/${workspaceId}/evaluate`);
    } else if (currentPath.includes("release-group")) {
      navigate(`/workspace/${workspaceId}/ab-releases`);
    } else {
      const uri = new URL(currentPath, window.location.origin);
      uri.pathname = uri.pathname.replace(
        /\/workspace\/\d+/,
        `/workspace/${workspaceId}`,
      );
      navigate(uri.pathname);
    }
  };

  useEffect(() => {
    setWorkspaceFilter(userContext.activeWorkspaceId);
  }, [userContext.activeWorkspaceId]);

  const handleWorkspaceFilterChange = (workspaceId: any) => {
    if (workspaceId === "create-new-workspace") {
      setWorkspaceModalOpen(true);
      return;
    }

    setWorkspaceFilter(workspaceId);
    onWorkspaceChange(workspaceId);
  };

  const workspaces = userContext.workspaces;

  const selectedWorkspace = workspaceFilter
    ? workspaces.find(
        (workspace: Workspace) => workspace.id === workspaceFilter,
      )?.name
    : "";

  return (
    <>
      <div className="-mt-0.5 px-1">
        <Listbox value={workspaceFilter} onChange={handleWorkspaceFilterChange}>
          {({ open }) => (
            <>
              <Listbox.Button
                ref={triggerButtonRef}
                className="relative w-40 rounded-lg border border-gray-300 bg-transparent p-2 text-base focus:outline-none focus:ring-0 sm:text-sm 2xl:w-64"
              >
                <div className="flex items-center justify-between px-1 font-medium text-gray-800">
                  <div className="flex items-center">
                    <div className="">
                      <CircleLetter
                        letter={(selectedWorkspace ?? "W").charAt(0)}
                        selected={false}
                      />
                    </div>
                    <div className="inline w-20 truncate 2xl:w-full">
                      {selectedWorkspace}
                      {workspaces.length === 0 && "Loading..."}
                    </div>
                  </div>
                  <ChevronDownIcon className="font-base h-5 w-5 text-gray-400" />
                </div>
              </Listbox.Button>
              {open && (
                <PortalDropdown triggerRef={triggerButtonRef}>
                  <Listbox.Options className="absolute z-10 mt-1 max-h-[450px] w-64 overflow-auto rounded-lg bg-white px-3 py-2 text-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {workspaces.length !== 0 && (
                      <div className="mb-1 py-1 text-center text-gray-400">
                        Switch workspaces
                      </div>
                    )}
                    {workspaces.map((workspace: Workspace) => (
                      <Listbox.Option key={workspace.id} value={workspace.id}>
                        {({ selected, active }) => (
                          <div
                            className={`mb-1 flex cursor-pointer select-none items-center rounded-md px-3 py-2 ${
                              active ? "bg-gray-200" : "text-gray-800"
                            } ${selected ? "bg-gray-100" : ""}`}
                          >
                            <CircleLetter
                              letter={(workspace.name ?? "W").charAt(0)}
                              selected={selected}
                            />
                            <span
                              className={`block flex-1 truncate pr-1 font-medium tracking-normal ${
                                selected ? "text-gray-800" : "text-gray-500"
                              } ${active && "text-gray-900"}`}
                            >
                              <div className="flex items-center gap-1">
                                {workspace.name}
                                {selected && (
                                  <span className={`px-2 py-1 text-blue-500`}>
                                    <CheckIcon className="h-4 w-4" />
                                  </span>
                                )}
                              </div>
                            </span>
                            <span
                              className={`rounded-full border border-blue-400 bg-blue-50 px-2 py-1 text-xs font-medium text-blue-400`}
                            >
                              {workspace.is_admin ? "Admin" : "Member"}
                            </span>
                          </div>
                        )}
                      </Listbox.Option>
                    ))}
                    {workspaces.length === 0 && (
                      <div className="flex items-center justify-center py-5">
                        <LoadingSpinner size={5} />
                      </div>
                    )}
                    <Listbox.Option value="create-new-workspace">
                      <div className="my-2 flex w-full justify-center pt-2">
                        <Button
                          variant="secondaryLightOutline"
                          size="default"
                          className="w-full text-left"
                        >
                          <PlusIcon
                            className="mr-1 h-4 w-4"
                            aria-hidden="true"
                          />
                          <span>Create New Workspace</span>
                        </Button>
                      </div>
                    </Listbox.Option>
                  </Listbox.Options>
                </PortalDropdown>
              )}
            </>
          )}
        </Listbox>
      </div>
      {isWorkspaceModalOpen && (
        <WorkspaceModal setOpen={setWorkspaceModalOpen} />
      )}
    </>
  );
};
