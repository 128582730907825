import { useEffect } from "react";
import { useState } from "react";
import { FieldValues } from "react-hook-form";
import { UseFormReturn } from "react-hook-form";

export const useReactFormMirror = <
  MirrorType extends Record<string, any>,
  FormType extends FieldValues,
>(
  form: UseFormReturn<FormType>,
  dataToMirror: MirrorType,
) => {
  const [data, setData] = useState<MirrorType>(dataToMirror);

  useEffect(() => {
    const subscription = form.watch(({ metadata }) => {
      if (metadata?.model) {
        setData({ ...metadata.model.parameters });
      }
    });

    return () => subscription.unsubscribe();
  }, [form]);

  return { data };
};
