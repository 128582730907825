import {
  useReactFlow,
  addEdge,
  Node,
  Edge,
  Connection,
  OnConnectStartParams,
  OnConnectStart,
  OnConnect,
  OnConnectEnd,
} from "@xyflow/react";
import { useRef, useCallback } from "react";

import useNodePreview from "./useNodePreview";
import {
  ObservableWorkflowNode,
  SetEdges,
  SetNodes,
  WorkflowNodeType,
} from "../../../types";
import { useWorkflow } from "../../workflow-context";
import { runInAction } from "mobx";
import { v4 } from "uuid";
import { useHistory } from "./useUndoRedo";

const useCreateNodeOnEdgeDrop = ([setNodes, setEdges]: [
  SetNodes,
  SetEdges,
]) => {
  const reactFlowWrapper = useRef<HTMLDivElement | null>(null);
  const connectingNodeId = useRef<string | null>(null);
  const { takeSnapshot } = useHistory();

  const { screenToFlowPosition, setCenter } = useReactFlow();
  const workflow = useWorkflow();

  const { isConnecting } = workflow;

  // update workflow node status for edge connect
  const onConnect: OnConnect = useCallback(
    (params: Connection) => {
      // Prevent self-loops
      if (params.source === params.target) {
        return;
      }

      const newEdgeId = workflow.addDependency(params.target, params.source);
      connectingNodeId.current = null;

      setEdges((eds: Edge[]) =>
        addEdge(
          {
            id: newEdgeId,
            ...params,
            type: "default",
          },
          eds,
        ),
      );
    },
    [setEdges, workflow],
  );

  const onConnectStart: OnConnectStart = useCallback(
    (_, { nodeId, handleType }: OnConnectStartParams) => {
      if (handleType === "source") {
        connectingNodeId.current = nodeId;
      } else if (handleType === "target") connectingNodeId.current = null;
    },
    [],
  );

  const [NodePreview, onMouseMove] = useNodePreview(isConnecting);

  const onConnectEnd: OnConnectEnd = useCallback(
    (event: globalThis.MouseEvent | TouchEvent) => {
      if (!connectingNodeId.current) return;

      const targetIsPane = (event.target as Element).classList.contains(
        "react-flow__pane",
      );

      if (targetIsPane) {
        takeSnapshot();
        const id = v4();
        const label = workflow.nextNodeLabel;
        const newNode: Node = {
          id,
          position: screenToFlowPosition({
            x:
              "clientX" in event
                ? event.clientX
                : (event as TouchEvent).touches[0].clientX,
            y:
              "clientY" in event
                ? event.clientY
                : (event as TouchEvent).touches[0].clientY,
          }),
          type: "PENDING",
          data: { label, type: "Pending" },
          origin: [0.5, 0.0],
          focusable: true,
        };

        setNodes((nds: Node[]) => nds.concat(newNode));

        runInAction(() => {
          workflow.addNode({
            id,
            name: label,
            node_type: "PENDING" as WorkflowNodeType,
            configuration: {},
            dependencies: [connectingNodeId.current!],
            workflow_version_id: workflow.version_id,
            is_output_node: false,
            has_default_name: true,
          });
        });
        setTimeout(
          () =>
            runInAction(() => {
              workflow.setActiveNode({ id } as ObservableWorkflowNode);
              workflow.openSidebar();
            }),
          0,
        );

        setEdges((eds: Edge[]) =>
          workflow.edges.map((edge) => ({
            id: edge.id,
            source: edge.source_node_name,
            target: edge.target_node_name,
            type: "default",
          })),
        );
        setCenter(newNode.position.x + 350 / 2, newNode.position.y, {
          duration: 750,
          zoom: 1,
        });
      }
    },
    [
      takeSnapshot,
      workflow,
      screenToFlowPosition,
      setNodes,
      setEdges,
      setCenter,
    ],
  );

  return [
    reactFlowWrapper,
    NodePreview,
    {
      onConnectStart,
      onConnectEnd,
      onConnect,
      onMouseMove,
    },
  ] as const;
};

export default useCreateNodeOnEdgeDrop;
