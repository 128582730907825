import { EdgeCondition } from "@/components/Workflows/types";
import { TrashIcon } from "@heroicons/react/outline";
import { ConditionsProps } from ".";
import ConditionValueInput from "./ConditionValueInput";

import JsonPath from "./JsonPath";
import OperatorSelect from "./OperatorSelect";

const SingleCondition = ({
  condition,
  onUpdateCondition,
  onRemoveCondition,
  readonly,
}: {
  condition: EdgeCondition;
  onUpdateCondition: ConditionsProps["onUpdateCondition"];
  onRemoveCondition: (id: string) => void;
  readonly?: boolean;
}) => (
  <div className="flex w-full flex-row gap-x-2" key={condition.id}>
    <div className="mb-2 w-full rounded-lg border border-gray-100 bg-white p-3 shadow-sm transition-colors duration-200 hover:border-gray-200">
      <div className="flex items-center space-x-3">
        <ConditionValueInput
          className="flex-1 text-sm focus:ring-2 focus:ring-blue-100"
          value={condition.left_config}
          onChange={(value) =>
            onUpdateCondition(condition.position, "left_config", value)
          }
          readonly={readonly}
        />
        <OperatorSelect
          value={condition.operator}
          onChange={(value) =>
            onUpdateCondition(condition.position, "operator", value)
          }
          readonly={readonly}
        />
        <ConditionValueInput
          className="flex-1 text-sm focus:ring-2 focus:ring-blue-100"
          value={condition.right_config}
          onChange={(value) =>
            onUpdateCondition(condition.position, "right_config", value)
          }
          readonly={readonly}
        />
      </div>
      <div className="flex flex-row justify-between">
        <div className="flex-1">
          {"source" in condition.left_config &&
          typeof condition.left_config === "object" ? (
            <JsonPath
              value={condition.left_config.source.json_path!}
              onChange={(checked) =>
                onUpdateCondition(condition.position, "left_config", {
                  source: {
                    ...(
                      condition.left_config as {
                        source: { name: string; json_path?: string };
                      }
                    ).source,
                    json_path: checked,
                  },
                })
              }
              readonly={readonly}
            />
          ) : null}
        </div>
        <div className="w-[80px]" />
        <div className="flex-1">
          {"source" in condition.right_config &&
          typeof condition.right_config === "object" ? (
            <JsonPath
              value={condition.right_config.source.json_path!}
              onChange={(value) =>
                onUpdateCondition(condition.position, "right_config", {
                  source: {
                    ...(
                      condition.right_config as {
                        source: { name: string; json_path?: string };
                      }
                    ).source,
                    json_path: value,
                  },
                })
              }
              readonly={readonly}
            />
          ) : null}
        </div>
      </div>
    </div>
    {!readonly && (
      <div className="flex flex-col items-center justify-center">
        <button
          onClick={() => onRemoveCondition(condition.id)}
          className="rounded-full p-2 text-gray-400 transition-colors duration-200 hover:bg-red-50 hover:text-red-600"
          title="Remove condition"
        >
          <TrashIcon className="h-4 w-4" />
        </button>
      </div>
    )}
  </div>
);

export default SingleCondition;
