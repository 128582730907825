import LightSwitch from "@/components/ui/LightSwitch";
import { observer } from "mobx-react-lite";

const AndOrToggle = ({
  checked = true,
  onChange,
}: {
  checked: boolean;
  onChange: () => void;
}) => {
  return (
    <div className="mx-auto mt-2 flex w-min cursor-pointer items-center gap-4 rounded-lg">
      <LightSwitch
        isOn={checked}
        onLabel="AND"
        offLabel="OR"
        size="sm"
        onToggle={function (value: boolean): void {
          onChange();
        }}
      ></LightSwitch>
    </div>
  );
};

export default observer(AndOrToggle);
