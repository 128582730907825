import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { JsonPathConfiguration, ReportColumn } from "@/types/evaluate";
import { formatInputVariable } from "@/utils/evaluate";
import { useState } from "react";
import { useBandaid } from "../../utils/BandaidContext";
import { ModalStep } from "../ModalRouter";

const JSONPathBuilder = ({
  columnData,
  availableColumns,
  navigatePrevious,
  saveColumnAction,
  editable,
}: {
  columnData: Partial<ReportColumn>;
  availableColumns: ReportColumn[];
  navigatePrevious: () => void;
  saveColumnAction: (newColumnDataArg: ReportColumn) => void;
  editable: boolean;
}) => {
  const [name, setName] = useState<string | null>(columnData.name || null);
  const [jsonPath, setJsonPath] = useState<string | null>(
    columnData.configuration?.json_path ?? "$.*",
  );
  const [column, setColumn] = useState<string | null>(
    columnData.configuration?.source || null,
  );
  const [returnFirstMatch, setReturnFirstMatch] = useState<boolean>(
    columnData.configuration?.return_first_match ?? true,
  );

  const [dataIsValid, setDataIsValid] = useState<{
    name: boolean;
    jsonPath: boolean;
    column: boolean;
  }>({
    name: true,
    jsonPath: true,
    column: true,
  });

  const saveEndpointAction = () => {
    if (!name) {
      setDataIsValid({ name: false, jsonPath: true, column: true });
      return;
    } else if (!jsonPath) {
      setDataIsValid({ name: true, jsonPath: false, column: true });
      return;
    } else if (!column) {
      setDataIsValid({ name: true, jsonPath: true, column: false });
      return;
    }

    setDataIsValid({ name: true, jsonPath: true, column: true });

    saveColumnAction({
      ...columnData,
      name: name,
      configuration: {
        json_path: jsonPath,
        source: column,
        return_first_match: returnFirstMatch || false,
      } as JsonPathConfiguration,
    } as ReportColumn);
  };

  const bandaid = useBandaid();

  return (
    <ModalStep
      navigatePrevious={navigatePrevious}
      navigateNext={saveEndpointAction}
      nextButtonText={editable ? "Save Step" : "Done"}
    >
      <div className="grid grid-cols-3 items-center gap-4">
        <div className="col-span-3">
          <div className="text-lg font-semibold">Configure JSON Extraction</div>
          <div className="max-w-md text-sm text-gray-500">
            This step will parse a JSON object and extract a value from it using
            JSONPath.
          </div>
        </div>
        <label
          htmlFor="name"
          className="col-span-1"
          style={{ display: bandaid ? "none" : "" }}
        >
          Column name:
        </label>
        <input
          id="name"
          className={`col-span-2 rounded border ${
            !dataIsValid.name ? "border-red-500" : "border-gray-300"
          } px-2 py-1 disabled:cursor-not-allowed disabled:bg-gray-50`}
          value={name || ""}
          onKeyDown={(e) => {
            if (e.key === " ") {
              // Only prevent default and handle manually for spaces
              e.preventDefault();
              setName(name + " ");
            }
            // Let all other keys (including backspace) work normally
          }}
          onChange={(e) => setName(e.target.value)}
          disabled={!editable}
          style={{ display: bandaid ? "none" : "" }}
        />
        {!dataIsValid.name && (
          <>
            <div className="col-span-1 -mt-4">&nbsp;</div>
            <div className="col-span-2 -mt-4 text-left text-xs text-red-500">
              Name is required
            </div>
          </>
        )}

        <label htmlFor="json-path" className="col-span-1">
          JSON Path:
        </label>
        <input
          id="json-path"
          className={`col-span-2 rounded border ${
            !dataIsValid.jsonPath ? "border-red-500" : "border-gray-300"
          } px-2 py-1 disabled:cursor-not-allowed disabled:bg-gray-50`}
          value={jsonPath || ""}
          onChange={(e) => setJsonPath(e.target.value)}
          disabled={!editable}
          placeholder="$.*"
        />
        {!dataIsValid.jsonPath && (
          <>
            <div className="col-span-1 -mt-4">&nbsp;</div>
            <div className="col-span-2 -mt-4 text-left text-xs text-red-500">
              JSON Path is required
            </div>
          </>
        )}

        <div className="col-span-1">&nbsp;</div>
        <div className="col-span-2 flex items-center gap-2">
          <input
            type="checkbox"
            id="return-first-match"
            checked={returnFirstMatch}
            onChange={() => setReturnFirstMatch(!returnFirstMatch)}
            disabled={!editable}
            className="cursor-pointer"
          />
          <label
            htmlFor="return-first-match"
            className="cursor-pointer text-sm"
          >
            Return First Match
          </label>
        </div>

        <label htmlFor="column" className="col-span-1">
          {bandaid ? "Node:" : "Column:"}
        </label>
        <DropdownMenu>
          <DropdownMenuTrigger
            disabled={!editable}
            className="col-span-2 w-full"
          >
            {column || (
              <span className="font-normal text-gray-500">
                Select a {bandaid ? "node" : "column"}...
              </span>
            )}
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {availableColumns.map((column) => (
              <DropdownMenuItem
                key={column.name}
                onSelect={() => setColumn(column.name)}
              >
                {formatInputVariable(column.column_type, column.name)}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
        {!dataIsValid.column && (
          <>
            <div className="col-span-1 -mt-4">&nbsp;</div>
            <div className="col-span-2 -mt-4 text-left text-xs text-red-500">
              {bandaid ? "Node" : "Column"} is required
            </div>
          </>
        )}
      </div>
    </ModalStep>
  );
};

export default JSONPathBuilder;
