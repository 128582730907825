import { Input } from "@/components/ui/input";
import { ValueConfig } from "@/components/Workflows/types";
import SwitchButton from "./SwitchButton";

interface StaticInputProps {
  inputValue: string;
  className?: string;
  placeholder?: string;
  onChange?: (value: ValueConfig) => void;
  onSourceSwitch: (e: React.MouseEvent) => void;
  readonly?: boolean;
}

const StaticInput = ({
  inputValue,
  className,
  placeholder = "Enter a value",
  onChange,
  onSourceSwitch,
  readonly,
}: StaticInputProps) => (
  <div className="mb-auto flex w-full flex-col gap-2">
    {!readonly && (
      <SwitchButton
        toSource={true}
        onSourceSwitch={onSourceSwitch}
        onStaticSwitch={() => {}}
      />
    )}
    <Input
      className={className}
      style={{
        padding: 9,
      }}
      placeholder={!readonly ? placeholder : ""}
      value={inputValue}
      onChange={(e) => onChange?.({ static_value: e.target.value })}
      readOnly={readonly}
    />
  </div>
);

export default StaticInput;
