import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { SchemaDefinition } from "@/types";
import { useState } from "react";
import SchemaEditor from "../SchemaEditor";

type SchemaDefinitionFormValues = SchemaDefinition & {
  json: SchemaDefinition;
};

interface EditJsonSchemaModalProps {
  initialValues: SchemaDefinitionFormValues;
  handleSubmit?: (schema: SchemaDefinition) => void;
  readonly?: boolean;
}

const EditJsonSchemaModal = ({
  initialValues,
  handleSubmit,
  readonly = false,
}: EditJsonSchemaModalProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <Dialog open={isOpen} onOpenChange={(open: boolean) => setIsOpen(open)}>
      <DialogTrigger asChild>
        <Button variant="outline">
          {readonly ? "View" : "Edit"} JSON Schema
        </Button>
      </DialogTrigger>
      <DialogContent className="box-border h-[80vh] w-screen max-w-full px-12">
        <DialogTitle>Edit JSON Schema</DialogTitle>
        <SchemaEditor
          schema={initialValues}
          onSubmit={(e) => {
            setIsOpen(false);
            handleSubmit?.(e);
          }}
          readonly={readonly}
        />
      </DialogContent>
    </Dialog>
  );
};
export default EditJsonSchemaModal;
