import EditJsonSchemaModal from "@/components/ModelProviderSelection/EditJsonSchemaModal";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { SchemaDefinition } from "@/types";
import { useEffect, useState } from "react";
import { ParamConfig } from "@/modelConfig";
import { CustomParameters } from "../ModelProviderSelection/CustomParameters";
import { getDropdownItem } from "@/components/PromptParameters";
import { Toggle, ToggleSize } from "../Toggle";
import { AdvancedControlsProps } from "./types";
import { cn } from "@/lib/utils";

// TODO: Move to separate file during refactor
const useSliderWithInput = (
  value: number,
  onChange: (value: number) => void,
) => {
  const [localValue, setLocalValue] = useState(value.toString());
  const [sliderValue, setSliderValue] = useState(value);

  useEffect(() => {
    setLocalValue(value.toString());
    setSliderValue(value);
  }, [value]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value);
  };

  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(e.target.value);
    setSliderValue(newValue);
    setLocalValue(newValue.toString());
    onChange(newValue);
  };
  const handleInputBlur = () => {
    const parsed = parseFloat(localValue);
    if (!isNaN(parsed)) {
      setSliderValue(parsed);
      setLocalValue(parsed.toString());
      onChange(parsed);
    } else {
      setLocalValue(value.toString());
    }
  };

  return {
    localValue,
    sliderValue,
    handleInputChange,
    handleSliderChange,
    handleInputBlur,
  };
};

// Needed as a separate component to unlink the input and slider values
const SliderWithInput = ({
  id,
  name,
  min,
  max,
  step,
  value,
  onChange,
  disabled,
}: {
  id: string;
  name: string;
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
  value: number;
  onChange: (value: number) => void;
}) => {
  const {
    localValue,
    sliderValue,
    handleInputChange,
    handleSliderChange,
    handleInputBlur,
  } = useSliderWithInput(value, onChange);

  return (
    <div className="mt-1 flex items-center space-x-1">
      <input
        type="range"
        id={`${id}-slider`}
        name={name}
        min={min}
        max={max}
        disabled={disabled}
        step={step}
        value={sliderValue.toString() === "-" ? 0 : sliderValue}
        className="mr-2 h-5 w-full rounded-md bg-blue-600 outline-none"
        onChange={handleSliderChange}
      />
      <input
        id={`${id}-input`}
        name={name}
        value={localValue}
        disabled={disabled}
        className="w-[40px] rounded-md border border-gray-300 px-1.5 py-1 text-xs"
        onChange={handleInputChange}
        onBlur={handleInputBlur}
      />
    </div>
  );
};

function AdvancedControls({
  isCustomModel,
  selectedProvider,
  selectedModel,
  modelParams,
  handleModelParamChange,
  handleModelParamChangeDropDown,
  updateCandidate,
  customParameters,
  setCustomParameters,
  modelConfigs,
  showRateLimitDelay = true,
  showChatTypeToggle = true,
}: AdvancedControlsProps) {
  const labelClasses = "flex w-full pl-1 text-xs font-medium";
  const disabledClass = "opacity-50";
  // const hasRateLimitDelayValue = modelParams?.["rateLimitDelay"] !== undefined;

  const handleToggleChange = (isToggleOn: boolean, key: string) => {
    const newValue = isToggleOn
      ? modelConfigs[selectedProvider][selectedModel].params[key]?.default
      : undefined;
    updateCandidate(key, newValue);
  };

  const getSelector = (key: string, value: ParamConfig) => {
    const hasValue = modelParams?.[key] !== undefined;
    switch (value.selectorType) {
      case "slider":
        return (
          <>
            <div className="flex w-full items-center justify-between">
              <label htmlFor={key} className={labelClasses}>
                <p className={hasValue ? "" : disabledClass}>{value.name}</p>
              </label>
              <Toggle
                size={ToggleSize.Small}
                enabled={hasValue}
                setEnabled={(isToggled) => handleToggleChange(isToggled, key)}
              />
            </div>
            <div className={hasValue ? "" : disabledClass}>
              <SliderWithInput
                id={`${selectedProvider}:::${selectedModel}:::${key}`}
                name={key}
                min={value?.range?.[0]}
                max={value?.range?.[1]}
                disabled={!hasValue}
                step={value.isInt ? 1 : 0.01}
                value={hasValue ? modelParams[key] : "-"}
                onChange={(newValue) => {
                  // Needed for type safety
                  const syntheticEvent = {
                    target: {
                      name: key,
                      value: newValue,
                      getAttribute: (attrName: string) =>
                        attrName === "value" ? newValue.toString() : null,
                    } as any,
                    currentTarget: {
                      value: newValue.toString(),
                    },
                    preventDefault: () => {},
                    stopPropagation: () => {},
                  } as React.ChangeEvent<HTMLInputElement>;

                  handleModelParamChange(syntheticEvent);
                }}
              />
            </div>
          </>
        );
      case "input":
        return (
          <>
            <div className="flex w-full items-center justify-between">
              <label htmlFor={key} className={labelClasses}>
                <p className={hasValue ? "" : disabledClass}>{value.name}</p>
              </label>
              <Toggle
                size={ToggleSize.Small}
                enabled={hasValue}
                setEnabled={(isToggled) => handleToggleChange(isToggled, key)}
              />
            </div>
            <div className={cn("mt-1", hasValue ? "" : disabledClass)}>
              <input
                type={hasValue ? "number" : "text"}
                id={key}
                key={`${selectedProvider}:::${selectedModel}:::${key}`}
                name={key}
                disabled={!hasValue}
                min={value?.range ? value.range[0] : undefined}
                max={value?.range ? value.range[1] : undefined}
                step={value.isInt ? 1 : 0.01}
                value={hasValue ? modelParams?.[key] : "-"}
                className="w-full rounded-lg border border-gray-300 px-2 py-1 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                onChange={handleModelParamChange}
              />
            </div>
          </>
        );
      case "dropdown":
        const selectedDropdownValue = hasValue
          ? getDropdownItem(value?.default, value?.options, modelParams?.[key])
          : "-";
        return (
          <>
            <div className="flex w-full items-center justify-between">
              <label htmlFor={key} className={labelClasses}>
                <p className={hasValue ? "" : disabledClass}>{value.name}</p>
              </label>
              <Toggle
                size={ToggleSize.Small}
                enabled={hasValue}
                setEnabled={(isToggled) => handleToggleChange(isToggled, key)}
              />
            </div>
            <div className={cn("mb-2 mt-1", hasValue ? "" : disabledClass)}>
              <DropdownMenu>
                <DropdownMenuTrigger
                  id={key}
                  disabled={!hasValue}
                  name={key}
                  key={`${selectedProvider}:::${selectedModel}:::${key}`}
                  className="w-full border-gray-300 text-sm font-normal text-gray-500 focus:border-blue-500 focus:ring-blue-500"
                >
                  {selectedDropdownValue || value.placeholder}
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  {Object.entries(value?.options || {}).map(
                    ([optionKey, optionValue]) => {
                      return (
                        <DropdownMenuItem
                          className="w-full text-center"
                          key={optionKey}
                          onSelect={() => {
                            handleModelParamChangeDropDown({
                              target: {
                                name: key,
                                value: optionValue,
                              },
                            } as React.ChangeEvent<HTMLSelectElement>);
                          }}
                        >
                          {optionKey}
                        </DropdownMenuItem>
                      );
                    },
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            {selectedDropdownValue === "JSON Schema" && (
              <EditJsonSchemaModal
                initialValues={modelParams[key]?.json_schema}
                handleSubmit={(schema: SchemaDefinition) => {
                  handleModelParamChangeDropDown({
                    target: {
                      name: key,
                      value: {
                        ...modelParams[key],
                        json_schema: { ...schema },
                      },
                    },
                  } as React.ChangeEvent<HTMLSelectElement>);
                }}
              />
            )}
          </>
        );
      case "textInput":
        return (
          <>
            <div className="flex w-full items-center justify-between">
              <label htmlFor={key} className={labelClasses}>
                <p className={hasValue ? "" : disabledClass}>{value.name}</p>
              </label>
              <Toggle
                size={ToggleSize.Small}
                enabled={hasValue}
                setEnabled={(isToggled) => handleToggleChange(isToggled, key)}
              />
            </div>
            <div className={cn("mt-1", hasValue ? "" : disabledClass)}>
              <input
                type="text"
                id={key}
                disabled={!hasValue}
                key={`${selectedProvider}:::${selectedModel}:::${key}`}
                name={key}
                value={hasValue ? modelParams?.[key] : "-"}
                className="w-full rounded-lg border border-gray-300 px-2 py-1 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                onChange={handleModelParamChange}
              />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="mb-6 grid max-h-[55vh] grid-cols-[repeat(2,minmax(0,234px))] gap-6 overflow-y-auto border-t border-gray-200 p-3 pt-6 text-gray-500">
      {!isCustomModel && (
        <>
          {Object.entries(
            modelConfigs[selectedProvider][selectedModel].params,
          ).map(([key, value]: [string, ParamConfig]) => (
            <div className="w-full" key={key}>
              {getSelector(key, value)}
            </div>
          ))}
        </>
      )}
      {/* {showRateLimitDelay && (
        <div key={"rate-limit-delay"}>
          <label htmlFor={"rate-limit-delay"} className={labelClasses}>
            <p className={hasRateLimitDelayValue ? "" : disabledClass}>
              Delay (s)
            </p>
            <Toggle
              size={ToggleSize.Small}
              enabled={hasRateLimitDelayValue}
              setEnabled={(isToggled) =>
                handleToggleChange(isToggled, "rateLimitDelay")
              }
            />
          </label>
          <div
            className={cn("mt-1", hasRateLimitDelayValue ? "" : disabledClass)}
          >
            <input
              type="number"
              id={"rate-limit-delay"}
              key={`${selectedProvider}:::${selectedModel}:::rate-limit-delay`}
              name={"key"}
              disabled={!hasRateLimitDelayValue}
              min={0}
              max={3600}
              defaultValue={0}
              className="w-full rounded-lg border border-gray-300 px-2 py-1 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
              onChange={(e) =>
                updateCandidate("rateLimitDelay", Number(e.target.value))
              }
            />
          </div>
        </div>
      )} */}
      <CustomParameters
        customParams={customParameters}
        setCustomParams={setCustomParameters}
        isCustomModel={isCustomModel}
        showChatTypeToggle={showChatTypeToggle}
        setIsChatModel={(isChatModel: boolean) => {
          updateCandidate("isChatModel", isChatModel);
        }}
      />
    </div>
  );
}

export default AdvancedControls;
