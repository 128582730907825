import { useFunctionDialogContext } from "@/components/FunctionsModal";
import { useFunctionForm } from "@/components/FunctionsModal/hooks/useFunctionForm";
import { FunctionFormValues } from "@/components/FunctionsModal/Types";
import SchemaEditor from "@/components/SchemaEditor";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { Function_ } from "@/types";
import { useForm } from "react-hook-form";

const defaultFormValues: Function_ = {
  name: "",
  description: "",
  strict: false,
  parameters: {
    additionalProperties: false,
    type: "object",
    properties: {},
    required: [],
  },
};

export const FunctionForm = () => {
  const { editingFunction: initialValues } = useFunctionDialogContext();
  const initialJson = initialValues ?? defaultFormValues;
  const form = useForm<FunctionFormValues>({
    defaultValues: {
      ...initialJson,
      json: initialJson,
    },
  });

  const { handleSubmit, closeForm } = useFunctionForm();

  const initialJSON = initialValues ?? defaultFormValues;

  return (
    <Form {...form}>
      <div className="flex flex-col gap-4">
        <div className="space-y-8">
          <SchemaEditor
            isFunction
            onSubmit={(value) => {
              handleSubmit(value as unknown as FunctionFormValues);
            }}
            schema={initialJSON}
          >
            <Button variant="outline" size="lg" onClick={closeForm}>
              Cancel
            </Button>
          </SchemaEditor>
        </div>
      </div>
    </Form>
  );
};
