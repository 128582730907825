import DatasetGroupSelectorModal from "@/components/DatasetGroupSelectorModal";
import { Button, DivButton } from "@/components/ui/button";
import { Popover, PopoverTrigger } from "@/components/ui/popover";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { ToastType } from "@/enums";
import { modelConfigs } from "@/modelConfig";
import { useAddRequestLogToDataset, useUpdateRequestTags } from "@/queries";
import { Request } from "@/types/requests";
import { displayErrorToast, displayToast } from "@/utils/toast";
import { formatPrice, keepRelevantSearchParams } from "@/utils/utils";
import { PlayIcon, PlusIcon, ViewListIcon } from "@heroicons/react/outline";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import { ChevronRight, XIcon } from "lucide-react";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { InputVariablesPopoverContent } from "./InputVariablesPopoverContent";
import LoadingSpinner from "./LoadingSpinner";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
} from "./ui/dropdown-menu";

// Calculate duration and return string using moment
function calculateDuration(start: string, end: string) {
  const duration = moment.duration(moment(end).diff(moment(start)));
  const seconds = duration.asSeconds();
  const milliseconds = duration.asMilliseconds();

  if (seconds < 1) {
    return `${milliseconds}ms`;
  } else if (seconds < 2 && milliseconds % 1000 > 0) {
    return `1s ${milliseconds % 1000}ms`;
  } else {
    return `${Math.round(seconds)}s`;
  }
}

// Tag Component
const TagComponent = ({
  tag,
  idx,
  onDelete,
  editable = false,
  onEnter,
}: {
  tag: any;
  idx: number | string;
  onDelete?: () => void;
  editable?: boolean;
  onEnter?: (tag: string) => void;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleAddTag = () => {
    if (onEnter) {
      onEnter(inputValue);
      setIsEditing(false);
      setInputValue("");
    }
  };

  const editableTag = isEditing ? (
    <input
      ref={inputRef}
      type="text"
      value={inputValue}
      placeholder="Add tag"
      onChange={(e) => setInputValue(e.target.value)}
      className="border-none bg-transparent p-0 text-sm placeholder-gray-400 focus:outline-none focus:ring-0"
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleAddTag();
        }
      }}
      style={{ width: `${Math.max(75, inputValue.length * 8)}px` }}
    />
  ) : (
    <div
      className="flex cursor-pointer items-center text-gray-400"
      onClick={() => {
        setIsEditing(true);
      }}
    >
      <span>Add tag</span> <PlusIcon className="ml-1 inline h-3 w-3" />
    </div>
  );

  return (
    <>
      <div
        key={idx}
        className={`group items-center ${
          onDelete && "hover:border-red-100 hover:bg-red-100"
        } ${
          editable && "border-gray-200 bg-gray-50"
        } relative inline rounded-full border border-gray-200 bg-gray-200 px-3 py-1 text-sm font-medium text-gray-500`}
      >
        {editable ? editableTag : tag}
        {onDelete ? (
          <div className="absolute -right-1 -top-1">
            <XIcon
              className="hidden h-4 w-4 cursor-pointer text-red-400 group-hover:block"
              onClick={onDelete}
            />
          </div>
        ) : null}
      </div>
      {editable && isEditing && (
        <Button size="sm" variant="default" onClick={handleAddTag}>
          <span>Add tag</span> <PlusIcon className="ml-1 inline h-3 w-3" />
        </Button>
      )}
    </>
  );
};

export const RequestInfoBlock = ({
  requestId,
  requestInfo,
  engine,
  provider,
  inputTokens,
  outputTokens,
}: {
  requestId: string;
  requestInfo: Request;
  requestText: string;
  engine: string;
  provider: string;
  response_format?: string;
  inputTokens?: number;
  outputTokens?: number;
}) => {
  const [isDatasetGroupSelectorModalOpen, setDatasetGroupSelectorModalOpen] =
    useState(false);
  const { shareHash } = useParams();
  const authContext = useAuth();
  const userContext = useUser();
  const userToken = authContext?.userToken;
  const activeWorkspaceId = userContext?.activeWorkspaceId;
  const isLoggedIn = !!authContext?.userToken && !!userContext.user;
  const {
    mutateAsync: addRequestLogToDataset,
    isLoading: updateAddRequestLogToDatasetIsLoading,
  } = useAddRequestLogToDataset(userToken!);

  const { mutate: updateTags, isLoading: updateTagsIsLoading } =
    useUpdateRequestTags(userToken || "");

  const handleDatasetGroupIdClick = async (datasetGroupId: number) => {
    const response = await addRequestLogToDataset({
      dataset_group_id: datasetGroupId,
      request_log_id: parseInt(requestId, 10),
    });

    if (response.success) {
      displayToast(
        "Request log added to dataset",
        ToastType.success,
        `/workspace/${userContext?.activeWorkspaceId}/dataset-groups/${datasetGroupId}/dataset/${response.draft_dataset_id}`,
      );
    } else {
      displayErrorToast(
        response.error ||
          "There was an error adding the request log to the dataset",
      );
    }
  };

  const handleDeleteTag = (tag: string) => {
    const newTags: string[] =
      requestInfo.tags_array?.filter((t) => t !== tag) || [];
    updateTags({
      request_id: requestId,
      new_tags_list: newTags,
    });
  };

  const handleAddTag = (tag: string) => {
    const newTags: string[] = requestInfo.tags_array || [];
    newTags.push(tag);
    updateTags({
      request_id: requestId,
      new_tags_list: newTags,
    });
  };

  const kwargs = requestInfo.prompt_version?.metadata?.model?.parameters;
  const modelAttributes = Object.entries(kwargs || {})
    .filter(
      ([key, value]) =>
        typeof value !== "object" &&
        !["system", "system_instruction", "inference_client_name"].includes(
          key,
        ),
    )
    .map(([key, value]) => ({
      name: modelConfigs[provider]?.[engine]?.params[key]?.name || key,
      value: value?.toString(),
    }));

  const attributes = [
    {
      name: "Time",
      value: calculateDuration(
        requestInfo.request_start_time,
        requestInfo.request_end_time,
      ),
    },
    {
      name: "Cost",
      value: requestInfo.price ? formatPrice(requestInfo.price) : "-",
    },
    { name: "Input Tokens", value: inputTokens ? inputTokens.toString() : "-" },
    {
      name: "Output Tokens",
      value: outputTokens ? outputTokens.toString() : "-",
    },
    ...modelAttributes,
  ].filter((attribute) => attribute.value && attribute.value !== "");

  const [searchParams] = useSearchParams();

  const renderPlaygroundButton = () => {
    if (!isLoggedIn) return null;

    const prompt_blueprint = requestInfo.prompt_version;
    if (!prompt_blueprint) return null;

    const preservedParams = keepRelevantSearchParams(searchParams);
    const requestIdentifier = shareHash || requestInfo.id;
    const basePlaygroundUrl = `/workspace/${activeWorkspaceId}/request/${requestIdentifier}/playground`;

    if (!requestInfo.prompt_id) {
      return (
        <Link to={`${basePlaygroundUrl}?mode=raw-request${preservedParams}`}>
          <Button size="sm" variant="secondaryLight">
            <PlayIcon className="mr-2 h-4 w-4" /> Open in Playground
          </Button>
        </Link>
      );
    }

    return (
      <div className="flex items-center divide-x divide-blue-300">
        <Link
          to={`${basePlaygroundUrl}?mode=raw-request&${requestInfo.prompt_id}&${preservedParams}`}
        >
          <Button size="sm" variant="secondaryLight" className="rounded-r-none">
            <PlayIcon className="mr-2 h-4 w-4" /> Open in Playground
          </Button>
        </Link>
        <DropdownMenu>
          <DropdownMenuTrigger className="group">
            <Button
              size="sm"
              variant="secondaryLight"
              className="rounded-l-none"
            >
              <ChevronRight className="h-4 w-4 transition group-data-[state=open]:rotate-90" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem asChild>
              <Link
                to={`${basePlaygroundUrl}?mode=raw-request&${preservedParams}`}
                className="w-full"
              >
                Raw Request
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem asChild>
              <Link
                to={`${basePlaygroundUrl}?mode=prompt-version&${preservedParams}`}
                className="w-full"
              >
                Prompt Template
              </Link>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    );
  };

  const renderAddToDatasetButton = () => {
    if (!isLoggedIn || shareHash) return null;

    return (
      <DivButton
        onClick={() => setDatasetGroupSelectorModalOpen(true)}
        size="sm"
        variant="secondaryLightOutline"
      >
        <PlusIcon className="mr-2 h-4 w-4" /> Add to Dataset
      </DivButton>
    );
  };

  const renderShowVariablesButton = () => {
    if (
      !requestInfo.prompt_input_variables ||
      Object.keys(requestInfo.prompt_input_variables).length === 0
    )
      return null;
    return (
      <Popover>
        <PopoverTrigger asChild>
          <DivButton size="sm" variant="ghost">
            Variables
            <ViewListIcon className="ml-2 h-4 w-4" />
          </DivButton>
        </PopoverTrigger>
        <InputVariablesPopoverContent
          variables={requestInfo.prompt_input_variables}
        />
      </Popover>
    );
  };

  return (
    <>
      <div className="mx-auto mb-2 w-full max-w-xl rounded-lg border-2 border-gray-200 bg-white px-4 pt-2 text-gray-700 shadow-md">
        <div className="min-h-[120px] justify-between">
          <div className="flex flex-col items-start justify-start">
            <div className="break-all text-left text-base font-semibold">
              {engine}
            </div>
            <div className="break-all text-base font-light">
              {requestInfo.function_name}
            </div>

            <div className={`flex flex-wrap gap-x-2 gap-y-2 pt-3`}>
              {(requestInfo.tags_array || []).map(
                (tag: string, idx: number) => (
                  <TagComponent
                    key={idx}
                    tag={tag}
                    idx={idx}
                    onDelete={() => handleDeleteTag(tag)}
                  />
                ),
              )}
              {updateTagsIsLoading ? (
                <TagComponent tag={<LoadingSpinner size={4} />} idx="loading" />
              ) : (
                <TagComponent
                  tag="Add Tag"
                  idx="add"
                  editable={true}
                  onEnter={handleAddTag}
                />
              )}
            </div>
            <div className="flex max-h-[100px] flex-wrap gap-x-5 gap-y-2 overflow-y-auto pt-2">
              {attributes.map(({ name, value }, idx) => (
                <div key={idx} className="gap-y-.5 flex flex-col text-sm">
                  <div className="capitalize text-gray-500">{name}</div>
                  <div className="text-gray-800">{value}</div>
                </div>
              ))}
            </div>
          </div>

          <div className="flex justify-between py-3">
            <div className="flex space-x-2">
              {renderPlaygroundButton()}
              {renderAddToDatasetButton()}
            </div>
            {renderShowVariablesButton()}
          </div>
        </div>
      </div>
      {isDatasetGroupSelectorModalOpen && (
        <DatasetGroupSelectorModal
          handleDatasetGroupIdClick={handleDatasetGroupIdClick}
          isLoading={updateAddRequestLogToDatasetIsLoading}
          minVersionNumber={-1}
          setOpen={setDatasetGroupSelectorModalOpen}
          showCreateButton={true}
        />
      )}
    </>
  );
};
