import { ArrowUpDownIcon } from "lucide-react";

interface SwitchButtonProps {
  toSource: boolean;
  onSourceSwitch: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onStaticSwitch: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const SwitchButton = ({
  toSource,
  onSourceSwitch,
  onStaticSwitch,
}: SwitchButtonProps) => (
  <button
    className="inline-flex items-center justify-center gap-2 rounded px-3 py-1.5 text-xs font-medium text-blue-500 transition-all duration-200 hover:bg-blue-50 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-200"
    onClick={toSource ? onSourceSwitch : onStaticSwitch}
  >
    <ArrowUpDownIcon className="h-4 w-4" />
    <span>Switch to {toSource ? "source" : "static"}</span>
  </button>
);

export default SwitchButton;
