import { WorkflowNodeStatus } from "@/components/Workflows/types";
import { observer } from "mobx-react-lite";

import { useWorkflow } from "../../workflow-context";
import { NodeContext, useNode } from "./node-context";
import { BasicNode, CompletedNode, QueuedNode, RunningNode } from "./NodeTypes";
import { BasicNodeProps } from "./NodeTypes/BasicNode";
import PlayFromHereButton from "./NodeTypes/BasicNode/PlayFromHereButton";
import SkippedNode from "./NodeTypes/SkippedNode";
import StatusText from "./StatusText";

const Node = (props: BasicNodeProps) => {
  const workflow = useWorkflow();
  const node = workflow.getNodeById(props.id);

  return (
    <NodeContext.Provider value={node || null}>
      <NodeRouter {...props} />
    </NodeContext.Provider>
  );
};

const NodeRouter = observer((props: BasicNodeProps) => {
  const node = useNode();
  const workflow = useWorkflow();
  const status = node?.status;
  const showPlayFromHere =
    !props.preview &&
    !props.pending &&
    (((workflow.isRunning || workflow.isStarted) && node?.triggered) ||
      (!workflow.isRunning && !workflow.isStarted));

  const saved = node?.saved;

  return (
    <>
      {!props.preview && (
        <div className={`mb-2 flex items-center justify-between`}>
          <StatusText status={status} />
          <div className="flex w-full items-end justify-between">
            {!saved && (
              <span className="mx-2 rounded-md bg-amber-100 px-2 py-1 text-xs font-medium text-amber-800 transition-all duration-200 hover:bg-amber-200">
                Unsaved
              </span>
            )}
            <PlayFromHereButton showPlayFromHere={showPlayFromHere} />
          </div>
        </div>
      )}
      {(() => {
        switch (status) {
          case WorkflowNodeStatus.COMPLETED:
            return <CompletedNode {...props} />;
          case WorkflowNodeStatus.RUNNING:
            return <RunningNode {...props} />;
          case WorkflowNodeStatus.QUEUED:
            return <QueuedNode {...props} />;
          case WorkflowNodeStatus.SKIPPED:
            return <SkippedNode {...props} />;
          default:
            return <BasicNode {...props} />;
        }
      })()}
    </>
  );
});

export default observer(Node);
