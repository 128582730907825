import Square3Stack3DIcon from "@/assets/Square3Stack3DIcon";
import {
  BriefcaseIcon,
  ChartSquareBarIcon,
  EyeIcon,
  FolderOpenIcon,
  HomeIcon,
  PlayIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/outline";

export const LOCAL_STORAGE = {
  ACCESS_TOKEN: "ACCESS_TOKEN",
  ACTIVE_WORKSPACE_ID: "ACTIVE_WORKSPACE_ID",
  INPUT_VARIABLE_ROWS: "INPUT_VARIABLE_ROWS",
  USER: "USER",
  LAST_METADATA_FIELD_FILTER: "LAST_METADATA_FIELD_FILTER",
  LAST_TEMPLATE_FORMAT: "LAST_TEMPLATE_FORMAT",
};

export const NAVIGATION: {
  name: string;
  icon: any;
  href: string;
  altHrefs?: string[];
}[] = [
  { name: "Home", icon: HomeIcon, href: "home", altHrefs: ["request"] },
  {
    name: "Registry",
    icon: FolderOpenIcon,
    href: "prompt",
    altHrefs: ["ab-releases", "prompt-folder", "workflow"],
  },
  { name: "Evaluate", icon: QuestionMarkCircleIcon, href: "evaluate" },
  { name: "Analytics", icon: ChartSquareBarIcon, href: "analytics" },
  { name: "Playground", icon: PlayIcon, href: "playground" },
];

export const MARKETING_NAVIGATION: {
  label: string;
  route?: string;
  dropdownItems?: {
    label: string;
    icon: any;
    route: string;
  }[];
}[] = [
  {
    label: "Platform",
    dropdownItems: [
      {
        route: "/platform/prompt-management",
        label: "Prompt Management",
        icon: Square3Stack3DIcon,
      },
      {
        route: "/platform/evaluations",
        label: "Evaluations",
        icon: ChartSquareBarIcon,
      },
      {
        route: "/platform/observability",
        label: "Observability",
        icon: EyeIcon,
      },
      {
        route: "/platform/dataset-management",
        label: "Dataset Management",
        icon: BriefcaseIcon,
      },
    ],
  },
  { label: "Docs", route: "https://docs.promptlayer.com/" },
  { label: "Blog", route: "https://blog.promptlayer.com/" },
  { label: "Case Studies", route: "/case-studies" },
];

export const queryKeys = {
  requests: "requests",
  request: "request",
  authUser: "authUser",
  metadataFields: "metadataFields",
  scoreNames: "scoreNames",
};

export type PlanDetails = {
  expiryLengthDays: number | null;
  requestCount: number;
  fineTuning: boolean;
  evaluateReports: boolean;
};

export const STRIPE_PLAN_DETAILS: Record<string, PlanDetails> = {
  free: {
    expiryLengthDays: 7, // days
    requestCount: 1000,
    fineTuning: false,
    evaluateReports: false,
  },
  individual: {
    expiryLengthDays: 7, // days
    requestCount: 10000,
    fineTuning: true,
    evaluateReports: false,
  },
  startup: {
    expiryLengthDays: null, // logs never expire
    requestCount: 100000,
    fineTuning: true,
    evaluateReports: true,
  },
  team: {
    expiryLengthDays: null, // logs never expire
    requestCount: 1000000,
    fineTuning: true,
    evaluateReports: true,
  },
  pro: {
    expiryLengthDays: null, // logs never expire
    requestCount: 10000000,
    fineTuning: true,
    evaluateReports: true,
  },
};

export const TEAM_WORKSPACE_MEMBER_LIMIT = 3;

export const WEBSOCKET_MESSAGE_NAME = {
  APPEND_TO_TEMPLATE: "APPEND_TO_TEMPLATE",
  CREATE_NEW_MESSAGE: "CREATE_NEW_MESSAGE",
  INDIVIDUAL_RUN_COMPLETE: "INDIVIDUAL_RUN_COMPLETE",
  NEW_THREAD_COMMENT: "NEW_THREAD_COMMENT",
  UPDATE_LAST_MESSAGE: "UPDATE_LAST_MESSAGE",
  DATASET_FILE_PROCESSING_COMPLETE: "DATASET_FILE_PROCESSING_COMPLETE",
  SET_WORKFLOW_NODE_OUTPUT: "SET_WORKFLOW_NODE_OUTPUT",
  SET_EDGE_OUTPUT: "SET_EDGE_OUTPUT",
};

export const OPEN_THREADS_SEARCH_PARAM = "threads-open";

export const MAX_SIDEBAR_NEW_REQUESTS_THRESHOLD = Number(
  process.env.REACT_APP_MAX_SIDEBAR_NEW_REQUESTS_THRESHOLD ?? 10,
);

export const SIDEBAR_MAX = 500;
