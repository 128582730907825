import { WorkflowEdge } from "@/components/Workflows/types";
import { toJS } from "mobx";
import { useState } from "react";

const compareEdges = (edge1: WorkflowEdge, edge2: WorkflowEdge | undefined) => {
  return JSON.stringify(toJS(edge1)) === JSON.stringify(toJS(edge2));
};

const useEdgeChanges = (edge: WorkflowEdge | undefined) => {
  const [initialEdgeData] = useState<WorkflowEdge>(toJS(edge)!);
  const hasChanges = !compareEdges(initialEdgeData, edge);
  return { hasChanges, initialEdgeData };
};

export default useEdgeChanges;
