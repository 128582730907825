import { ToolChoice } from "@/types";
import { stripDummyFields } from "./SchemaEditor/utils";

const getParameters = (declaration: any, required: boolean): any[] => {
  const requiredParameters = declaration?.parameters?.required || [];
  const allParameters = Object.keys(declaration?.parameters?.properties || {});
  return required
    ? requiredParameters
    : allParameters.filter((param) => !requiredParameters.includes(param));
};

interface ToolDeclaration {
  type: string;
  [key: string]: any;
}

const SubParams = ({ values }: { values: [string, any][] }) => {
  return values.map(([key, value]) => {
    if (!key || value === null) return null;

    const isNestedObject = typeof value === "object" && value.type;
    const content = isNestedObject ? (
      <Parameter param={key} value={value} />
    ) : typeof value === "object" ? (
      <SubParams values={Object.entries(value)} />
    ) : (
      JSON.stringify(value)
    );

    return isNestedObject ? (
      content
    ) : (
      <ul key={key} className="pl-4">
        <li>{content}</li>
      </ul>
    );
  });
};

const Parameter = ({ param, value }: { param: string; value: any }) => {
  return (
    <li key={param} className="pb-1 text-sm">
      <span className="font-semibold text-gray-500">{`${param} (${value?.type})`}</span>
      {value?.enum && (
        <span className="font-mono text-sm font-semibold text-gray-500">
          [{value.enum.join(", ")}]
        </span>
      )}
      {value?.description && (
        <span className="font-sans text-sm italic text-gray-500">
          : {value?.description?.trim()}
        </span>
      )}

      <SubParams
        values={Object.entries(stripDummyFields(value)).filter(
          ([key, _]) =>
            key !== "type" &&
            key !== "enum" &&
            key !== "description" &&
            key !== "required" &&
            key !== "additionalProperties" &&
            key !== "name" &&
            key !== "title",
        )}
      />
    </li>
  );
};

const ParameterList = ({
  declaration,
  required,
}: {
  declaration: any;
  required: boolean;
}) => {
  const parameters = getParameters(declaration, required);
  if (parameters.length === 0) return null;

  return (
    <>
      <div className="font-sans text-sm text-gray-900">
        {required ? "Required" : "Optional"} Parameters:
      </div>
      <ul className="py-1 pl-4">
        {parameters.map((param) => (
          <Parameter
            key={param}
            param={param}
            value={declaration?.parameters?.properties[param]}
          />
        ))}
      </ul>
    </>
  );
};

const FunctionDeclaration = ({ declaration }: { declaration: any }) => (
  <>
    <div className="font-medium text-gray-900">
      {declaration[declaration.type]?.name}
    </div>
    <div className="pl-2 pt-1 font-sans text-sm italic text-gray-500">
      {declaration[declaration.type]?.description}
    </div>
    <div className="pl-2 pt-1 text-gray-600">
      {declaration[declaration.type]?.parameters?.type === "object" ? (
        <>
          <ParameterList
            declaration={declaration[declaration.type]}
            required={true}
          />
          <ParameterList
            declaration={declaration[declaration.type]}
            required={false}
          />
        </>
      ) : (
        <div>{JSON.stringify(declaration[declaration.type], undefined, 2)}</div>
      )}
    </div>
  </>
);

export const ToolDeclarationsFormatter = ({
  toolDeclarations,
  toolChoice,
}: {
  toolDeclarations: ToolDeclaration[];
  toolChoice?: ToolChoice;
}) => {
  return (
    <div className="space-y-2">
      {toolDeclarations.map((declaration, index) => (
        <div key={index} className="pl-2">
          <h2 className="text-gray-900">{`${
            declaration.type.charAt(0).toUpperCase() + declaration.type.slice(1)
          }s`}</h2>
          <hr />
          <div className="pl-5 pt-2">
            {declaration.type === "function" ? (
              <FunctionDeclaration declaration={declaration} />
            ) : (
              <div className="font-medium text-gray-900">
                {declaration[declaration.type]?.name}
              </div>
            )}
          </div>
        </div>
      ))}

      {toolChoice && (
        <div className="font-medium text-gray-700">
          {"tool_choice: "}
          {typeof toolChoice === "string" ? (
            <span className="italic">{toolChoice}</span>
          ) : (
            <span className="font-mono">{toolChoice.function.name}</span>
          )}
        </div>
      )}
    </div>
  );
};
