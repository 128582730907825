import { DuplicateIcon, TrashIcon } from "@heroicons/react/solid";
import { useReactFlow } from "@xyflow/react";
import { runInAction } from "mobx";
import { useCallback } from "react";
import { v4 } from "uuid";
import { useWorkflow } from "../../workflow-context";
import { useHistory } from "../hooks/useUndoRedo";

interface NodeContextMenuProps {
  id: string;
  onClose?: () => void;
}

const NodeContextMenu: React.FC<NodeContextMenuProps> = ({ id, onClose }) => {
  const { setNodes, setEdges, getNode, addNodes, updateNode } = useReactFlow();
  const workflow = useWorkflow();
  const { takeSnapshot } = useHistory();

  const deleteNode = useCallback(() => {
    takeSnapshot();
    setNodes((nodes) => nodes.filter((node) => node.id !== id));
    setEdges((edges) => edges.filter((edge) => edge.source !== id));
    if (workflow.isNodeSelected(id)) {
      workflow.removeActiveNode();
    }
    onClose?.();
  }, [takeSnapshot, setNodes, setEdges, workflow, id, onClose]);

  const duplicateNode = useCallback(() => {
    takeSnapshot();
    const node = getNode(id);
    const workflowNode = workflow.getNodeById(id);

    if (!node || !workflowNode) {
      console.error(`Node with id ${id} not found`);
      return;
    }

    const baseName = workflowNode.name.replace(/\s+\d+$/, "");
    const existingNodes = workflow.nodes.filter((n) =>
      n.name.startsWith(baseName),
    );

    let highestNumber = 0;
    existingNodes.forEach((n) => {
      const match = n.name.match(/\s+(\d+)$/);
      if (match) {
        const number = parseInt(match[1], 10);
        if (number > highestNumber) {
          highestNumber = number;
        }
      }
    });

    const newNodeName = `${baseName} ${highestNumber + 1}`;

    const newNode = {
      ...node,
      id: v4(),
      position: {
        x: node.position.x + 50,
        y: node.position.y + 50,
      },
      data: {
        ...node.data,
        label: newNodeName,
        configuration: JSON.parse(
          JSON.stringify(workflowNode.configuration || {}),
        ),
        dependencies: [],
      },
      selected: true,
    };

    addNodes([newNode]);
    updateNode(id, { selected: false });
    onClose?.();
  }, [takeSnapshot, getNode, id, workflow, addNodes, updateNode, onClose]);

  const node = workflow.getNodeById(id);
  const isOutputNode = node?.is_output_node;

  const toggleOutputNode = useCallback(() => {
    const node = getNode(id);
    if (node) {
      runInAction(() => workflow.setOutputNode(id, !isOutputNode));
      onClose?.();
    }
  }, [getNode, id, workflow, isOutputNode, onClose]);

  const OutputIcon = isOutputNode ? BasicNodeIcon : OutputNodeIcon;

  return (
    <>
      <div className="p-2 text-sm text-gray-500">
        <small>Node: {id}</small>
      </div>

      {node?.node_type.toLowerCase() !== "pending" && (
        <button
          className="inline-flex w-full items-center border-t border-gray-200 px-4 py-2 text-left text-sm hover:bg-gray-100"
          onClick={toggleOutputNode}
        >
          <OutputIcon />
          {isOutputNode ? "Make Basic Node" : "Make Output Node"}
        </button>
      )}
      <button
        className="inline-flex w-full items-center border-t border-gray-200 px-4 py-2 text-left text-sm hover:bg-gray-100"
        onClick={duplicateNode}
      >
        <DuplicateIcon className="mr-2 h-4 w-auto" />
        Duplicate
      </button>
      <button
        className="inline-flex w-full items-center border-t border-gray-200 px-4 py-2 text-left text-sm text-red-600 hover:bg-gray-100"
        onClick={deleteNode}
      >
        <TrashIcon className="mr-2 h-4 w-auto text-red-600" />
        Delete
      </button>
    </>
  );
};

const BasicNodeIcon = () => (
  <svg
    className="mr-2 h-4 w-auto"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 10h18M3 14h18M3 18h18M3 6h18"
    />
  </svg>
);

const OutputNodeIcon = () => (
  <svg
    className="mr-2 h-4 w-auto"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
    />
  </svg>
);

export default NodeContextMenu;
