import { Message } from "@/types";
import { createBlobUrlFromBase64, isValidUrl } from "@/utils/utils";
import { useEffect, useState } from "react";

export const usePdfBlobManager = (
  mediaUrlOrContent?: string | Message["content"],
  mediaType?: string,
): [
  Record<number, string> | string | null,
  (index: number, url: string) => void,
] => {
  const [pdfBlobUrls, setPdfBlobUrls] = useState<Record<number, string>>({});
  const [singlePdfBlobUrl, setSinglePdfBlobUrl] = useState<string | null>(null);

  useEffect(() => {
    return () => {
      Object.values(pdfBlobUrls).forEach((url) => URL.revokeObjectURL(url));
      if (singlePdfBlobUrl) URL.revokeObjectURL(singlePdfBlobUrl);
    };
  }, [pdfBlobUrls, singlePdfBlobUrl]);

  const generateBlobUrl = (index: number, mediaUrl: string) => {
    if (!pdfBlobUrls[index] && !isValidUrl(mediaUrl)) {
      const blobUrl = createBlobUrlFromBase64(mediaUrl, "application/pdf");
      if (blobUrl) {
        setPdfBlobUrls((prev) => ({ ...prev, [index]: blobUrl }));
      }
    }
  };

  useEffect(() => {
    if (
      typeof mediaUrlOrContent === "string" &&
      mediaType === "application/pdf"
    ) {
      if (!isValidUrl(mediaUrlOrContent)) {
        const blobUrl = createBlobUrlFromBase64(
          mediaUrlOrContent,
          "application/pdf",
        );
        if (blobUrl) {
          setSinglePdfBlobUrl(blobUrl);
        }
      }
    }
  }, [mediaUrlOrContent, mediaType]);

  return typeof mediaUrlOrContent === "string"
    ? [singlePdfBlobUrl, () => {}]
    : [pdfBlobUrls, generateBlobUrl];
};
