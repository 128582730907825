import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { RegexConfiguration, ReportColumn } from "@/types/evaluate";
import { formatInputVariable } from "@/utils/evaluate";
import { useState } from "react";
import { useBandaid } from "../utils/BandaidContext";
import { ModalStep } from "./ModalRouter";

const RegexBuilder = ({
  columnData,
  availableColumns,
  navigatePrevious,
  saveColumnAction,
  editable,
}: {
  columnData: Partial<ReportColumn>;
  availableColumns: ReportColumn[];
  navigatePrevious: () => void;
  saveColumnAction: (newColumnDataArg: ReportColumn) => void;
  editable: boolean;
}) => {
  const [name, setName] = useState<string | null>(columnData.name || null);
  const [regexPattern, setRegexPattern] = useState<string | null>(
    columnData.configuration?.regex_pattern || null,
  );
  const [targetColumn, setTargetColumn] = useState<string | null>(
    columnData.configuration?.source || null,
  );

  const [dataIsValid, setDataIsValid] = useState<{
    name: boolean;
    targetColumn: boolean;
    regexPattern: boolean;
  }>({
    name: true,
    targetColumn: true,
    regexPattern: true,
  });

  const saveEndpointAction = () => {
    if (!name) {
      setDataIsValid({ name: false, targetColumn: true, regexPattern: true });
      return;
    } else if (!targetColumn) {
      setDataIsValid({ name: true, targetColumn: false, regexPattern: true });
      return;
    } else if (!regexPattern) {
      setDataIsValid({ name: true, targetColumn: true, regexPattern: false });
      return;
    }

    setDataIsValid({ name: true, targetColumn: true, regexPattern: true });

    saveColumnAction({
      ...columnData,
      name: name,
      configuration: {
        regex_pattern: regexPattern,
        source: targetColumn,
      } as RegexConfiguration,
    } as ReportColumn);
  };

  const bandaid = useBandaid();

  return (
    <ModalStep
      navigatePrevious={navigatePrevious}
      navigateNext={saveEndpointAction}
      nextButtonText={editable ? "Save Step" : "Done"}
    >
      <div className="flex flex-col gap-4">
        <div>
          <div className="text-lg font-semibold">Configure Regex Pattern</div>
          <div className="max-w-md text-sm text-gray-500">
            This step will search for a regex pattern in the target{" "}
            {bandaid ? "node" : "column"} and return true if the pattern is
            found.
          </div>
        </div>
        <div
          style={{ display: bandaid ? "none" : "flex" }}
          className="flex items-center gap-4"
        >
          <label htmlFor="name" className="w-1/3 text-left">
            Column name:
          </label>
          <div className="w-2/3">
            <input
              id="name"
              className={`w-full rounded border ${
                !dataIsValid.name ? "border-red-500" : "border-gray-300"
              } px-2 py-1 disabled:cursor-not-allowed disabled:bg-gray-50`}
              value={name || ""}
              onChange={(e) => setName(e.target.value)}
              disabled={!editable}
            />
            {!dataIsValid.name && (
              <div className="text-left text-xs text-red-500">
                Name is required
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center gap-4">
          <label htmlFor="regex" className="w-1/3 text-left">
            Regex Pattern:
          </label>
          <div className="w-2/3">
            <input
              id="regex"
              placeholder="[A-Za-z0-9]"
              className={`w-full rounded border ${
                !dataIsValid.regexPattern ? "border-red-500" : "border-gray-300"
              } px-2 py-1 disabled:cursor-not-allowed disabled:bg-gray-50`}
              value={regexPattern || ""}
              onChange={(e) => setRegexPattern(e.target.value)}
              disabled={!editable}
            />
            {!dataIsValid.regexPattern && (
              <div className="text-left text-xs text-red-500">
                Regex pattern is required
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center gap-4">
          <label htmlFor="targetColumn" className="w-1/3 text-left">
            {bandaid ? "Node" : "Column"}:
          </label>
          <div className="w-2/3">
            <DropdownMenu>
              <DropdownMenuTrigger
                id="targetColumn"
                disabled={!editable}
                className="w-full text-left"
              >
                {targetColumn || (
                  <span className="font-normal text-gray-500">
                    Select a {bandaid ? "node" : "column"}...
                  </span>
                )}
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {availableColumns.map((column) => (
                  <DropdownMenuItem
                    key={column.name}
                    onSelect={() => setTargetColumn(column.name)}
                  >
                    {formatInputVariable(column.column_type, column.name)}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
            {!dataIsValid.targetColumn && (
              <div className="text-left text-xs text-red-500">
                Target {bandaid ? "node" : "column"} input is required for
                comparison
              </div>
            )}
          </div>
        </div>
      </div>
    </ModalStep>
  );
};

export default RegexBuilder;
