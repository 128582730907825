import { ValueConfig } from "@/components/Workflows/types";
import { observer } from "mobx-react-lite";
import SourceInput from "./SourceInput";
import StaticInput from "./StaticInput";

interface ConditionValueProps {
  value: ValueConfig | string;
  onChange?: (value: ValueConfig) => void;
  className?: string;
  placeholder?: string;
  readonly?: boolean;
}

const ConditionValueInput = ({
  value,
  onChange,
  className,
  placeholder,
  readonly,
}: ConditionValueProps) => {
  const handleSourceSwitch = (e: React.MouseEvent) => {
    e.preventDefault();
    onChange?.({ source: { name: "", json_path: null } });
  };

  const handleStaticSwitch = (e: React.MouseEvent) => {
    e.preventDefault();
    onChange?.({ static_value: "" });
  };

  const staticProps = {
    className,
    placeholder,
    onChange,
    onSourceSwitch: handleSourceSwitch,
    readonly,
  };

  if (typeof value === "string") {
    return <StaticInput inputValue={value} {...staticProps} />;
  }

  if ("static_value" in value) {
    return <StaticInput inputValue={value.static_value} {...staticProps} />;
  } else if ("source" in value) {
    return (
      <SourceInput
        source={value.source}
        onChange={onChange}
        onStaticSwitch={handleStaticSwitch}
        readonly={readonly}
      />
    );
  }
  return null;
};

export default observer(ConditionValueInput);
