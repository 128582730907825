import { observer } from "mobx-react-lite";
import { useSchemaEditor } from ".";
import Checkbox from "../ui/checkbox";
import { Input } from "../ui/input";
import { Textarea } from "../ui/textarea";

const SchemaMetadata = () => {
  const editor = useSchemaEditor();
  const isFunction = editor.isFunction;
  const isReadonly = editor.isReadonly;

  const keyword = isFunction ? "function" : "schema";
  const Keyword = isFunction ? "Function" : "Schema";

  return (
    <div className="mb-5 space-y-4">
      <div className="flex flex-col gap-1">
        <span className="text-sm font-medium">{Keyword} Name</span>
        <Input
          value={editor.name}
          onChange={(e) => editor.setName(e.target.value)}
          disabled={isReadonly}
        />
        <span className="text-xs text-muted-foreground">
          Name of {keyword}, max length 64.
        </span>
      </div>
      <div className="flex flex-col gap-1">
        <span className="text-sm font-medium">Description</span>
        <Textarea
          value={editor.description}
          onChange={(e) => editor.setDescription(e.target.value)}
          disabled={isReadonly}
        />
        <span className="mb-5 text-xs text-muted-foreground">
          {isFunction
            ? "A description of what the function does, used by the model to choose when and how to call the function. (Optional)"
            : "A description of what the response format is for, used by the model to determine how to respond in the format. (Optional)"}
        </span>
      </div>
      <div className="mt-5 flex items-center gap-6">
        <Checkbox
          checked={editor.strict}
          onChange={(e) => editor.setStrict(e.target.checked)}
          disabled={isReadonly}
          label="Enable Strict Structured Output"
        />
        <Checkbox
          checked={editor.allowAdditionalProperties}
          onChange={(e) =>
            editor.setAllowAdditionalProperties(e.target.checked)
          }
          disabled={isReadonly}
          label="Allow Additional Properties"
        />
      </div>
    </div>
  );
};

export default observer(SchemaMetadata);
