import { PlusIcon, XIcon } from "@heroicons/react/outline";
import { Button } from "../ui/button";
import { v4 } from "uuid";
import { IndexParam } from "./types";
import { FUNCTION_MODEL_PARAMS } from "./constants";

export function CustomParameters({
  customParams,
  setCustomParams,
  isCustomModel,
  setIsChatModel,
  showChatTypeToggle,
}: {
  customParams: IndexParam[];
  setCustomParams: (newParams: IndexParam[]) => void;
  isCustomModel: boolean;
  setIsChatModel: (isChatModel: boolean) => void;
  showChatTypeToggle: boolean;
}) {
  const handleAddCustomParameter = () => {
    let index = customParams.length + 1;
    for (const param of customParams) {
      if (param.paramKey.includes(`custom_key_${index}`)) {
        index++;
      }
    }

    const newParamKey = `custom_key_${index}`;
    setCustomParams([
      ...customParams,
      { paramKey: newParamKey, paramValue: 0, paramId: v4() },
    ]);
  };

  const handleUpdateCustomerParameterKey = (oldKey: string, newKey: string) => {
    const value =
      customParams.find((param) => param.paramKey === oldKey)?.paramValue || 0;
    const newCustomParams = customParams.map((param) =>
      param.paramKey === oldKey
        ? { paramKey: newKey, paramValue: value, paramId: param.paramId }
        : param,
    );
    setCustomParams(newCustomParams);
  };

  const handleUpdateCustomParameter = (
    paramKey: string,
    paramValue: number,
  ) => {
    const newCustomParams = customParams.map((param) =>
      param.paramKey === paramKey
        ? { paramKey: paramKey, paramValue: paramValue, paramId: param.paramId }
        : param,
    );
    setCustomParams(newCustomParams);
  };

  const handleRemoveCustomParameter = (index: number) => {
    const newCustomParams = [
      ...customParams.slice(0, index),
      ...customParams.slice(index + 1),
    ];
    setCustomParams(newCustomParams);
  };

  const renderedParams = customParams
    .filter(({ paramKey }) => !FUNCTION_MODEL_PARAMS.includes(paramKey))
    .map(({ paramKey, paramId }, index) => {
      return (
        <div
          key={`custom-params-${paramId}`}
          className="grid grid-cols-3 items-center gap-1"
        >
          <div className="col-span-1 flex flex-row gap-1">
            <button
              onClick={() => handleRemoveCustomParameter(index)}
              className="text-red-500 hover:text-red-700 focus:outline-none"
            >
              <XIcon className="h-4 w-4" />
            </button>
            <label
              htmlFor={`${paramId}_label`}
              className="col-span-1 block pl-1 text-xs font-medium"
            >
              Key
            </label>
          </div>
          <input
            type="text"
            id={`${paramId}_label`}
            key={`${paramId}_label`}
            defaultValue={paramKey}
            className="col-span-2 rounded-lg border border-gray-300 px-2 py-1 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
            onChange={(e) =>
              handleUpdateCustomerParameterKey(paramKey, e.target.value)
            }
          />
          <div className="col-span-1">
            <label
              htmlFor={`${paramId}_input`}
              className="block pl-6 text-xs font-medium"
            >
              Value
            </label>
          </div>
          <input
            type="number"
            id={`${paramId}_input`}
            key={`${paramId}_input`}
            name={"key"}
            min={0}
            max={3600}
            defaultValue={Number(customParams[index].paramValue)}
            className="col-span-2 rounded-lg border border-gray-300 px-2 py-1 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
            onChange={(e) =>
              handleUpdateCustomParameter(paramKey, Number(e.target.value))
            }
          />
        </div>
      );
    });

  return (
    <>
      {isCustomModel && showChatTypeToggle && (
        <div key={"chat-model"}>
          <label
            htmlFor={"chat-model"}
            className="block pl-1 text-xs font-medium"
          >
            Chat Model?
          </label>
          <div className="mt-1">
            <input
              type="checkbox"
              id={"chat-model"}
              key={`chat-model`}
              name={"key"}
              className="form-checkbox h-5 w-5 rounded-sm border-gray-400 text-blue-600 focus:ring-blue-500"
              onChange={(e) => setIsChatModel(e.target.checked)}
            />
          </div>
        </div>
      )}
      {renderedParams}
      <Button
        onClick={handleAddCustomParameter}
        variant="link"
        size="sm"
        className="mt-4"
      >
        <PlusIcon className="mr-1 h-3 w-3" />
        Add New Parameter
      </Button>
    </>
  );
}
