import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { OPERATORS } from "./constants";

const OperatorSelect = ({
  value,
  onChange,
  readonly,
}: {
  value: string;
  onChange: (value: string) => void;
  readonly?: boolean;
}) => (
  <Select value={value} onValueChange={onChange} disabled={readonly}>
    <SelectTrigger className="mt-auto w-[80px] focus:ring-2 focus:ring-blue-100">
      <SelectValue placeholder="Op" />
    </SelectTrigger>
    <SelectContent>
      {Object.entries(OPERATORS).map(([key, symbol]) => (
        <SelectItem key={key} value={key}>
          {symbol}
        </SelectItem>
      ))}
    </SelectContent>
  </Select>
);

export default OperatorSelect;
