import { motion } from "framer-motion";

const Dots = ({ isEdgeSelected }: { isEdgeSelected: boolean }) => {
  const dotProps = (delay: number) => ({
    initial: { scale: 0, opacity: 0 },
    animate: { scale: 1, opacity: 1 },
    exit: { scale: 0, opacity: 0 },
    transition: { duration: 0.2, delay },
    r: "2",
    fill: isEdgeSelected ? "#3b82f6" : "white",
  });

  return (
    <>
      <motion.circle {...dotProps(0)} cx="-8" cy="0" />
      <motion.circle {...dotProps(0.1)} cx="0" cy="0" />
      <motion.circle {...dotProps(0.2)} cx="8" cy="0" />
    </>
  );
};

export default Dots;
