import { Switch as HeadlessSwitch, SwitchProps } from "@headlessui/react";

interface LockableSwitchProps extends Omit<SwitchProps<"input">, "size"> {
  lockedState?: boolean | null;
  disabled?: boolean;
  size?: "sm" | "md" | "lg";
}

const sizeConfig = {
  sm: {
    switch: "h-4 w-8",
    thumb: "h-3 w-3",
    translate: "translate-x-4",
  },
  md: {
    switch: "h-6 w-12",
    thumb: "h-5 w-5",
    translate: "translate-x-6",
  },
  lg: {
    switch: "h-8 w-16",
    thumb: "h-7 w-7",
    translate: "translate-x-8",
  },
};

export const Switch = ({
  lockedState,
  disabled,
  size = "md",
  ...props
}: LockableSwitchProps) => {
  const isLocked = lockedState !== null && lockedState !== undefined;
  const isChecked = isLocked ? lockedState : props.checked;
  const isDisabled = isLocked || disabled;

  return (
    <HeadlessSwitch
      {...props}
      checked={isChecked}
      disabled={isDisabled}
      className={`${isChecked ? "bg-blue-500" : "bg-blue-600"}
          relative inline-flex ${
            sizeConfig[size].switch
          } shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 ${
            isDisabled ? "cursor-not-allowed bg-gray-300" : ""
          }`}
    >
      <span
        aria-hidden="true"
        className={`${isChecked ? sizeConfig[size].translate : "translate-x-0"}
            pointer-events-none inline-block transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out ${
              sizeConfig[size].thumb
            } ${isDisabled ? "bg-gray-400" : ""}`}
      />
    </HeadlessSwitch>
  );
};
