import LoadingSpinner from "@/components/LoadingSpinner";
import { SidebarRequestLogCard } from "@/components/SidebarRequestLogCard";
import { useInfiniteScroll } from "@/components/SidebarSearchLayout/hooks/useInfiniteScroll";
import { useKeyboardNavigation } from "@/components/SidebarSearchLayout/hooks/useKeyboardNavigation";
import NoResultsFooter from "@/components/SidebarSearchLayout/NoResultsFooter";
import { SIDEBAR_MAX } from "@/constants";
import { useRequestLogs } from "@/queries";
import { Request } from "@/types/requests";
import { responseIsFunctionOrTool } from "@/utils/logUtils";
import { RefObject, useCallback, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";

const RequestLogs = ({
  inputRef,
  requestsOn,
}: {
  inputRef: RefObject<HTMLInputElement>;
  requestsOn: boolean;
}) => {
  const { requestId, groupId } = useParams();
  const onGroupPage = window.location.pathname.includes("/group");
  const currentType = onGroupPage ? "group" : "request";

  const sidebarLogContainerRef = useRef<HTMLDivElement | null>(null);

  const requestLogs = useRequestLogs();

  const requests = useMemo(
    () =>
      requestLogs.data?.pages
        ?.map(
          (page) =>
            page.items?.map((item: any) => {
              if (item?.metadata?.[0] === null) {
                item.metadata = null;
              }
              return item;
            }),
        )
        .flat() ?? [],
    [requestLogs.data],
  );

  const fetchNextPage = requestLogs.fetchNextPage;

  const infiniteScrollRef = useInfiniteScroll(fetchNextPage);

  const getNextId = useCallback(
    (id?: string, type?: string) => {
      const index = requests.findIndex(
        (item: { id: string; type?: string; trace_id?: string }) =>
          String(item.id) === id && item.type === type,
      );
      if (index < requests.length - 1) {
        const nextItem = requests[index + 1];
        return {
          id: nextItem.id,
          type: nextItem.type,
        };
      }
      return null;
    },
    [requests],
  );

  const scrollToLogBox = useCallback(
    (id: string) => {
      if (sidebarLogContainerRef.current) {
        const container = sidebarLogContainerRef.current;
        const element = container.querySelector(
          `[data-request-id="${id}"]`,
        ) as HTMLElement;

        if (element) {
          const containerRect = container.getBoundingClientRect();
          const elementRect = element.getBoundingClientRect();
          const offsetTop = elementRect.top - containerRect.top;

          // Calculate the desired scroll position
          let scrollTo = container.scrollTop + offsetTop;

          // If the element is at the top, scroll to show the full element
          if (offsetTop <= 0) {
            scrollTo = container.scrollTop + offsetTop - 16; // Add some padding
          }

          // If we're near the bottom, adjust scroll to show as much as possible
          const containerHeight = container.clientHeight;
          const elementHeight = element.clientHeight;
          if (offsetTop + elementHeight > containerHeight) {
            scrollTo =
              container.scrollTop +
              offsetTop -
              containerHeight +
              elementHeight +
              16; // Add some padding
          }

          // Perform the scroll
          container.scrollTo({
            top: scrollTo,
            behavior: "smooth",
          });
        }
      }
    },
    [sidebarLogContainerRef],
  );

  const getPreviousId = useCallback(
    (id?: string, type?: string) => {
      const index = requests.findIndex(
        (item: { id: string; type?: string; trace_id?: string }) =>
          String(item.id) === id && item.type === type,
      );
      if (index > 0) {
        const prevItem = requests[index - 1];
        return {
          id: prevItem.id,
          type: prevItem.type,
        };
      }
      return null;
    },
    [requests],
  );

  useKeyboardNavigation(
    inputRef,
    requests,
    getNextId,
    getPreviousId,
    scrollToLogBox,
    groupId || requestId || "",
    currentType,
    onGroupPage,
  );

  const hasNextPage = requestLogs.hasNextPage;
  const isFetchingNewRequests = requestLogs.isFetching;
  const isFetching = requestLogs.isFetching && !hasNextPage;
  const noRequestsFound = !(requests.length > 0);

  const renderCards = () =>
    (requests || [])
      .slice(0, SIDEBAR_MAX)
      .map((item: Request, idx: number) => (
        <SidebarRequestLogCard
          key={idx}
          active={
            !!(requestId && String(item.id) === requestId) ||
            !!(groupId && String(item.id) === groupId)
          }
          logId={item.id}
          descriptor={item.engine || item.function_name}
          startTime={item.request_start_time}
          tags={item.tags}
          starred={item.is_starred}
          prompt={item.prompt_string}
          responseIsFunction={responseIsFunctionOrTool(item)}
          response={item.response_string}
          type={item.type}
          cardinality={item.cardinality}
          promptId={item.prompt_id ?? undefined}
        />
      ));
  return (
    <nav
      className="flex flex-1 flex-col space-y-1 overflow-y-auto px-1 pb-4"
      ref={sidebarLogContainerRef}
    >
      {renderCards()}
      <div
        ref={infiniteScrollRef}
        className={`${hasNextPage ? "flex justify-center py-2" : "hidden"}`}
      >
        <LoadingSpinner size={5} />
      </div>
      {isFetchingNewRequests && isFetching && (
        <div className="flex justify-center py-2">
          <LoadingSpinner size={5} />
        </div>
      )}
      {noRequestsFound && <NoResultsFooter requestsOn={requestsOn} />}
    </nav>
  );
};

export default RequestLogs;
