import { EdgeProps, getSmoothStepPath } from "@xyflow/react";

const useEdgePath = (props: EdgeProps) => {
  const { sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition } =
    props;
  return getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    borderRadius: 20,
  })[0];
};

export default useEdgePath;
