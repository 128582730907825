import { ToggleSizeClasses } from "./types";

export enum ToggleSize {
  Small = "small",
  Medium = "medium",
  Large = "large",
}

export const sizeClasses: Record<ToggleSize, ToggleSizeClasses> = {
  [ToggleSize.Small]: {
    switch: "h-4 w-7",
    toggle: "h-3 w-3",
    translate: "translate-x-3",
    icon: "h-2 w-2",
  },
  [ToggleSize.Medium]: {
    switch: "h-6 w-11",
    toggle: "h-5 w-5",
    translate: "translate-x-5",
    icon: "h-3 w-3",
  },
  [ToggleSize.Large]: {
    switch: "h-8 w-14",
    toggle: "h-7 w-7",
    translate: "translate-x-6",
    icon: "h-4 w-4",
  },
};
