import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Button } from "../ui/button";
import { LabelsSelectProps } from "./types";
import { useLabelsSelect } from "./hooks";

export const LabelsSelect = ({
  trigger,
  isLoading,
  open,
  setOpen,
  labels,
  promptVersion,
  shouldNavigate,
}: LabelsSelectProps) => {
  const {
    selectedReleaseLabel,
    setSelectedReleaseLabel,
    handleClick,
    hasLabels,
    isABTestLoading,
  } = useLabelsSelect({
    promptVersion,
    labels,
    setOpen,
    shouldNavigate,
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      {hasLabels ? (
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Select Release Label</DialogTitle>
            <DialogDescription>
              <span className="max-w-md">
                Choose a release label to build an experiment on top of. The
                release label must already be configured on one of your prompt
                templates.
              </span>
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-2">
            <label
              htmlFor="release-label-select"
              className="block text-sm font-medium text-gray-700"
            >
              Release Label
            </label>
            <DropdownMenu>
              <DropdownMenuTrigger className="w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500">
                {selectedReleaseLabel?.name || (
                  <span className="text-gray-500">Select a release label</span>
                )}
              </DropdownMenuTrigger>
              <DropdownMenuContent className="mt-1 w-full">
                {labels.map((promptLabel) => (
                  <DropdownMenuItem
                    key={promptLabel.id}
                    onSelect={() => setSelectedReleaseLabel(promptLabel)}
                    className="cursor-pointer px-3 py-2 hover:bg-blue-50"
                  >
                    {promptLabel.name}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <DialogFooter>
            <DialogClose onClick={() => setOpen?.(false)} asChild>
              <Button variant="secondary">Cancel</Button>
            </DialogClose>
            <Button
              disabled={isABTestLoading || isLoading}
              isLoading={isABTestLoading || isLoading}
              onClick={handleClick}
            >
              Submit
            </Button>
          </DialogFooter>
        </DialogContent>
      ) : null}
    </Dialog>
  );
};
