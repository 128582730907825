import { cn } from "@/lib/utils";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { XCircleIcon } from "lucide-react";
import { forwardRef } from "react";

interface BannerProps extends React.HTMLAttributes<HTMLDivElement> {
  type?: "warning" | "error";
  icon?: React.ReactNode;
  prefix?: string;
  children: React.ReactNode;
}

const Banner = forwardRef<HTMLDivElement, BannerProps>(
  ({ type = "warning", icon, prefix, children, className, ...props }, ref) => {
    const styles = {
      warning: "bg-amber-50 border-amber-200 text-amber-800 shadow-sm",
      error: "bg-red-50 border-red-200 text-red-800 shadow-sm",
    };

    const defaultIcons = {
      warning: <ExclamationTriangleIcon className="h-5 w-5 text-amber-500" />,
      error: <XCircleIcon className="h-5 w-5 text-red-500" />,
    };

    const defaultPrefixes = {
      warning: "Warning:",
      error: "Error:",
    };

    return (
      <div
        ref={ref}
        className={cn(
          "flex items-center gap-3 rounded-lg border px-4 py-3 transition-colors",
          styles[type],
          className,
        )}
        {...props}
      >
        <span className="flex-shrink-0">{icon || defaultIcons[type]}</span>
        <div className="text-sm font-medium">
          {prefix || defaultPrefixes[type]} {children}
        </div>
      </div>
    );
  },
);

Banner.displayName = "Banner";

export default Banner;
