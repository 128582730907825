import { BaseEdge, EdgeProps } from "@xyflow/react";
import { AnimatePresence, motion } from "framer-motion";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useWorkflow } from "../../../workflow-context";
import { EdgeContextProvider } from "../../ContextMenu/edge-conditions-context";
import Dots from "./Dots";
import EdgeContent from "./EdgeContent";
import { LoadingSpinner, LoadingSpinner2 } from "./EdgeLoadingSpinner";
import useEdgeInteraction from "./hooks/useEdgeInteraction";
import useEdgePath from "./hooks/useEdgePath";
import useEdgeStyle from "./hooks/useEdgeStyle";
import {
  getStatusColor,
  springTransition,
  springTransitionFast,
} from "./utils";

interface EdgeCenter {
  x: number;
  y: number;
}

function CustomEdge(props: EdgeProps) {
  const {
    id,
    style = {},
    target,
    source,
    sourceX,
    targetX,
    sourceY,
    targetY,
  } = props;
  const workflow = useWorkflow();
  const isEdgeSelected = workflow.isEdgeSelected(id);

  const edgePath = useEdgePath(props);
  const stroke = useEdgeStyle(target, id);
  const { hoverPosition, onEdgeClick, handleMouseMove, handleMouseLeave } =
    useEdgeInteraction(id, target, source);

  const edge = workflow.getEdge(id);
  const status = edge?.status;
  const isLoading = edge?.is_loading ?? false;
  const conditions = edge?.conditionals ?? [];

  const edgeCenter: EdgeCenter = useMemo(
    () => ({
      x: (sourceX + targetX) / 2,
      y: (sourceY + targetY) / 2,
    }),
    [sourceX, targetX, sourceY, targetY],
  );

  const baseEdgeStyle = useMemo(
    () => ({
      ...style,
      stroke,
      strokeWidth: 2,
      transition: "stroke 0.25s cubic-bezier(0.4, 0, 0.2, 1)",
    }),
    [style, stroke],
  );

  const shouldShowEdgeContent =
    (conditions.length > 0 || isLoading) && !hoverPosition;

  const shouldShowHoverControls = hoverPosition;

  return (
    <EdgeContextProvider
      value={{
        edge,
      }}
    >
      <>
        {/* Base edge line */}
        <BaseEdge path={edgePath} style={baseEdgeStyle} />

        {/* Invisible interaction layer */}
        <path
          d={edgePath}
          fill="none"
          strokeWidth={20}
          stroke="transparent"
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          onClick={onEdgeClick}
          className="cursor-pointer"
        />

        {/* Edge content (conditions or loading state) */}
        <AnimatePresence mode="wait">
          {shouldShowEdgeContent && (
            <motion.g transform={`translate(${edgeCenter.x}, ${edgeCenter.y})`}>
              <motion.rect
                x="-20"
                y="-15"
                width={Math.max(48, conditions.length > 9 ? 56 : 48)}
                height="30"
                rx="15"
                fill="#ffffff"
                stroke={getStatusColor(status)}
                strokeWidth={1.5}
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.8, opacity: 0 }}
                transition={springTransition}
                className="cursor-pointer shadow-lg transition-all duration-300 ease-out hover:scale-110 hover:stroke-blue-400 active:scale-95"
                whileHover={{
                  boxShadow: "0 0 12px rgba(59, 130, 246, 0.6)",
                  stroke: "#60a5fa",
                }}
              />
              {isLoading ? (
                <LoadingSpinner2 isEdgeSelected={isEdgeSelected} />
              ) : (
                <EdgeContent conditions={conditions} />
              )}
            </motion.g>
          )}
        </AnimatePresence>

        {/* Hover controls */}
        <AnimatePresence mode="wait">
          {shouldShowHoverControls && (
            <g transform={`translate(${hoverPosition.x}, ${hoverPosition.y})`}>
              <motion.circle
                initial={{ scale: 0.6, opacity: 0 }}
                animate={{ scale: isEdgeSelected ? 1.2 : 1, opacity: 1 }}
                exit={{ scale: 0.6, opacity: 0 }}
                transition={springTransitionFast}
                r="14"
                fill={isEdgeSelected ? "#ffffff" : "#3b82f6"}
                stroke={isEdgeSelected ? "#3b82f6" : "#2563eb"}
                strokeWidth={isEdgeSelected ? 2.5 : 1.5}
                className="cursor-pointer transition-all duration-300 ease-out hover:scale-110 hover:stroke-blue-400 active:scale-95"
              />
              <motion.g
                initial={{ scale: 0.6, opacity: 0 }}
                animate={{
                  scale: isEdgeSelected ? 1.2 : 1,
                  opacity: 1,
                  rotate: isEdgeSelected ? 180 : 0,
                }}
                exit={{ scale: 0.6, opacity: 0 }}
                transition={springTransitionFast}
                className="pointer-events-none select-none"
              >
                {isLoading ? (
                  <LoadingSpinner isEdgeSelected={isEdgeSelected} />
                ) : (
                  <Dots isEdgeSelected={isEdgeSelected} />
                )}
              </motion.g>
            </g>
          )}
        </AnimatePresence>
      </>
    </EdgeContextProvider>
  );
}

export default observer(CustomEdge);
