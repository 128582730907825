import { useUser } from "@/context/user-context";
import { cn } from "@/lib/utils";
import { useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";

export default function RegistryTabs() {
  const userContext = useUser();
  const location = useLocation();

  const navigationTabs = useMemo(() => {
    return [
      {
        label: "Prompts",
        href: `/workspace/${userContext?.activeWorkspaceId}/prompt`,
      },
      {
        label: "A/B Releases",
        href: `/workspace/${userContext?.activeWorkspaceId}/ab-releases`,
      },
      {
        label: "Agents",
        href: `/workspace/${userContext?.activeWorkspaceId}/workflow`,
        beta: true,
      },
    ];
  }, [userContext.activeWorkspaceId]);

  return (
    <div className="mt-4 flex gap-x-4 border-b">
      {navigationTabs.map((link) => (
        <NavLink
          key={link.label}
          to={link.href}
          className={({ isActive }) =>
            cn(
              "relative inline-flex cursor-pointer items-center justify-center whitespace-nowrap px-3 py-2 text-sm font-medium transition-all duration-200 ease-in-out",
              isActive ||
                (link.label === "Prompts" &&
                  location.pathname.includes("/prompt-folder")) ||
                (link.label === "Workflows" &&
                  location.pathname.includes("/workflow"))
                ? "border-b-2 border-b-blue-500 text-foreground"
                : "text-muted-foreground hover:rounded-t-md hover:bg-gray-50",
              "hover:text-blue-500",
            )
          }
        >
          <span className="flex items-center">
            {link.label}
            {link.beta && (
              <span className="ml-2 rounded-full bg-gradient-to-r from-blue-500 to-blue-600 px-2 py-0.5 text-xs text-white">
                Beta
              </span>
            )}
          </span>
        </NavLink>
      ))}
    </div>
  );
}
