import { makeObservable, observable, computed, action } from "mobx";
import { ParameterContent, SchemaParameterType } from "../types";
import { convertParameterToJson } from "../utils";
import { v4 as uuidv4 } from "uuid";
class SchemaParameterStore {
  @observable type: SchemaParameterType = "string";
  @observable name: string = "";
  @observable title: string = "";
  @observable includeEnum: boolean = false;
  @observable _enums: Record<string, string> | undefined = undefined;
  @observable _required: SchemaParameterStore[] = [];
  @observable _collapsed: boolean = true;

  @observable content: ParameterContent = ""; // either a parameter or value or array description
  @observable depth: number = 0;
  private _parent: SchemaParameterStore | undefined = undefined;

  constructor(
    depth: number = 0,
    collapsed = true,
    parent?: SchemaParameterStore,
  ) {
    this._parent = parent;
    this.depth = depth;
    this._collapsed = collapsed;
    makeObservable(this);
  }

  setDepth(depth: number) {
    this.depth = depth;
    if (Array.isArray(this.content)) {
      this.content.forEach((param) => param.setDepth(depth + 1));
    }
  }

  get isCollapsed() {
    return this._collapsed;
  }

  get isExpanded() {
    return !this._collapsed;
  }

  get isParent() {
    return this.depth === 0;
  }

  get isChild() {
    return !this.isParent;
  }

  @action toggleCollapsed() {
    this._collapsed = !this._collapsed;
  }

  @action toggleRequired() {
    this._parent?.toggleRequiredField(this);
  }

  get isCompositionArray() {
    if (this.type !== "array") return false;
    if (Array.isArray(this.content)) return false;
    return typeof this.content === "object";
  }

  @action toggleArrayType() {
    if (this.isCompositionArray) {
      this.content = [
        new SchemaParameterStore(this.depth + 1, this._collapsed, this),
      ];
    } else {
      this.content = {
        type: "string",
        description: "",
      };
      this._required = [];
    }
  }

  @action
  toggleRequiredField(value: SchemaParameterStore) {
    if (this._required.includes(value)) {
      const idx = this._required.indexOf(value);
      if (idx !== undefined && idx >= 0) {
        this._required.splice(idx, 1);
      }
    } else {
      this._required.push(value);
    }
  }

  get required() {
    return !!this._parent?._required?.includes(this);
  }

  @action
  setType(type: SchemaParameterType) {
    this.type = type;
    if (["array", "object"].includes(type)) {
      this.content = [
        new SchemaParameterStore(this.depth + 1, this._collapsed, this),
      ];
    } else if (Array.isArray(this.content)) {
      this.content = "";
    }
  }

  get isEditableList() {
    return (
      (this.type === "array" && Array.isArray(this.content)) ||
      this.type === "object"
    );
  }

  @action
  addField() {
    if (typeof this.content !== "string" && Array.isArray(this.content)) {
      this.content.push(new SchemaParameterStore(this.depth + 1, false, this));
      this.setDepth(this.depth);
    } else if (["object", "array"].includes(this.type)) {
      this.content = [new SchemaParameterStore(this.depth + 1, false, this)];
    }
  }

  @action setTitle(title: string) {
    this.title = title;
  }

  @action setName(name: string) {
    this.name = name;
  }

  @computed get metadata() {
    return {
      name: this.name,
      type: this.type,
      title: this.title,
      required: this.required,
    };
  }

  @computed get hasEnums() {
    return this.enums !== undefined;
  }

  @computed get enums() {
    return this._enums ? Object.values(this._enums) : undefined;
  }

  @action setEnums(enums: string[]) {
    this._enums = enums.reduce(
      (acc, elem) => {
        acc[uuidv4()] = elem;
        return acc;
      },
      {} as Record<string, string>,
    );
  }

  @computed get enumsAvailable() {
    return this.type === "string" || this.type === "number";
  }

  @action addEnum() {
    if (!this._enums) {
      this._enums = {};
    }
    this._enums[uuidv4()] = "";
  }

  @action toggleEnums() {
    if (this.hasEnums) {
      this._enums = undefined;
    } else {
      this.addEnum();
    }
  }

  @action setContent(content: ParameterContent) {
    this.content = content;
  }

  @action deleteEnum(key: string) {
    if (!this._enums) {
      this._enums = {};
    }
    delete this._enums[key];
  }

  @action setEnum(key: string, value: string) {
    if (!this._enums) {
      this._enums = {};
    }
    this._enums[key] = value;
  }

  @action setRequired(parameters: SchemaParameterStore[]) {
    this._required = parameters;
  }

  @computed get schema() {
    const param = this;
    const baseObj = {
      type: param.type,
      description: typeof param.content == "string" ? param.content : undefined,
      title: param.title,
      required: param._required?.map((p) => p?.name || "invalid_reference"),
      enums: param.enums,
    };

    if (param.type === "object" && Array.isArray(param.content)) {
      const properties: Record<string, any> = {};

      param.content.forEach((childParam) => {
        properties[childParam.name] = {
          ...convertParameterToJson(childParam),
          name: undefined,
        };
      });

      return {
        ...baseObj,
        additionalProperties: false,
        properties,
      };
    }

    if (param.type === "array" && Array.isArray(param.content)) {
      return {
        ...baseObj,
        required: undefined,
        items: {
          type: "object",
          required: baseObj.required || [],
          additionalProperties: false,
          properties: param.content
            .map((childParam) => convertParameterToJson(childParam))
            .reduce(
              (acc, elem) => ({
                ...acc,
                [elem.name]: { ...elem, name: undefined },
              }),
              {},
            ),
        },
      };
    } else if (param.type === "array" && typeof param.content === "object") {
      return {
        ...baseObj,
        required: undefined,
        items: {
          additionalProperties: false,
          //required: baseObj.required || [],
          ...param.content,
        },
      };
    }

    if (param.includeEnum) {
      return {
        ...baseObj,
        enum: param.enums,
      };
    }

    return baseObj;
  }
}

export type ISchemaParameter = InstanceType<typeof SchemaParameterStore>;

export default SchemaParameterStore;
