import {
  EdgeStatus,
  ExecutionStatus,
  WorkflowNodeStatus,
} from "@/components/Workflows/types";
import { WEBSOCKET_MESSAGE_NAME } from "@/constants";
import { useUser } from "@/context/user-context";

import { WebSocketMessage } from "@/types/websockets";
import { displayErrorToast } from "@/utils/toast";
import { useChannel } from "ably/react";
import { useWorkflow } from "../../workflow-context";

const useExecutionStatus = () => {
  const { user } = useUser();
  const workflow = useWorkflow();

  useChannel(
    `user:${user.id}`,
    WEBSOCKET_MESSAGE_NAME.SET_EDGE_OUTPUT,
    (socketMessage: WebSocketMessage) => {
      try {
        const { data: websocketData } = socketMessage;
        if (!websocketData) return;
        const data: EdgeStatus = JSON.parse(websocketData);
        const { edge_id, status } = data;
        workflow.setEdgeStatus(edge_id, status);
      } catch (error) {
        console.log("Error processing edge output:", error);
        displayErrorToast("Error processing edge output.");
      }
    },
  );

  useChannel(
    `user:${user.id}`,
    WEBSOCKET_MESSAGE_NAME.SET_WORKFLOW_NODE_OUTPUT,
    (socketMessage: WebSocketMessage) => {
      try {
        const { data: websocketData } = socketMessage;
        if (!websocketData) return;

        const response: ExecutionStatus = JSON.parse(websocketData);

        const { workflow_node_id } = response;

        workflow.setNodeStatus(workflow_node_id, response.status);
        switch (response.status) {
          case WorkflowNodeStatus.COMPLETED:
            workflow.addOutput(workflow_node_id, {
              date: Date.now(),
              display_value: response.display_value,
              request_id: response.value?.value?.request_id,
            });

            break;
          case WorkflowNodeStatus.QUEUED:
            break;
          case WorkflowNodeStatus.FAILED:
            workflow.setErrors([
              {
                loc: [
                  "nodes",
                  String(workflow.indexOf(workflow_node_id)),
                  "node_execution_error",
                ],
                msg: response.error_message!,
                type: "runtime",
              },
            ]);
            break;
        }
      } catch (error) {
        console.log("Error processing file:", error);
        displayErrorToast("Error processing file.");
      } finally {
      }
    },
  );
};

export default useExecutionStatus;
