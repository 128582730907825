import { motion } from "framer-motion";
import { springTransition } from "./utils";

const LoadingSpinner = ({ isEdgeSelected }: { isEdgeSelected: boolean }) => (
  <g>
    <motion.circle
      r="6"
      cx="0"
      cy="0"
      fill="transparent"
      stroke={isEdgeSelected ? "#3b82f6" : "white"}
      strokeWidth="2"
      strokeDasharray="25 10"
      initial={{ scale: 0, opacity: 0, rotate: 0 }}
      animate={{
        rotate: [0, 360],
        scale: 1,
        opacity: 1,
      }}
      exit={{
        scale: 0,
        opacity: 0,
        rotate: 180,
      }}
      transition={{
        rotate: {
          duration: 1,
          repeat: Infinity,
          ease: "linear",
        },
        scale: springTransition,
        opacity: {
          duration: 0.3,
          ease: "easeInOut",
        },
      }}
    >
      <animate
        attributeName="strokeDashoffset"
        values="0;35"
        dur="1s"
        repeatCount="indefinite"
      />
    </motion.circle>
  </g>
);

const LoadingSpinner2 = ({ isEdgeSelected }: { isEdgeSelected: boolean }) => (
  <motion.svg
    width="20"
    height="20"
    viewBox="-4 0 20 20"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
  >
    <motion.circle
      r="6"
      fill="transparent"
      stroke={isEdgeSelected ? "#3b82f6" : "#2563eb"}
      strokeWidth="2"
      strokeDasharray="25 10"
      initial={{ rotate: 0 }}
      animate={{ rotate: 360 }}
      transition={{
        duration: 1,
        repeat: Infinity,
        ease: "linear",
      }}
    />
  </motion.svg>
);

export { LoadingSpinner, LoadingSpinner2 };
