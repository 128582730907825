import { Switch } from "@/components/switch";
import { observer } from "mobx-react-lite";

interface ArrayTypeSwitchProps {
  checked: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}

const ArrayTypeSwitch = (props: ArrayTypeSwitchProps) => {
  return (
    <div
      className={`mt-2 flex items-center gap-2 pl-6 ${
        props.disabled ? "opacity-50" : ""
      }`}
    >
      <span className="text-xs text-gray-600">Composition</span>
      <Switch {...props} />
      <span className="text-xs text-gray-600">Description</span>
    </div>
  );
};

export default observer(ArrayTypeSwitch);
