import { WorkflowEdge } from "@/components/Workflows/types";
import { createContext, useContext } from "react";

type ContextType =
  | {
      linkedDependencies?: string[];
      edge?: WorkflowEdge;
    }
  | undefined;

const defaultContextValue: ContextType = undefined;

export const TargetNodeContext =
  createContext<ContextType>(defaultContextValue);

export const EdgeContextProvider = ({
  children,
  value,
}: {
  children: React.ReactNode;
  value: ContextType;
}) => {
  return (
    <TargetNodeContext.Provider value={value}>
      {children}
    </TargetNodeContext.Provider>
  );
};

export const useLinkedDependencies = () => {
  return useContext(TargetNodeContext)?.linkedDependencies || [];
};

export const useEdge = () => {
  return useContext(TargetNodeContext)?.edge;
};
