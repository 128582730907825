import { useWorkflow } from "@/components/Workflows/WorkflowCanvas/workflow-context";
import { useState, useCallback, useEffect } from "react";
import { ContextMenuType } from "../../../ContextMenu";
import { transaction } from "mobx";

const useEdgeInteraction = (id: string, target: string, source: string) => {
  const workflow = useWorkflow();

  const contextMenuIsOpen = workflow.isEdgeSelected(id);
  const [hoverPosition, setHoverPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const onEdgeClick = useCallback(
    (evt: React.MouseEvent<SVGGElement>) => {
      evt.stopPropagation();
      if (workflow.isSidebarOpen) {
        transaction(() => {
          workflow.closeSidebar();
          workflow.removeActiveNode();
        });
      }
      workflow.setContextMenu({
        id,
        top: hoverPosition?.y ?? 0,
        left: hoverPosition?.x ?? 0,
        type: ContextMenuType.Edge,
      });
    },
    [hoverPosition?.x, hoverPosition?.y, id, workflow],
  );

  const handleMouseMove = useCallback(
    (event: React.MouseEvent<SVGPathElement>) => {
      const svgElement = event.currentTarget.ownerSVGElement;
      if (svgElement) {
        const point = svgElement.createSVGPoint();
        point.x = event.clientX;
        point.y = event.clientY;
        const cursorPoint = point.matrixTransform(
          svgElement.getScreenCTM()?.inverse(),
        );
        if (!contextMenuIsOpen)
          setHoverPosition({ x: cursorPoint.x, y: cursorPoint.y });
      }
    },
    [contextMenuIsOpen],
  );

  const handleMouseLeave = useCallback(() => {
    if (!contextMenuIsOpen) setHoverPosition(null);
  }, [contextMenuIsOpen]);

  useEffect(() => {
    handleMouseLeave();
  }, [handleMouseLeave]);

  return {
    hoverPosition,
    onEdgeClick,

    handleMouseMove,
    handleMouseLeave,
  };
};
export default useEdgeInteraction;
