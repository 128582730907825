import { memo } from "react";
import { ScoreMatrixCellValue } from "../types";
import { parseCellValue } from "./utils";

const PercentageChange = memo(
  ({
    cell,
    compareCell,
  }: {
    cell: ScoreMatrixCellValue;
    compareCell?: ScoreMatrixCellValue;
  }) => {
    const valueChange =
      parseCellValue(cell) -
      ((compareCell && parseCellValue(compareCell)) || 0);
    const isPositive = valueChange > 0;
    return (
      <span className={`ml-1 text-[10px]`}>
        {isPositive ? "+" : ""}
        {valueChange === Infinity ? "∞" : `${valueChange.toFixed(1)}`}
      </span>
    );
  },
);

export default PercentageChange;
