import { useAuth } from "@/context/auth-context";
import { useWorkflowVersionExecutions } from "@/queries";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import LoadingSpinner from "@/components/LoadingSpinner";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import { InputVariablesPopoverContent } from "@/components/InputVariablesPopoverContent";
import { DataTable } from "@/components/ui/data-table";
import { Popover, PopoverTrigger } from "@/components/ui/popover";
import { Skeleton } from "@/components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { ChevronsUpDown } from "lucide-react";

const useColumns = (workspaceId: string): ColumnDef<any>[] => [
  {
    accessorKey: "created_at",
    header: "Created At",
    sortingFn: "datetime",
  },
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    accessorKey: "input_variables",
    header: "Input Variables",
    cell: ({ row }) => {
      const value = row.original.input_variables;

      return value ? (
        <div className="x-36 flex flex-wrap gap-1 md:w-[200px] 2xl:w-[300px]">
          <Popover>
            <PopoverTrigger asChild>
              <div className="group flex cursor-pointer flex-wrap gap-1 rounded-md bg-none px-2 py-1 text-xs hover:bg-gray-100 hover:shadow-sm">
                {Object.keys(value)
                  .slice(0, 7)
                  .map((k) => (
                    <div
                      key={k}
                      className="rounded-full border border-gray-200 bg-gray-200 px-2 py-1 font-medium text-gray-700 group-hover:border-gray-300 group-hover:bg-gray-300 group-hover:text-gray-600"
                    >
                      {k}
                    </div>
                  ))}
                {Object.keys(value).length > 7 && (
                  <div className="rounded-full border border-gray-200 bg-gray-200 px-2 py-1 font-medium text-gray-700 group-hover:border-gray-300 group-hover:bg-gray-300 group-hover:text-gray-600">
                    +{Object.keys(value).length - 7} more
                  </div>
                )}
              </div>
            </PopoverTrigger>
            <InputVariablesPopoverContent variables={value} />
          </Popover>
        </div>
      ) : null;
    },
  },
  {
    accessorKey: "metadata",
    header: "Metadata",
    cell: ({ row }) => {
      const value = row.original.metadata;

      return value && Object.keys(value).length > 0 ? (
        <div className="x-36 flex flex-wrap gap-1 md:w-[200px] 2xl:w-[300px]">
          <Popover>
            <PopoverTrigger asChild>
              <div className="group flex cursor-pointer flex-wrap gap-1 rounded-md bg-none px-2 py-1 text-xs hover:bg-gray-100 hover:shadow-sm">
                {Object.keys(value)
                  .slice(0, 7)
                  .map((k) => (
                    <div
                      key={k}
                      className="rounded-full border border-gray-200 bg-gray-200 px-2 py-1 font-medium text-gray-700 group-hover:border-gray-300 group-hover:bg-gray-300 group-hover:text-gray-600"
                    >
                      {k}
                    </div>
                  ))}
                {Object.keys(value).length > 7 && (
                  <div className="rounded-full border border-gray-200 bg-gray-200 px-2 py-1 font-medium text-gray-700 group-hover:border-gray-300 group-hover:bg-gray-300 group-hover:text-gray-600">
                    +{Object.keys(value).length - 7} more
                  </div>
                )}
              </div>
            </PopoverTrigger>
            <InputVariablesPopoverContent variables={value} />
          </Popover>
        </div>
      ) : (
        <div className="flex justify-center">-</div>
      );
    },
  },
  {
    accessorKey: "trace_id",
    header: "Trace ID",
    cell: ({ row }) => {
      const value = row.original.trace_id;
      return value ? (
        <a
          href={`/workspace/${workspaceId}/traces/${value}`}
          className="text-blue-500 hover:text-blue-700"
        >
          {value}
        </a>
      ) : null;
    },
  },
  {
    accessorKey: "workflow_version_id",
    header: "Agent Version ID",
  },
];

interface WorkflowExecutionTableProps {
  workflowVersionId: number | undefined;
}

const TableShimmer = ({
  table,
  rowCount = 5,
}: {
  table: any;
  rowCount?: number;
}) => {
  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup: any) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header: any) => (
                <TableHead key={header.id} className="whitespace-nowrap">
                  <Button variant="ghost" className="-ml-3 h-8" disabled>
                    <Skeleton width="100px" height="1rem" />
                    <ChevronsUpDown className="ml-2 h-4 w-4 text-gray-300" />
                  </Button>
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {Array.from({ length: rowCount }).map((_, index) => (
            <TableRow key={index}>
              {table.getAllColumns().map((column: any) => (
                <TableCell key={column.id}>
                  <Skeleton width="100%" height="1rem" />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

const WorkflowExecutionTable = ({
  workflowVersionId,
}: WorkflowExecutionTableProps) => {
  const authContext = useAuth();
  const userToken = authContext?.userToken || "";
  const { workspaceId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sorting, setSorting] = useState<SortingState>([]);
  const columns = useColumns(workspaceId!);

  const {
    data,
    isLoading,
    error,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useWorkflowVersionExecutions(
    workflowVersionId,
    Number(workspaceId),
    userToken,
    currentPage,
    rowsPerPage,
  );

  const items = useMemo(
    () => data?.pages[0]?.workflow_version_executions || [],
    [data],
  );
  const totalPages = useMemo(() => data?.pages[0]?.pages || 1, [data]);

  const handlePageChange = useCallback(
    (page: number) => {
      setCurrentPage(page);
      if (page > currentPage && hasNextPage) {
        fetchNextPage();
      }
    },
    [currentPage, hasNextPage, fetchNextPage],
  );

  const handleRowsPerPageChange = useCallback((value: string) => {
    setRowsPerPage(Number(value));
    setCurrentPage(1);
  }, []);

  const table = useReactTable({
    data: items,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  if (error) {
    return <div className="text-red-500">Error: {error.message}</div>;
  }

  return (
    <div className="space-y-4 pb-6">
      {isLoading || !workflowVersionId ? (
        <TableShimmer table={table} rowCount={5} />
      ) : (
        <DataTable
          table={table}
          isLoading={isLoading}
          emptyMessage={
            <div className="py-16 text-center text-gray-500">
              <p className=" text-lg font-semibold">No executions found</p>
              <p className="text-sm">
                There are no workflow executions to display.
              </p>
            </div>
          }
        />
      )}
      {items.length > 0 && (
        <div className="flex flex-col items-center justify-between gap-4 sm:flex-row">
          <div className="flex items-center space-x-2">
            <span>Rows per page:</span>
            <Select
              value={rowsPerPage.toString()}
              onValueChange={handleRowsPerPageChange}
            >
              <SelectTrigger className="w-[80px]">
                <SelectValue>{rowsPerPage}</SelectValue>
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="10">10</SelectItem>
                <SelectItem value="25">25</SelectItem>
                <SelectItem value="50">50</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="flex items-center space-x-2">
            <Button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1 || isLoading}
              variant="outline"
              size="sm"
            >
              Previous
            </Button>
            <span className="text-sm">
              Page {currentPage} of {totalPages}
            </span>
            <Button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={
                (currentPage === totalPages && !hasNextPage) || isLoading
              }
              variant="outline"
              size="sm"
            >
              Next
            </Button>
          </div>
        </div>
      )}
      {isFetchingNextPage && (
        <div className="flex justify-center">
          <LoadingSpinner size="small" />
        </div>
      )}
    </div>
  );
};

export default WorkflowExecutionTable;
