import { getTruncatedFolderPath } from "@/utils/utils";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import { Link, useParams } from "react-router-dom";
import { Breadcrumbs } from "./components/Breadcrumbs";
import LoadingSpinner from "./components/LoadingSpinner";
import NotFoundMessage from "./components/ui/not-found";
import { useAuth } from "./context/auth-context";
import { useUser } from "./context/user-context";
import { useFolders, usePromptRegistry, usePromptVersion } from "./queries";
import { Folder } from "./types/folders";
import { PromptVersion } from "@/types/apiGetters";

const formatPromptVersionForDiffView = (data?: PromptVersion) =>
  JSON.stringify(
    { ...data?.metadata?.model, ...data?.prompt_template },
    null,
    2,
  ).replace(/\\n/g, "\n");

export default function DiffPromptTemplate() {
  const userContext = useUser();
  const auth = useAuth();
  const userToken = auth!.userToken!;
  const workspaceId = userContext.activeWorkspaceId!;
  const { promptId, versionNumber, diffVersionNumber } = useParams();
  const promptRegistryQuery = usePromptRegistry(userToken, Number(promptId));

  const sourceVersionQuery = usePromptVersion(
    userToken,
    Number(promptId),
    Number(versionNumber),
  );

  const targetVersionQuery = usePromptVersion(
    userToken,
    Number(promptId),
    Number(diffVersionNumber),
  );

  const sourceVersion = sourceVersionQuery.data;
  const targetVersion = targetVersionQuery.data;

  const promptRegistry = promptRegistryQuery.data;

  const foldersQuery = useFolders(
    userToken!,
    workspaceId!,
    promptRegistry?.folder_id?.toString(),
  );

  if (!promptId || !versionNumber || !diffVersionNumber) {
    return (
      <NotFoundMessage
        title="Template not found"
        subtitle="We couldn't find the prompt template you were looking for."
        backLink="/prompt"
        backLinkMessage="Back to Prompt Registry"
      />
    );
  }

  if (
    promptRegistryQuery.isLoading ||
    sourceVersionQuery.isLoading ||
    targetVersionQuery.isLoading
  ) {
    return (
      <div className="p-5">
        <LoadingSpinner size={5} />
      </div>
    );
  }

  if (!promptRegistry || !sourceVersion || !targetVersion) {
    return (
      <NotFoundMessage
        title="Template not found"
        subtitle="We couldn't find the prompt template you were looking for."
        backLink="/prompt"
        backLinkMessage="Back to Prompt Registry"
      />
    );
  }

  const folderPath: string[] =
    foldersQuery.data?.path?.map((folder: Folder) => folder.name) ?? [];

  return (
    <div className="p-1">
      <div className="pb-3">
        <Breadcrumbs
          items={
            [
              "Registry",
              "Prompt Registry",
              ...(getTruncatedFolderPath(folderPath) ?? null),
              promptRegistry.prompt_name,
              `Diff View (${versionNumber} vs ${diffVersionNumber})`,
            ].filter(Boolean) as string[]
          }
          navigateUrl={`/workspace/${userContext?.activeWorkspaceId}/prompt/${promptId}/version/${versionNumber}`}
          pageTitle={`${promptRegistry.prompt_name} [Diff view]`}
          pageSubtitle="View the differences between two versions of the selected prompt template."
          backLink={`/workspace/${userContext?.activeWorkspaceId}/prompt/${promptId}/version/${versionNumber}`}
        />
      </div>
      <div className="flex w-full">
        <div className="w-full rounded-md border-2 border-gray-200">
          <div className="grid grid-cols-2 gap-4 bg-gray-100 p-2">
            <div className="py-2 text-center">
              <Link
                to={`/workspace/${userContext?.activeWorkspaceId}/prompt/${promptId}/version/${versionNumber}`}
                className="text-lg font-semibold hover:text-blue-600"
              >
                Version {versionNumber} (source)
              </Link>
            </div>
            <div className="py-2 text-center">
              <Link
                to={`/workspace/${userContext?.activeWorkspaceId}/prompt/${promptId}/version/${diffVersionNumber}`}
                className="text-lg font-semibold hover:text-blue-600"
              >
                Version {diffVersionNumber} (target)
              </Link>
            </div>
          </div>
          <ReactDiffViewer
            oldValue={formatPromptVersionForDiffView(sourceVersion)}
            newValue={formatPromptVersionForDiffView(targetVersion)}
            splitView={true}
            hideLineNumbers={true}
            showDiffOnly={true}
            extraLinesSurroundingDiff={2}
            compareMethod={DiffMethod.WORDS}
          />
        </div>
      </div>
    </div>
  );
}
