interface LightSwitchProps {
  isOn: boolean;
  onToggle: (value: boolean) => void;
  onLabel?: string;
  offLabel?: string;
  size?: "sm" | "md" | "lg";
}

const LightSwitch = ({
  isOn,
  onToggle,
  onLabel = "On",
  offLabel = "Off",
  size = "md",
}: LightSwitchProps) => {
  const sizeClasses = {
    sm: "h-6 text-xs",
    md: "h-7 text-sm",
    lg: "h-8 text-base",
  };

  return (
    <div
      className="relative inline-flex cursor-pointer items-center rounded-full bg-gray-100 p-0.5"
      onClick={() => onToggle(!isOn)}
    >
      <div
        className={`absolute inset-0.5 w-[calc(50%-2px)] rounded-full bg-white transition-transform duration-150 ${
          isOn ? "translate-x-0" : "translate-x-full"
        }`}
      />
      <div
        className={`${
          sizeClasses[size]
        } relative flex items-center justify-center rounded-full px-3 font-medium transition-all duration-150 ${
          isOn ? "text-gray-900" : "text-gray-500"
        }`}
      >
        {onLabel}
      </div>
      <div
        className={`${
          sizeClasses[size]
        } relative flex items-center justify-center rounded-full px-3 font-medium transition-all duration-150 ${
          isOn ? "text-gray-500" : "text-gray-900"
        }`}
      >
        {offLabel}
      </div>
    </div>
  );
};

export default LightSwitch;
