import { ViewportPortal } from "@xyflow/react";
import { AnimatePresence, motion } from "framer-motion";
import BackgroundContextMenu from "./BackgroundContextMenu";
import EdgeContextMenu from "./EdgeContextMenu";
import NodeContextMenu from "./NodeContextMenu";

export enum ContextMenuType {
  Node = "node",
  Background = "background",
  Edge = "edge",
  None = "none",
}
interface ContextMenuProps {
  id: string;
  type: ContextMenuType;
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
  onSelect?: () => void;
  [key: string]: any;
}

const ContextMenuBody = ({
  id,
  type,
  onSelect,
  position,
}: ContextMenuProps) => {
  if (type === ContextMenuType.Node) {
    return <NodeContextMenu id={id} onClose={onSelect} />;
  } else if (type === ContextMenuType.Background) {
    return <BackgroundContextMenu onClose={onSelect} position={position} />;
  } else if (type === ContextMenuType.Edge) {
    return <EdgeContextMenu id={id} onClose={onSelect} />;
  }
  return null;
};

export default function ContextMenu({
  id,
  type,
  top,
  left,
  right,
  bottom,
  onSelect,
  ...props
}: ContextMenuProps) {
  return (
    <ViewportPortal>
      <AnimatePresence>
        <motion.div
          className="pointer-events-auto relative z-[1001] w-fit rounded-md border border-gray-200 bg-white shadow-md"
          tabIndex={0}
          style={{
            top,
            left,
          }}
          initial={{ opacity: 0, scale: 0.8, y: -10 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          exit={{ opacity: 0, scale: 0.8, y: 10 }}
          transition={{
            duration: 0.2,
            type: "spring",
            stiffness: 500,
            damping: 30,
          }}
          {...props}
        >
          <ContextMenuBody
            id={id}
            type={type}
            onSelect={onSelect}
            position={{ x: left, y: top }}
          />
        </motion.div>
      </AnimatePresence>
    </ViewportPortal>
  );
}
