import { PromptVersionSnippet } from "@/types/apiGetters";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TextareaProps } from "../ui/textarea";
import Content from "./Content";
import { ContentAreaProvider } from "./content-area-context";
import { ITextAreaStore } from "./store";
import { escapeXml } from "./utils";

const ContentArea = (
  props: TextareaProps & {
    isXrayMode?: boolean;
    sourcedSnippets?: Array<PromptVersionSnippet>;
  },
) => {
  const contentAreaRef = useRef<ITextAreaStore | null>(null);
  const [externalUpdates, setExternalUpdates] = useState(0);
  const wasXrayMode = useRef(props.isXrayMode);

  const checkForChanges = useCallback(() => {
    if (!contentAreaRef.current) return;

    const didXrayModeChange = props.isXrayMode !== wasXrayMode.current;
    const didChange =
      props.value !== contentAreaRef.current.getContent() || didXrayModeChange;

    if (didXrayModeChange) wasXrayMode.current = props.isXrayMode;

    if (didChange) setExternalUpdates((prev) => prev + 1);
  }, [props.isXrayMode, props.value]);

  useEffect(() => {
    checkForChanges();
  }, [checkForChanges]);

  const escapedValue = useMemo(() => escapeXml(props.value), [props.value]);

  return (
    <ContentAreaProvider
      key={externalUpdates}
      ref={contentAreaRef}
      sourcedSnippets={props.sourcedSnippets}
      value={escapedValue}
    >
      <Content {...props} />
    </ContentAreaProvider>
  );
};

export default ContentArea;
