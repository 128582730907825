import { Node, Edge, useViewport } from "@xyflow/react";
import { useCallback } from "react";
import { ContextMenuType } from "../ContextMenu";
import { useWorkflow } from "../../workflow-context";

const useContextMenu = (ref: React.RefObject<any>) => {
  const workflow = useWorkflow();
  const { isSidebarOpen } = workflow;

  const viewport = useViewport();
  const onNodeContextMenu = useCallback(
    (event: React.MouseEvent, node: Node) => {
      event.preventDefault();
      event.stopPropagation();

      if (isSidebarOpen) workflow.closeSidebar();
      const pane = ref.current.getBoundingClientRect();

      // Transform the mouse position to the correct viewport coordinates
      const x = (event.clientX - pane.left - viewport.x) / viewport.zoom;
      const y = (event.clientY - pane.top - viewport.y) / viewport.zoom;

      const workflowNode = workflow.getNodeById(node.id);

      workflow.setActiveNode(workflowNode);
      workflow.setContextMenu({
        id: node.id,
        top: y,
        left: x,
        type: ContextMenuType.Node,
      });
    },
    [isSidebarOpen, ref, viewport.x, viewport.y, viewport.zoom, workflow],
  );

  const onEdgeContextMenu = useCallback(
    (event: React.MouseEvent, edge: Edge) => {
      event.preventDefault();
      event.stopPropagation();

      if (isSidebarOpen) workflow.closeSidebar();
      const pane = ref.current.getBoundingClientRect();

      // Transform the mouse position to the correct viewport coordinates
      const x = (event.clientX - pane.left - viewport.x) / viewport.zoom;
      const y = (event.clientY - pane.top - viewport.y) / viewport.zoom;

      workflow.setContextMenu({
        id: edge.id,
        top: y,
        left: x,
        type: ContextMenuType.Edge,
      });
    },
    [isSidebarOpen, ref, viewport.x, viewport.y, viewport.zoom, workflow],
  );

  // Generic context menu with option to add new node
  const onContextMenu = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (isSidebarOpen) workflow.closeSidebar();
      const pane = ref.current.getBoundingClientRect();
      // Transform the mouse position to the correct viewport coordinates
      const x = (event.clientX - pane.left - viewport.x) / viewport.zoom;
      const y = (event.clientY - pane.top - viewport.y) / viewport.zoom;
      workflow.setContextMenu({
        id: "generic-context-menu",
        top: y,
        left: x,
        type: ContextMenuType.Background,
      });
    },
    [isSidebarOpen, ref, viewport.x, viewport.y, viewport.zoom, workflow],
  );

  return [
    [workflow.contextMenu, workflow.setContextMenu.bind(workflow)],
    { onNodeContextMenu, onEdgeContextMenu, onContextMenu },
  ] as const;
};

export default useContextMenu;
