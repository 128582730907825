import { useCallback } from "react";
import { DiffState, ScoreMatrixCellValue } from "../types";
import { parseCellValue } from "../Matrix/utils";

const useDiffStyles = () => {
  const getCellDiff = useCallback(
    (
      value: ScoreMatrixCellValue,
      compareValue: ScoreMatrixCellValue | undefined,
      showDiff?: boolean,
    ): DiffState => {
      if (showDiff && compareValue === undefined) return "added";
      if (!showDiff || compareValue === undefined) return null;

      // Handle string comparisons
      if (typeof value === "string" && typeof compareValue === "string") {
        return value !== compareValue ? "changed" : null;
      }

      // Handle numeric comparisons
      if (
        typeof parseCellValue(value) === "number" &&
        typeof parseCellValue(compareValue) === "number"
      ) {
        const parsedValue = parseCellValue(value);
        const parsedCompareValue = parseCellValue(compareValue);

        if (parsedValue === parsedCompareValue) return null;
        // Check ascending property and determine if increase is positive
        const isAscending =
          typeof value === "object" && value.positive_metric !== undefined
            ? value.positive_metric
            : typeof compareValue === "object" &&
              compareValue.positive_metric !== undefined
            ? compareValue.positive_metric
            : true; // Default to true if no ascending property found

        if (parsedValue !== parsedCompareValue) {
          if (parsedValue > parsedCompareValue) {
            return isAscending ? "increased" : "decreased";
          }
          return isAscending ? "decreased" : "increased";
        }
      }

      // Handle mixed types
      return value !== compareValue ? "changed" : null;
    },
    [],
  );

  const getDiffStyle = useCallback((diffState: DiffState): string => {
    switch (diffState) {
      case "increased":
        return "group-hover:bg-green-200 bg-green-100";
      case "decreased":
        return "group-hover:bg-red-200 bg-red-100";
      case "changed":
        return "group-hover:bg-yellow-200 bg-yellow-100";
      case "added":
        return "group-hover:bg-blue-200 bg-blue-100";
      case "removed":
        return "group-hover:bg-red-200 bg-red-100";
      default:
        return "group-hover:bg-gray-100 bg-gray-50";
    }
  }, []);

  return { getCellDiff, getDiffStyle };
};

export default useDiffStyles;
