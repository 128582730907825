import { Button } from "@/components/ui/button";
import Checkbox from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { PlusIcon, TrashIcon } from "@heroicons/react/outline";
import { observer } from "mobx-react-lite";
import { useSchemaEditor } from "..";

const Enums = ({
  includeEnum,
  enums,
  addEnum,
  setEnum,
  toggleEnums,
  deleteEnum,
}: {
  includeEnum: boolean;
  enums: Record<string, string> | undefined;
  addEnum: () => void;
  setEnum: (key: string, value: string) => void;
  deleteEnum: (key: string) => void;
  toggleEnums: () => void;
}) => {
  const editor = useSchemaEditor();
  const isReadonly = editor.isReadonly;
  return (
    <div className="mt-2">
      <div>
        <Checkbox
          checked={includeEnum}
          onChange={(e) => {
            toggleEnums();
          }}
          label="Include Enum Values"
          disabled={isReadonly}
        />
      </div>

      {includeEnum && enums && (
        <div className="ml-4 mt-2">
          <div className="mb-3 text-sm text-gray-600">
            Add one or more enum values:
          </div>
          {Object.entries(enums).map(([key, value], index) => (
            <div key={index} className="mb-2 flex gap-2">
              <Input
                type="text"
                value={value}
                onChange={(e) => {
                  setEnum(key, e.target.value);
                }}
                placeholder="Enter enum value"
                className="flex-1"
                readOnly={isReadonly}
              />
              <Button
                onClick={() => {
                  deleteEnum(key);
                }}
                variant={"destructiveOutline"}
                disabled={isReadonly}
              >
                <TrashIcon className="h-4 w-4" />
              </Button>
            </div>
          ))}
          <Button
            onClick={addEnum}
            variant={"outline"}
            size={"tinyButton"}
            disabled={isReadonly}
          >
            <PlusIcon className="h-3 w-3" /> Add Enum Value
          </Button>
        </div>
      )}
    </div>
  );
};

export default observer(Enums);
