interface JsonPathProps {
  value: string | null;
  onChange: (value: string | null) => void;
  readonly?: boolean;
}

/*
const DEFAULT_JSON_PATH = "$.*";
const JsonPathInput = ({ onChange, value, readonly }: JsonPathProps) => {
  return (
    <div className="relative flex items-center">
      <Input
        placeholder={DEFAULT_JSON_PATH}
        value={value as string}
        onChange={(e) => onChange(e.target.value)}
        readOnly={readonly}
      />
      {!readonly && (
        <button
          className="absolute right-2 text-gray-500 hover:text-gray-700"
          onClick={() => onChange(null)}
        >
          <XIcon className="h-4 w-4" />
        </button>
      )}
    </div>
  );
};

*/

const JsonPath = ({ value, onChange, readonly }: JsonPathProps) => {
  return null; // disable temporarily until BE supports json path
  /*
  const hasJsonPath = value !== null;
  return (
    <div className="mt-1 flex flex-col">
      {!hasJsonPath && !readonly && (
        <Button
          variant="outline"
          className="w-full"
          size={"tinyButton"}
          onClick={() => onChange(DEFAULT_JSON_PATH)}
        >
          Use JSON Path
        </Button>
      )}
      {hasJsonPath && (
        <JsonPathInput value={value} onChange={onChange} readonly={readonly} />
      )}
    </div>
  );
  */
};

export default JsonPath;
