import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { cn } from "@/lib/utils";
import { ArrowsExpandIcon } from "@heroicons/react/outline";
import Editor from "@monaco-editor/react";
import { FC, useState } from "react";
import RuntimeDetails from "./RuntimeDetails";

interface CodeEditorProps {
  language: string;
  code: string;
  setCode: (code: string) => void;
  editorOptions: any;
  dataIsValid?: { code: boolean };
  className?: string;
}
const EDITOR_OPTIONS = {
  minimap: { enabled: false },
  scrollBeyondLastLine: false,
  fontSize: 13,
  lineHeight: 20,
  padding: { top: 12, bottom: 12 },
  renderLineHighlight: "none",
  fontFamily: "menlo, monaco, monospace",
} as const;

const CodeEditor: FC<CodeEditorProps> = ({
  language,
  code,
  setCode,
  editorOptions,
  dataIsValid,
  className,
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  return (
    <>
      <div
        className={cn(
          "relative col-span-3 w-full overflow-hidden rounded-md border border-gray-200",
          className,
        )}
      >
        <Button
          variant="ghost"
          size="sm"
          className="absolute right-2 top-2 z-10"
          onClick={() => setIsFullscreen(true)}
        >
          <ArrowsExpandIcon className="h-4 w-4" />
        </Button>
        <Editor
          height="300px"
          defaultLanguage={language.toLowerCase()}
          language={language.toLowerCase()}
          value={code}
          onChange={(value) => setCode(value || "")}
          options={{ ...editorOptions, ...EDITOR_OPTIONS }}
          className="w-full"
        />
      </div>
      {dataIsValid && !dataIsValid.code && (
        <div className="col-span-3 mt-2 text-left text-xs text-red-500">
          Code is required
        </div>
      )}
      <div className="col-span-3 text-sm text-gray-500">
        <RuntimeDetails language={language.toUpperCase()} />
      </div>
      <Dialog open={isFullscreen} onOpenChange={setIsFullscreen}>
        <DialogContent className="max-w-[90vw]">
          <div className="h-[80vh]">
            <Editor
              defaultLanguage={language.toLowerCase()}
              language={language.toLowerCase()}
              value={code}
              onChange={(value) => setCode(value || "")}
              options={{ ...editorOptions, ...EDITOR_OPTIONS }}
              className="h-full w-full"
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CodeEditor;
