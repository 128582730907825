import { Button } from "@/components/ui/button";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useCreateWebhook, useDeleteWebhook } from "@/queries";
import { displayErrorToast } from "@/utils/toast";
import { XIcon } from "@heroicons/react/outline";
import { FC, FormEvent, useState } from "react";

const WebhookForm: FC = () => {
  const [url, setUrl] = useState("");
  const auth = useAuth();
  const userContext = useUser();
  const userToken = auth!.userToken;
  const createWorkspaceWebhook = useCreateWebhook(userToken!);
  const deleteWorkspaceWebhook = useDeleteWebhook(userToken!);
  const handleDelete = async (webhookId: number) => {
    try {
      await deleteWorkspaceWebhook.mutateAsync(webhookId);
      userContext.workspaceWebhooks = userContext.workspaceWebhooks.filter(
        (webhook) => webhook.id !== webhookId,
      );
    } catch (error) {
      displayErrorToast(error);
      console.error(error);
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      const newWebhook = await createWorkspaceWebhook.mutateAsync({
        url,
        workspace_id: userContext?.activeWorkspaceId!,
      });
      if (newWebhook) {
        userContext.workspaceWebhooks.push(newWebhook);
        setUrl("");
      }
    } catch (error) {
      displayErrorToast(error);
      console.error(error);
    }
  };

  const isDisabled = () => {
    return createWorkspaceWebhook.isLoading || url.trim() === "";
  };
  const renderForm = () => (
    <form onSubmit={handleSubmit} className="mt-6 space-y-4">
      <div>
        <label
          className="block text-sm font-medium text-gray-700"
          htmlFor="url"
        >
          Webhook URL
        </label>
        <input
          className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500"
          id="url"
          name="url"
          onChange={(e) => setUrl(e.target.value)}
          required
          type="url"
          placeholder="https://example.com/promptlayer-webhook"
          value={url}
        />
      </div>
      <div className="inline-flex items-center">
        <Button
          disabled={isDisabled()}
          isLoading={createWorkspaceWebhook.isLoading}
          type="submit"
        >
          Add Webhook
        </Button>
      </div>
    </form>
  );

  const renderWebhookDetails = () => (
    <div>
      <div className="my-4 border-b border-gray-300 pb-2 text-base font-medium text-gray-800">
        Webhooks:
      </div>
      {userContext.workspaceWebhooks.length > 0 ? (
        userContext.workspaceWebhooks.map((webhook) => (
          <div key={webhook.id} className="mt-4 flex items-center gap-x-2">
            <Button
              variant="ghost"
              onClick={() => handleDelete(webhook.id)}
              size="sm"
            >
              <XIcon className="h-4 w-4 text-red-500" />
            </Button>
            <div className="text-base text-gray-600">{webhook.url}</div>
          </div>
        ))
      ) : (
        <div className="text-base text-gray-600">No webhooks available.</div>
      )}
    </div>
  );

  return (
    <>
      <div className="mt-2">
        <a
          href="https://docs.promptlayer.com/features/prompt-registry#setting-up-a-webhook"
          target="_blank"
          rel="noreferrer"
          className="text-sm text-blue-500 hover:text-blue-400"
        >
          Learn more about webhooks here.
        </a>
      </div>
      <div className="max-w-lg px-2">{renderWebhookDetails()}</div>
      <div className="max-w-lg px-2">{renderForm()}</div>
    </>
  );
};

export default WebhookForm;
