import { WorkflowEdge } from "@/components/Workflows/types";
import { useEffect } from "react";
import useEdgeChanges from "./useEdgeChanges";
import { IWorkflow } from "@/components/Workflows/WorkflowCanvas/workflow-store";

const useEdgeCleanup = (
  edge: WorkflowEdge | undefined,
  workflow: IWorkflow,
) => {
  const { hasChanges, initialEdgeData } = useEdgeChanges(edge);

  useEffect(() => {
    return () => {
      if (
        hasChanges &&
        !workflow.readonly &&
        edge &&
        !workflow.isEdgeSelected(edge.id)
      ) {
        workflow.updateEdge(initialEdgeData);
      }
    };
  }, [edge, hasChanges, initialEdgeData, workflow]);
};

export default useEdgeCleanup;
