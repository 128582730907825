import { ColumnType } from "@/types/evaluate";

export const ColumnTypes: Record<
  string,
  { displayName: string; description: string }
> = {
  [ColumnType.PROMPT_TEMPLATE]: {
    displayName: "Prompt Template",
    description: "Run a prompt through an LLM.",
  },
  [ColumnType.ENDPOINT]: {
    displayName: "Custom API Endpoint",
    description: "Send data to your URL endpoint.",
  },
  [ColumnType.HUMAN]: {
    displayName: "Human Input",
    description: "Let a human fill in the data.",
  },
  [ColumnType.COMPARE]: {
    displayName: "Equality Comparison",
    description: "Generate a diff between two values",
  },
  [ColumnType.CONTAINS]: {
    displayName: "Contains Value",
    description: "",
  },
  [ColumnType.REGEX]: {
    displayName: "Regex Match",
    description: "",
  },
  [ColumnType.ABSOLUTE_NUMERIC_DISTANCE]: {
    displayName: "Absolute Numeric Distance",
    description: "",
  },
  [ColumnType.JSON_PATH]: {
    displayName: "JSON Extraction",
    description: "",
  },
  [ColumnType.PARSE_VALUE]: {
    displayName: "Parse Value",
    description: "",
  },
  [ColumnType.LLM_ASSERTION]: {
    displayName: "LLM Assertion",
    description: "",
  },
  [ColumnType.VARIABLE]: {
    displayName: "Static Value",
    description: "",
  },
  [ColumnType.COALESCE]: {
    displayName: "Coalesce",
    description: "",
  },
  [ColumnType.ASSERT_VALID]: {
    displayName: "Type Validation",
    description: "",
  },
  [ColumnType.COSINE_SIMILARITY]: {
    displayName: "Cosine Similarity",
    description: "",
  },
  [ColumnType.COUNT]: {
    displayName: "Count",
    description: "",
  },
  [ColumnType.MATH_OPERATOR]: {
    displayName: "Comparison Operator",
    description: "",
  },
  [ColumnType.XML_PATH]: {
    displayName: "XML Extraction",
    description: "",
  },
  [ColumnType.REGEX_EXTRACTION]: {
    displayName: "Regex Extraction",
    description: "",
  },
  [ColumnType.COMBINE_COLUMNS]: {
    displayName: "Combine Results",
    description: "",
  },
  [ColumnType.MIN_MAX]: {
    displayName: "Min/Max",
    description: "",
  },
  [ColumnType.CODE_EXECUTION]: {
    displayName: "Code Execution",
    description: "Run code for each row.",
  },
  [ColumnType.WORKFLOW]: {
    displayName: "Agent",
    description: "Run an agent",
  },
};

const primaryColumnTypes = [
  ColumnType.PROMPT_TEMPLATE,
  ColumnType.WORKFLOW,
  ColumnType.ENDPOINT,
  ColumnType.HUMAN,
  ColumnType.CODE_EXECUTION,
];

const simpleEvalColumnTypes = [
  ColumnType.COMPARE,
  ColumnType.CONTAINS,
  ColumnType.REGEX,
  ColumnType.ABSOLUTE_NUMERIC_DISTANCE,
  ColumnType.REGEX_EXTRACTION,
  ColumnType.MIN_MAX,
];

const dataManipulationColumnTypes = [
  ColumnType.JSON_PATH,
  ColumnType.PARSE_VALUE,
  ColumnType.VARIABLE,
  ColumnType.COALESCE,
  ColumnType.ASSERT_VALID,
  ColumnType.COUNT,
  ColumnType.MATH_OPERATOR,
  ColumnType.XML_PATH,
  ColumnType.COMBINE_COLUMNS,
];

const llmEvalColumnTypes = [
  ColumnType.LLM_ASSERTION,
  ColumnType.COSINE_SIMILARITY,
];

export const ColumnCategories = {
  "Data Sources": primaryColumnTypes.map((type) => ({
    type,
    name: ColumnTypes[type].displayName,
    description: ColumnTypes[type].description,
  })),
  "Simple Evals": simpleEvalColumnTypes.map((type) => ({
    type,
    name: ColumnTypes[type].displayName,
    description: ColumnTypes[type].description,
  })),
  "LLM Evals": llmEvalColumnTypes.map((type) => ({
    type,
    name: ColumnTypes[type].displayName,
    description: ColumnTypes[type].description,
  })),
  "Helper Functions": dataManipulationColumnTypes.map((type) => ({
    type,
    name: ColumnTypes[type].displayName,
    description: ColumnTypes[type].description,
  })),
};
