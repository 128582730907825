import SchemaParameterStore from "./SchemaParameter/schema-parameter-store";

export type SchemaEditorMode = "interactive" | "json";

export type SchemaParameterType =
  | "string"
  | "number"
  | "boolean"
  | "object"
  | "array";

export const SCHEMA_PARAMETER_TYPES: SchemaParameterType[] = [
  "string",
  "number",
  "boolean",
  "object",
  "array",
];

export const isSchemaParameterType = (
  type: string,
): type is SchemaParameterType => {
  return SCHEMA_PARAMETER_TYPES.includes(type as SchemaParameterType);
};
interface ArrayDescription {
  type: string;
  description: string;
}
export type ParameterContent =
  | SchemaParameterStore[]
  | string
  | ArrayDescription;
