import { useFunctionDialogContext } from "@/components/FunctionsModal";
import { FunctionFormValues } from "@/components/FunctionsModal/Types";

export const useFunctionForm = () => {
  const {
    functions,
    isEditing,
    parameterEditorMode,
    setIsAddingFunction,
    setEditingFunction,
    onSubmit,
  } = useFunctionDialogContext();

  const functionNames = functions.map((f) => f.name);

  const closeForm = () => {
    setIsAddingFunction(false);
    setEditingFunction(null);
  };

  const handleSubmit = (functionSchema: FunctionFormValues) => {
    if (functionNames.includes(functionSchema?.name) && isEditing) {
      if (
        !window.confirm(
          "A function with this name already exists. Are you sure you want to overwrite it?",
        )
      ) {
        return;
      }
    }

    if (functionSchema.parameters) {
      functionSchema.parameters.type = "object";
    }
    closeForm();
    onSubmit!(functionSchema);
  };

  return {
    handleSubmit,
    closeForm,
    parameterEditorMode,
  };
};
