import { Textarea } from "@/components/ui/textarea";
import { observer } from "mobx-react-lite";
import SchemaParameter from ".";
import { useSchemaEditor } from "..";
import {
  ParameterContent as IParameterContent,
  SchemaParameterType,
} from "../types";
import TypeSelector from "./TypeSelector";

interface ParameterContentProps {
  content: IParameterContent;
  onChangeContent: (value: IParameterContent) => void;
}
const ParameterContent = observer(
  ({ content, onChangeContent }: ParameterContentProps) => {
    const editor = useSchemaEditor();
    const isReadonly = editor.isReadonly;

    if (typeof content === "string") {
      return (
        <Textarea
          placeholder="Description"
          value={content}
          onChange={(e) => !isReadonly && onChangeContent(e.target.value)}
          readOnly={isReadonly}
        />
      );
    } else if (typeof content === "object" && !Array.isArray(content)) {
      return (
        <div className="mb-4 flex flex-col gap-y-1 pl-6">
          <TypeSelector
            value={content.type as SchemaParameterType}
            onChange={(value) =>
              !isReadonly &&
              onChangeContent({
                type: value,
                description: content.description,
              })
            }
            disabled={isReadonly}
          />
          <Textarea
            placeholder="Description"
            value={content.description}
            onChange={(e) =>
              !isReadonly &&
              onChangeContent({
                type: content.type,
                description: e.target.value,
              })
            }
            readOnly={isReadonly}
          />
        </div>
      );
    } else {
      return (
        <div className="flex flex-col">
          {content?.map((item, index) => (
            <SchemaParameter key={index} parameter={item} />
          ))}
        </div>
      );
    }
  },
);

const ParameterContentHelper = (props: {
  content: IParameterContent;
  onChangeContent: (value: IParameterContent) => void;
  children?: React.ReactNode;
  before?: React.ReactNode;
}) => {
  return (
    <div
      className={`mb-3 flex flex-col gap-y-2 ${
        typeof props.content !== "string"
          ? "relative before:absolute before:left-2 before:top-0 before:h-full before:w-[1px] before:bg-gray-100 before:content-[''] after:absolute after:bottom-0 after:left-2 after:h-[1px] after:w-full after:bg-gray-100 after:content-[''] hover:before:bg-gray-300 hover:after:bg-gray-300"
          : ""
      }`}
    >
      <div className="flex justify-center">{props.before}</div>
      <ParameterContent
        content={props.content}
        onChangeContent={props.onChangeContent}
      />
      {props.children}
    </div>
  );
};

export default ParameterContentHelper;
