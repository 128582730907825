import { SearchableDropdown } from "@/components/ui/searchable-dropdown";
import { formatRawNodeTypeToDisplayName } from "@/components/Workflows/utils";
import { ColumnCategories, ColumnTypes } from "@/constants/column-types";
import { ColumnType, ColumnTypeIcon, ReportColumn } from "@/types/evaluate";

export const ChooseColumnType = ({
  patchColumnData,
  columnData,
  editable,
}: {
  patchColumnData: (
    data:
      | Partial<ReportColumn>
      | ((prevState: Partial<ReportColumn>) => Partial<ReportColumn>),
  ) => void;
  columnData: Partial<ReportColumn>;
  editable: boolean;
}) => {
  return (
    <div>
      <div className="text-lg font-medium">Choose step type</div>
      <div className="grid grid-cols-3 items-center gap-4 py-4">
        <div className="text-md col-span-1">Type:</div>
        <div className="col-span-2 items-center justify-center">
          <SearchableDropdown
            categories={Object.entries(ColumnCategories).map(
              ([label, items]) => ({
                label,
                items: items.map((item) => ({
                  id: item.type,
                  description: item.description,
                  label: item.name,
                  icon: ColumnTypeIcon[item.type],
                  onClick: () =>
                    patchColumnData({ column_type: item.type as ColumnType }),
                })),
              }),
            )}
            selectedItem={
              columnData?.column_type
                ? {
                    label: formatRawNodeTypeToDisplayName(
                      columnData.column_type,
                      ColumnTypes,
                    ),
                    icon: ColumnTypeIcon[columnData.column_type],
                  }
                : undefined
            }
            readonly={!editable}
          />
        </div>
      </div>
    </div>
  );
};
