import { NodeProps } from "@xyflow/react";
import { useMemo } from "react";

import { observer, Observer } from "mobx-react-lite";

import { useWorkflow } from "@/components/Workflows/WorkflowCanvas/workflow-context";

import { useNode } from "../../node-context";
import NodeBody from "./NodeBody";
import NodeFooter from "./NodeFooter";
import NodeHeader from "./NodeHeader";
import { getActiveStyle, getBackgroundColor, getIconColor } from "./utils";

export interface BasicNodeProps extends NodeProps {
  data: any;
  isConnectable: boolean;
  preview?: boolean;
  pending?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

export const BasicNode = observer(
  ({
    data,
    isConnectable,
    selectable,
    preview,
    pending,
    style,
    id,
    className,
  }: BasicNodeProps) => {
    const workflow = useWorkflow();

    return (
      <Observer>
        {() => {
          const node = useNode();
          const isSelected = selectable && workflow.isNodeSelected(id),
            isOutput = node?.is_output_node;
          const nodeErrors = node?.errors || [],
            hasErrors = nodeErrors.length > 0;
          const showHandles = !preview;

          const baseStyle = useMemo(() => {
            const backgroundColor = getBackgroundColor(
                pending,
                preview,
                hasErrors,
              ),
              activeStyle = getActiveStyle();
            return isSelected ? activeStyle : className || backgroundColor;
          }, [isSelected, hasErrors]);

          const { background: iconBackgroundColor, text: iconTextColor } =
            getIconColor(baseStyle);

          return (
            <div
              className={`border ${baseStyle} relative min-w-[400px] cursor-pointer rounded-md transition-all duration-300 ease-in-out ${
                preview ? "py-9" : "py-3"
              } shadow-md`}
              style={style}
              tabIndex={0}
            >
              {!preview && (
                <NodeHeader
                  type={data?.type}
                  isOutput={isOutput}
                  iconBackgroundColor={iconBackgroundColor}
                  iconTextColor={iconTextColor}
                />
              )}
              <NodeBody
                isConnectable={isConnectable}
                showHandles={showHandles}
                label={data.label}
              />
              <NodeFooter />
            </div>
          );
        }}
      </Observer>
    );
  },
);

export default BasicNode;
