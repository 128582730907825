import { SearchableDropdown } from "@/components/ui/searchable-dropdown";
import { ColumnCategories, ColumnTypes } from "@/constants/column-types";
import { ColumnType, ColumnTypeIcon } from "@/types/evaluate";
import { useReactFlow } from "@xyflow/react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { WorkflowNodeType } from "../../types";
import { useWorkflow } from "../workflow-context";
import { formatRawNodeTypeToDisplayName } from "../../utils";

interface NodeTypeSelectorProps {
  readonly?: boolean;
}

const NodeTypeSelector = (props: NodeTypeSelectorProps) => {
  const { readonly } = props;
  const { activeNode } = useWorkflow();
  const { updateNode } = useReactFlow();
  if (!activeNode) return null;

  const onSelect = (value: ColumnType) => {
    if (!activeNode || readonly) return;

    runInAction(() =>
      updateNode(activeNode.id, (node) => ({
        ...node,
        type: "basic",
        data: {
          type: value as unknown as WorkflowNodeType,
          label: activeNode.name,
          unsaved: true,
        },
      })),
    );
  };

  const currentType = activeNode.node_type;

  return (
    <SearchableDropdown
      triggerClassName="mb-4 w-full"
      categories={Object.entries(ColumnCategories).map(([label, items]) => ({
        label,
        items: items
          .filter((item) => item.type !== ColumnType.HUMAN)
          .map((item) => ({
            id: item.type,
            description: item.description,
            label: item.name,
            icon: ColumnTypeIcon[item.type],
            onClick: () => onSelect(item.type),
          })),
      }))}
      selectedItem={
        currentType
          ? {
              label: formatRawNodeTypeToDisplayName(currentType, ColumnTypes),
              icon: ColumnTypeIcon[currentType as unknown as ColumnType],
            }
          : undefined
      }
      readonly={readonly}
    />
  );
};

export default observer(NodeTypeSelector);
