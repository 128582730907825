import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Form,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useState } from "react";
import { useForm } from "react-hook-form";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  setDelete: () => void;
  itemName: string;
  itemType: "template" | "agent";
};

export const DeleteItem = (props: Props) => (
  <Dialog open={props.open} onOpenChange={props.setOpen}>
    <DialogContent>
      <DialogHeader>
        <DialogTitle>
          Are you sure you want to delete this {props.itemType}?
        </DialogTitle>
      </DialogHeader>
      <DeleteItemForm {...props} onSuccess={() => props.setOpen(false)} />
    </DialogContent>
  </Dialog>
);

const DeleteItemForm = (
  props: Props & {
    onSuccess: () => void;
  },
) => {
  const form = useForm();
  const [isDisabled, setIsDisabled] = useState(true);

  const onSubmit = async () => {
    props.setDelete();
    props.onSuccess();
  };

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            name="prompt_name"
            render={({ field }) => (
              <FormItem>
                <FormDescription className="text-gray-700">
                  This action cannot be undone.
                </FormDescription>
                <Input
                  className="italic"
                  placeholder={props.itemName}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    setIsDisabled(e.target.value !== props.itemName);
                  }}
                />
                <FormDescription>
                  To confirm, type <b>{props.itemName}</b> in the box above.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <DialogFooter className="mt-5">
            <Button
              type="button"
              onClick={() => props.setOpen(false)}
              className="bg-transparent text-sm"
              variant="outline"
            >
              Cancel
            </Button>
            <Button type="submit" variant="destructive" disabled={isDisabled}>
              Confirm Deletion
            </Button>
          </DialogFooter>
        </form>
      </Form>
    </>
  );
};
