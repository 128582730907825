import { EdgeStatusType } from "@/components/Workflows/types";

const springTransition = {
  type: "spring",
  stiffness: 500,
  damping: 30,
};

const springTransitionFast = {
  type: "spring",
  stiffness: 1000,
  damping: 15,
  mass: 0.1,
};

const getStatusColor = (status?: EdgeStatusType) => {
  switch (status) {
    case "PASSED":
      return "#22C55E"; // green-500
    case "FAILED":
      return "#EF4444"; // red-500
    /* case "SKIPPED":
     return "#9CA3AF"; // gray-400 */
    default:
      return "#2563EB"; // blue-600
  }
};

export { springTransition, getStatusColor, springTransitionFast };
