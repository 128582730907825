import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { CosineSimilarityConfiguration, ReportColumn } from "@/types/evaluate";
import { formatInputVariable } from "@/utils/evaluate";
import { useState } from "react";
import { useBandaid } from "../../utils/BandaidContext";
import { ModalStep } from "../ModalRouter";

const CosineSimilarityBuilder = ({
  columnData,
  availableColumns,
  navigatePrevious,
  saveColumnAction,
  editable,
}: {
  columnData: Partial<ReportColumn>;
  availableColumns: ReportColumn[];
  navigatePrevious: () => void;
  saveColumnAction: (newColumnDataArg: ReportColumn) => void;
  editable: boolean;
}) => {
  const [name, setName] = useState<string | null>(columnData.name || null);
  const [columnOneName, setColumnOneName] = useState<string | null>(
    columnData.configuration?.sources[0] || null,
  );
  const [columnTwoName, setColumnTwoName] = useState<string | null>(
    columnData.configuration?.sources[1] || null,
  );

  const [dataIsValid, setDataIsValid] = useState<{
    name: boolean;
    columnOne: boolean;
    columnTwo: boolean;
  }>({
    name: true,
    columnOne: true,
    columnTwo: true,
  });

  const saveEndpointAction = () => {
    if (!name) {
      setDataIsValid({ name: false, columnOne: true, columnTwo: true });
      return;
    } else if (!columnOneName) {
      setDataIsValid({ name: true, columnOne: false, columnTwo: true });
      return;
    } else if (!columnTwoName) {
      setDataIsValid({ name: true, columnOne: true, columnTwo: false });
      return;
    }

    setDataIsValid({ name: true, columnOne: true, columnTwo: true });

    saveColumnAction({
      ...columnData,
      name: name,
      configuration: {
        sources: [columnOneName, columnTwoName],
      } as CosineSimilarityConfiguration,
    } as ReportColumn);
  };

  const bandaid = useBandaid();

  return (
    <ModalStep
      navigatePrevious={navigatePrevious}
      navigateNext={saveEndpointAction}
      nextButtonText={editable ? "Save Step" : "Done"}
    >
      <div className="grid grid-cols-3 items-center gap-4">
        <div className="col-span-3">
          <div className="text-lg font-semibold">
            Cosine Similarity Comparison
          </div>
          <div className="max-w-md text-sm text-gray-500">
            This step calculates the cosine similarity between two{" "}
            {bandaid ? "nodes" : "columns"}.
          </div>
        </div>
        <label
          htmlFor="name"
          className="col-span-1"
          style={{ display: bandaid ? "none" : "" }}
        >
          {bandaid ? "Node" : "Column"} name:
        </label>
        <input
          id="name"
          className={`col-span-2 rounded border ${
            !dataIsValid.name ? "border-red-500" : "border-gray-300"
          } px-2 py-1 disabled:cursor-not-allowed disabled:bg-gray-50`}
          value={name || ""}
          onChange={(e) => setName(e.target.value)}
          disabled={!editable}
          style={{ display: bandaid ? "none" : "" }}
        />
        {!dataIsValid.name && (
          <>
            <div className="col-span-1 -mt-4">&nbsp;</div>
            <div className="col-span-2 -mt-4 text-left text-xs text-red-500">
              Name is required
            </div>
          </>
        )}
        <>
          <div className="col-span-1">
            Comparison {bandaid ? "nodes" : "columns"}:
          </div>
          <div className="col-span-2 rounded-md border border-gray-200 bg-gray-50 px-4 py-3">
            <div
              className="grid grid-cols-3 items-center space-x-2 space-y-2"
              key={`column-one`}
            >
              <div className="col-span-1 mr-2 mt-2 break-all text-right font-mono text-sm text-gray-600">
                First
              </div>
              <div className="col-span-2 w-full">
                <DropdownMenu>
                  <DropdownMenuTrigger disabled={!editable} className="w-full">
                    {columnOneName || (
                      <span className="font-normal text-gray-500">
                        Select a {bandaid ? "node" : "column"}...
                      </span>
                    )}
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    {availableColumns.map((column) => (
                      <DropdownMenuItem
                        key={column.name}
                        onSelect={() => setColumnOneName(column.name)}
                      >
                        {formatInputVariable(column.column_type, column.name)}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>
            {!dataIsValid.columnOne && (
              <>
                <div className="col-span-1 -mt-4">&nbsp;</div>
                <div className="col-span-2 -mt-4 text-left text-xs text-red-500">
                  First {bandaid ? "node" : "column"} input is required for
                  comparison
                </div>
              </>
            )}
            <div
              className="grid grid-cols-3 items-center space-x-2 space-y-2"
              key={`column-two`}
            >
              <div className="col-span-1 mr-2 mt-2 break-all text-right font-mono text-sm text-gray-600">
                Second
              </div>
              <div className="col-span-2 w-full">
                <DropdownMenu>
                  <DropdownMenuTrigger disabled={!editable} className="w-full">
                    {columnTwoName || (
                      <span className="font-normal text-gray-500">
                        Select a {bandaid ? "node" : "column"}...
                      </span>
                    )}
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    {availableColumns.map((column) => (
                      <DropdownMenuItem
                        key={column.name}
                        onSelect={() => setColumnTwoName(column.name)}
                      >
                        {formatInputVariable(column.column_type, column.name)}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>
            {!dataIsValid.columnTwo && (
              <>
                <div className="col-span-1 -mt-4">&nbsp;</div>
                <div className="col-span-2 -mt-4 text-left text-xs text-red-500">
                  Second {bandaid ? "node" : "column"} input is required for
                  comparison
                </div>
              </>
            )}
          </div>
        </>
      </div>
    </ModalStep>
  );
};

export default CosineSimilarityBuilder;
