import { useUser } from "@/context/user-context";

import { useAuth } from "@/context/auth-context";

import { useNavigate } from "react-router-dom";

import { useState } from "react";

import { LabelsSelectHookProps } from "../types";
import { useCreateABtest } from "@/queries";
import { promptLabel } from "@/schemas";
import { displayErrorToast } from "@/utils/toast";

export const useLabelsSelect = ({
  promptVersion,
  labels,
  setOpen,
  shouldNavigate,
}: LabelsSelectHookProps) => {
  const [selectedReleaseLabel, setSelectedReleaseLabel] =
    useState<promptLabel.Response>();
  const navigate = useNavigate();
  const auth = useAuth();
  const userToken = auth?.userToken!;
  const createABTest = useCreateABtest(userToken);
  const user = useUser();
  const workspaceId = user?.activeWorkspaceId!;
  const hasLabels = labels.length > 0;

  const handleClick = () => {
    createABTest.mutate(
      {
        promptId: promptVersion.prompt_id,
        promptVersionId: promptVersion.version_id,
        workspaceId,
        labelId: selectedReleaseLabel?.id,
      },
      {
        onSuccess: (res) => {
          if (res?.success && res?.release_label_group?.id) {
            navigate(
              `/workspace/${workspaceId}/ab-releases/prompt-release-group/${res?.release_label_group?.id}`,
            );
          } else {
            displayErrorToast(
              res?.message ?? "There's a problem with the AB Test request",
            );
          }
          setOpen?.(false);
        },
        onError: () => {
          if (shouldNavigate) {
            navigate(
              `/workspace/${workspaceId}/prompt/${promptVersion.prompt_id}/version/${promptVersion.version_id}`,
            );
          }
          displayErrorToast("There's a problem with the AB Test request");
          setOpen?.(false);
        },
      },
    );
  };

  return {
    selectedReleaseLabel,
    setSelectedReleaseLabel,
    handleClick,
    hasLabels,
    isABTestLoading: createABTest.isLoading,
  };
};
