import DropdownMenu from "@/components/DropdownMenu";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Workflow } from "../Workflows/types";

interface WorkflowCardProps {
  workflow: Workflow;
  workspaceId: string;
  onDelete: (workflow: Workflow) => void;
}

const WorkflowCard: React.FC<WorkflowCardProps> = ({
  workflow,
  workspaceId,
  onDelete,
}) => {
  const navigate = useNavigate();

  return (
    <li
      onClick={() =>
        navigate(`/workspace/${workspaceId}/workflow/${workflow.id}`)
      }
      className="group col-span-1 flex cursor-pointer flex-col rounded-lg border bg-white shadow-sm transition-all duration-300 hover:-translate-y-1 hover:shadow-md"
    >
      <div className="flex w-full items-center justify-between space-x-6 p-6">
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <svg
              width="60"
              height="30"
              viewBox="0 0 200 100"
              xmlns="http://www.w3.org/2000/svg"
              className="text-gray-400 transition-colors duration-300 group-hover:text-blue-500"
            >
              <circle cx="100" cy="50" r="10" fill="currentColor">
                <animate
                  attributeName="r"
                  values="10;12;10"
                  dur="1s"
                  repeatCount="indefinite"
                  begin="parentElement.parentElement.parentElement.parentElement.mouseover"
                  end="parentElement.parentElement.parentElement.parentElement.mouseout"
                />
              </circle>
              <circle cx="170" cy="30" r="10" fill="currentColor">
                <animate
                  attributeName="r"
                  values="10;12;10"
                  dur="1s"
                  repeatCount="indefinite"
                  begin="parentElement.parentElement.parentElement.parentElement.mouseover + 0.2s"
                  end="parentElement.parentElement.parentElement.parentElement.mouseout"
                />
              </circle>
              <circle cx="170" cy="70" r="10" fill="currentColor">
                <animate
                  attributeName="r"
                  values="10;12;10"
                  dur="1s"
                  repeatCount="indefinite"
                  begin="parentElement.parentElement.parentElement.parentElement.mouseover + 0.4s"
                  end="parentElement.parentElement.parentElement.parentElement.mouseout"
                />
              </circle>
              <circle cx="30" cy="50" r="10" fill="currentColor">
                <animate
                  attributeName="r"
                  values="10;12;10"
                  dur="1s"
                  repeatCount="indefinite"
                  begin="parentElement.parentElement.parentElement.parentElement.mouseover + 0.6s"
                  end="parentElement.parentElement.parentElement.parentElement.mouseout"
                />
              </circle>

              <line
                x1="110"
                y1="50"
                x2="160"
                y2="30"
                stroke="currentColor"
                strokeWidth="2"
              >
                <animate
                  attributeName="stroke-width"
                  values="2;3;2"
                  dur="1s"
                  repeatCount="indefinite"
                  begin="parentElement.parentElement.mouseover"
                  end="parentElement.parentElement.mouseout"
                />
              </line>
              <line
                x1="110"
                y1="50"
                x2="160"
                y2="70"
                stroke="currentColor"
                strokeWidth="2"
              >
                <animate
                  attributeName="stroke-width"
                  values="2;3;2"
                  dur="1s"
                  repeatCount="indefinite"
                  begin="parentElement.parentElement.mouseover + 0.2s"
                  end="parentElement.parentElement.mouseout"
                />
              </line>
              <line
                x1="90"
                y1="50"
                x2="40"
                y2="50"
                stroke="currentColor"
                strokeWidth="2"
              >
                <animate
                  attributeName="stroke-width"
                  values="2;3;2"
                  dur="1s"
                  repeatCount="indefinite"
                  begin="parentElement.parentElement.mouseover + 0.4s"
                  end="parentElement.parentElement.mouseout"
                />
              </line>
            </svg>

            <h3 className="truncate text-sm font-medium text-gray-900">
              {workflow.name}
            </h3>
          </div>
        </div>
        <DropdownMenu
          iconSize="5"
          menuItems={[
            {
              text: "Delete",
              onClick: (e) => {
                e.stopPropagation();
                onDelete(workflow);
              },
            },
          ]}
        />
      </div>
      <div className="border-t border-gray-100 bg-gray-50 px-6 py-4">
        <div className="flex items-center text-xs text-gray-500">
          <svg
            className="mr-1 h-4 w-4 text-gray-500 transition-colors duration-300 group-hover:text-yellow-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M13 10V3L4 14h7v7l9-11h-7z"
            />
          </svg>
          Click to view or edit agent
        </div>
      </div>
    </li>
  );
};

export default WorkflowCard;
