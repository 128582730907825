import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ReportColumn, XmlPathConfiguration } from "@/types/evaluate";
import { formatInputVariable } from "@/utils/evaluate";
import { useState } from "react";
import { useBandaid } from "../utils/BandaidContext";
import { ModalStep } from "./ModalRouter";

const XMLPathBuilder = ({
  columnData,
  availableColumns,
  navigatePrevious,
  saveColumnAction,
  editable,
}: {
  columnData: Partial<ReportColumn>;
  availableColumns: ReportColumn[];
  navigatePrevious: () => void;
  saveColumnAction: (newColumnDataArg: ReportColumn) => void;
  editable: boolean;
}) => {
  const [name, setName] = useState<string | null>(columnData.name || null);
  const [xmlPath, setXmlPath] = useState<string | null>(
    columnData.configuration?.xml_path ?? "",
  );
  const [type, setType] = useState<"find" | "findall">(
    columnData.configuration?.type ?? "find",
  );
  const [returnText, setReturnText] = useState(
    columnData.configuration?.return_text ?? false,
  );
  const [column, setColumn] = useState<string | null>(
    columnData.configuration?.source || null,
  );

  const [dataIsValid, setDataIsValid] = useState<{
    name: boolean;
    xmlPath: boolean;
    column: boolean;
  }>({
    name: true,
    xmlPath: true,
    column: true,
  });

  const saveEndpointAction = () => {
    if (!name) {
      setDataIsValid({ name: false, xmlPath: true, column: true });
      return;
    } else if (!xmlPath) {
      setDataIsValid({ name: true, xmlPath: false, column: true });
      return;
    } else if (!column) {
      setDataIsValid({ name: true, xmlPath: true, column: false });
      return;
    }

    setDataIsValid({ name: true, xmlPath: true, column: true });

    saveColumnAction({
      ...columnData,
      name: name,
      configuration: {
        xml_path: xmlPath,
        type,
        source: column,
      } as XmlPathConfiguration,
    } as ReportColumn);
  };

  const bandaid = useBandaid();

  return (
    <ModalStep
      navigatePrevious={navigatePrevious}
      navigateNext={saveEndpointAction}
      nextButtonText={editable ? "Save Step" : "Done"}
    >
      <div className="grid grid-cols-3 items-center gap-4">
        <div className="col-span-3">
          <div className="text-lg font-semibold">Configure XML Extraction</div>
          <div className="max-w-md text-sm text-gray-500">
            This step will parse a XML string and extract a value from it using
            XML Path.
          </div>
        </div>
        <label
          htmlFor="name"
          className="col-span-1"
          style={{ display: bandaid ? "none" : "" }}
        >
          Column name:
        </label>
        <input
          id="name"
          className={`col-span-2 rounded border ${
            !dataIsValid.name ? "border-red-500" : "border-gray-300"
          } px-2 py-1 disabled:cursor-not-allowed disabled:bg-gray-50`}
          value={name || ""}
          onKeyDown={(e) => {
            if (e.key === " ") {
              // Only prevent default and handle manually for spaces
              e.preventDefault();
              setName(name + " ");
            }
            // Let all other keys (including backspace) work normally
          }}
          onChange={(e) => setName(e.target.value)}
          disabled={!editable}
          style={{ display: bandaid ? "none" : "" }}
        />
        {!dataIsValid.name && (
          <>
            <div className="col-span-1 -mt-4">&nbsp;</div>
            <div className="col-span-2 -mt-4 text-left text-xs text-red-500">
              Name is required
            </div>
          </>
        )}

        <label htmlFor="xml-path" className="col-span-1">
          XML Path:
        </label>
        <input
          id="xml-path"
          className={`col-span-2 rounded border ${
            !dataIsValid.xmlPath ? "border-red-500" : "border-gray-300"
          } px-2 py-1 disabled:cursor-not-allowed disabled:bg-gray-50`}
          value={xmlPath ?? ""}
          onChange={(e) => setXmlPath(e.target.value)}
          disabled={!editable}
          placeholder="e.g. .//element"
        />
        {!dataIsValid.xmlPath && (
          <>
            <div className="col-span-1 -mt-4">&nbsp;</div>
            <div className="col-span-2 -mt-4 text-left text-xs text-red-500">
              XML Path is required
            </div>
          </>
        )}

        <div className="col-span-1">&nbsp;</div>
        <div className="col-span-2 flex items-center gap-2">
          <input
            type="checkbox"
            id="find-all-matches"
            checked={type === "findall"}
            onChange={(e) => setType(e.target.checked ? "findall" : "find")}
            disabled={!editable}
            className="cursor-pointer"
          />
          <label htmlFor="find-all-matches" className="cursor-pointer text-sm">
            Find all matches
          </label>
        </div>

        {/* <div className="col-span-1">&nbsp;</div> */}
        {/* Unhide this if needed in the future */}
        <div className="col-span-2 hidden items-center gap-2">
          <input
            type="checkbox"
            id="return-text"
            checked={returnText}
            onChange={(e) => setReturnText(e.target.checked)}
            disabled={!editable}
            className="cursor-pointer"
          />
          <label htmlFor="return-text" className="cursor-pointer text-sm">
            Return text
          </label>
        </div>

        <label htmlFor="column" className="col-span-1">
          {bandaid ? "Node:" : "Column:"}
        </label>
        <DropdownMenu>
          <DropdownMenuTrigger
            disabled={!editable}
            className="col-span-2 w-full"
          >
            {column || (
              <span className="font-normal text-gray-500">
                Select a {bandaid ? "node" : "column"}...
              </span>
            )}
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {availableColumns.map((column) => (
              <DropdownMenuItem
                key={column.name}
                onSelect={() => setColumn(column.name)}
              >
                {formatInputVariable(column.column_type, column.name)}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
        {!dataIsValid.column && (
          <>
            <div className="col-span-1 -mt-4">&nbsp;</div>
            <div className="col-span-2 -mt-4 text-left text-xs text-red-500">
              {bandaid ? "Node" : "Column"} is required
            </div>
          </>
        )}
      </div>
    </ModalStep>
  );
};

export default XMLPathBuilder;
