import { EdgeStatusType } from "@/components/Workflows/types";
import { motion } from "framer-motion";
import { observer } from "mobx-react-lite";
import { useEdge } from "../../ContextMenu/edge-conditions-context";
import { getStatusColor, springTransition } from "./utils";

const EdgeContent = ({ conditions }: { conditions: any[] }) => {
  const edge = useEdge();
  const status = edge?.status as EdgeStatusType;

  return (
    <>
      <motion.path
        d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0 0 12 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52 2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 0 1-2.031.352 5.988 5.988 0 0 1-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971Zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0 2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 0 1-2.031.352 5.989 5.989 0 0 1-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971Z"
        stroke={getStatusColor(status)}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        style={{ transformOrigin: "center center" }}
        initial={{ pathLength: 0, opacity: 0, scale: 0 }}
        animate={{
          pathLength: 1,
          opacity: 1,
          scale: 0.65,
          x: -14,
          y: -12,
        }}
        exit={{ pathLength: 0, opacity: 0, scale: 0 }}
        transition={{
          pathLength: {
            type: "spring",
            duration: 1,
            bounce: 0.3,
          },
          opacity: { duration: 0.2 },
          scale: {
            type: "spring",
            duration: 0.8,
            bounce: 0.4,
          },
        }}
        className="pointer-events-none"
      />
      <motion.text
        textAnchor="middle"
        dy=".3em"
        dx={conditions.length > 9 ? "18" : "12"}
        fill={getStatusColor(status)}
        fontSize="14"
        fontWeight="600"
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0, opacity: 0 }}
        transition={{
          delay: 0.1,
          ...springTransition,
        }}
        className="pointer-events-none select-none"
      >
        {conditions.length}
      </motion.text>
    </>
  );
};

export default observer(EdgeContent);
