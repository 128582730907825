import { usePdfBlobManager } from "@/hooks/usePdfBlobManager";
import { Message } from "@/types";
import { truncate } from "@/utils/strings";
import { getStringContent } from "@/utils/utils";
import { ReactNode, useMemo } from "react";
import { CopyButton } from "../ui/copy-button";

const renderMediaContent = (
  content: any,
  index: number,
  pdfBlobUrls: Record<number, string>,
  generateBlobUrl: (index: number, url: string) => void,
): ReactNode => {
  if (content.type === "media") {
    const { media } = content;
    if (media.type.startsWith("image")) {
      return <img key={`media.${index}`} src={media.url} alt="content" />;
    }
    if (media.type.startsWith("audio")) {
      return <audio key={`media.${index}`} src={media.url} controls />;
    }
    if (media.type.startsWith("video")) {
      return <video key={`media.${index}`} src={media.url} controls />;
    }
    if (["application/pdf", "text/plain"].includes(media.type)) {
      if (media.type === "application/pdf") {
        generateBlobUrl(index, media.url);
        return (
          <iframe
            key={`media.${index}`}
            title={media.title}
            src={pdfBlobUrls[index] || media.url}
            width="100%"
            height="500px"
          />
        );
      }
      return (
        <iframe
          key={`media.${index}`}
          title={media.title}
          src={media.url}
          width="100%"
          height="500px"
        />
      );
    }
    return (
      <a
        key={`media.${index}`}
        href={media.url}
        target="_blank"
        rel="noreferrer"
      >
        {media.title}
      </a>
    );
  }
  return null;
};

export const NotAction = ({
  componentIsOpen,
  highlight,
  highlightedContent,
  message,
}: {
  componentIsOpen: boolean;
  highlight: boolean;
  highlightedContent: string | React.ReactNode | null;
  message?: Message;
}) => {
  const [pdfBlobUrls, generateBlobUrl] = usePdfBlobManager(message?.content);

  const displayedMessageContent = useMemo((): ReactNode | null => {
    return message?.content?.map((content, index) => {
      if (content.type === "text") {
        return (
          <div key={`text.${index}`}>
            {highlightedContent ? highlightedContent : content.text}
          </div>
        );
      } else if (componentIsOpen) {
        const validPdfBlobUrls: Record<number, string> = pdfBlobUrls || {};
        return renderMediaContent(
          content,
          index,
          validPdfBlobUrls,
          generateBlobUrl,
        );
      }
      return null;
    });
  }, [
    highlightedContent,
    componentIsOpen,
    message,
    pdfBlobUrls,
    generateBlobUrl,
  ]);
  const getPreviewContent = (): ReactNode => {
    const contentPart = message?.content?.at(0);
    if (!contentPart) return null;

    if (contentPart.type === "image_url") {
      return <span className="italic">[Image]</span>;
    }

    if (contentPart.type === "text") {
      return truncate(contentPart.text, 200);
    }
  };

  return (
    <>
      {!componentIsOpen ? (
        <div className="group-hover:inherit pt-2 text-left">
          {getPreviewContent()}
        </div>
      ) : (
        <div className="flex items-start justify-between">
          <div className="w-full">
            <span className={highlight ? "bg-blue-100 " : ""}>
              {displayedMessageContent}
            </span>
          </div>
          <div>
            <CopyButton text={message ? getStringContent(message) : ""} />
          </div>
        </div>
      )}
    </>
  );
};
