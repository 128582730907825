import { LockClosedIcon } from "@heroicons/react/outline";

const ViewOnlyBanner = ({
  message = "Batch evaluation has already been started and is not editable.",
}) => {
  return (
    <div className="w-34 mx-auto my-4 rounded-md bg-gray-100 p-2 text-xs text-gray-600">
      <LockClosedIcon className="mr-1 inline-block h-4 w-4" />
      [View-Only] {message}
    </div>
  );
};

export default ViewOnlyBanner;
