import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { SearchIcon } from "@heroicons/react/outline";
import { KeyboardEvent, useCallback, useEffect, useRef, useState } from "react";
import { Input } from "./input";

interface Category {
  label: string;
  items: Array<{
    id: string;
    label: string;
    description?: string;
    icon?: React.ComponentType<{ className?: string }>;
    onClick?: () => void;
  }>;
}

interface SearchableDropdownProps {
  categories: Category[];
  selectedItem?: {
    label: string;
    description?: string;
    icon?: React.ComponentType<{ className?: string }>;
  };
  readonly?: boolean;
  onSelect?: (item: any) => void;
  className?: string;
  triggerClassName?: string;
}

const useKeyboardNavigation = (
  onSelect: (item: any) => void,
  setOpen: (open: boolean) => void,
  setSearchQuery: (query: string) => void,
) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [hasNavigated, setHasNavigated] = useState(false);
  const [filteredItems, setFilteredItems] = useState<Array<any>>([]);
  const listRef = useRef<HTMLDivElement>(null);

  const updateFilteredItems = useCallback((items: any[]) => {
    setFilteredItems(items);
    setActiveIndex(-1);
    setHasNavigated(false);
  }, []);

  const scrollIntoView = useCallback((index: number) => {
    const listElement = listRef.current;
    if (!listElement) return;

    const itemElements = listElement.querySelectorAll('[role="option"]');
    const itemElement = itemElements[index] as HTMLElement;
    if (!itemElement) return;

    const listRect = listElement.getBoundingClientRect();
    const itemRect = itemElement.getBoundingClientRect();
    const offset = 4; // Small offset to prevent exact edge alignment

    if (itemRect.bottom > listRect.bottom) {
      listElement.scrollTop += itemRect.bottom - listRect.bottom + offset;
    } else if (itemRect.top < listRect.top) {
      listElement.scrollTop += itemRect.top - listRect.top - offset;
    }
  }, []);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLElement>) => {
      e.stopPropagation();
      switch (e.key) {
        case "ArrowDown":
          e.preventDefault();
          setHasNavigated(true);
          setActiveIndex((prev) => {
            const nextIndex = (prev + 1) % filteredItems.length;
            scrollIntoView(nextIndex);
            return nextIndex;
          });
          break;
        case "ArrowUp":
          e.preventDefault();
          setHasNavigated(true);
          setActiveIndex((prev) => {
            const nextIndex = prev <= 0 ? filteredItems.length - 1 : prev - 1;
            scrollIntoView(nextIndex);
            return nextIndex;
          });
          break;
        case "Enter":
          e.preventDefault();
          if (activeIndex >= 0) {
            onSelect(filteredItems[activeIndex]);
            filteredItems[activeIndex].onClick?.();
            setSearchQuery("");
            setOpen(false);
          } else if (filteredItems.length > 0) {
            onSelect(filteredItems[0]);
            filteredItems[0].onClick?.();
            setSearchQuery("");
            setOpen(false);
          }
          break;
        case "Tab":
          e.preventDefault();
          setHasNavigated(true);
          if (e.shiftKey) {
            setActiveIndex((prev) => {
              const nextIndex = prev <= 0 ? filteredItems.length - 1 : prev - 1;
              scrollIntoView(nextIndex);
              return nextIndex;
            });
          } else {
            setActiveIndex((prev) => {
              const nextIndex = (prev + 1) % filteredItems.length;
              scrollIntoView(nextIndex);
              return nextIndex;
            });
          }
          break;
        case "Escape":
          e.preventDefault();
          setSearchQuery("");
          setOpen(false);
          break;
        default:
          setActiveIndex(-1);
          break;
      }
    },
    [
      activeIndex,
      filteredItems,
      setOpen,
      scrollIntoView,
      onSelect,
      setSearchQuery,
    ],
  );

  return {
    activeIndex,
    hasNavigated,
    updateFilteredItems,
    handleKeyDown,
    listRef,
  };
};

export function SearchableDropdown({
  categories,
  selectedItem,
  readonly = false,
  onSelect = () => {},
  className = "w-[var(--radix-dropdown-menu-trigger-width)]",
  triggerClassName = "w-full",
}: SearchableDropdownProps) {
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    activeIndex,
    hasNavigated,
    updateFilteredItems,
    handleKeyDown,
    listRef,
  } = useKeyboardNavigation(onSelect, setOpen, setSearchQuery);

  const filterItems = useCallback(
    (items: Category["items"]) => {
      return items.filter(
        (item) =>
          item.label.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.description?.toLowerCase().includes(searchQuery.toLowerCase()),
      );
    },
    [searchQuery],
  );

  const getAllFilteredItems = useCallback(() => {
    return categories.flatMap((category) => filterItems(category.items));
  }, [categories, filterItems]);

  useEffect(() => {
    updateFilteredItems(getAllFilteredItems());
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
  }, [
    searchQuery,
    categories,
    getAllFilteredItems,
    updateFilteredItems,
    listRef,
  ]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  }, [open]);

  const SelectedIcon = selectedItem?.icon;

  return (
    <DropdownMenu open={readonly ? false : open} onOpenChange={setOpen}>
      <DropdownMenuTrigger disabled={readonly} className={triggerClassName}>
        <div className="flex w-full items-center justify-between">
          <div className="inline-flex items-center space-x-2.5">
            {SelectedIcon && <SelectedIcon className="h-5 w-5 text-gray-500" />}
            <span className="text-sm font-medium text-gray-700">
              {selectedItem?.label || "Select..."}
            </span>
          </div>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className={`${className} overflow-hidden px-3 py-1 pb-0 shadow-lg`}
        align="start"
      >
        <div className="relative mb-2 py-1">
          <Input
            ref={inputRef}
            type="text"
            placeholder="Search..."
            className="w-full pl-9 pr-3"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <SearchIcon className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-400" />
        </div>

        <div
          ref={listRef}
          style={{ maxHeight: "300px", overflowY: "auto", overflowX: "hidden" }}
        >
          {categories.map((category) => {
            const filteredItems = filterItems(category.items);

            if (filteredItems.length === 0) return null;

            return (
              <div key={category.label}>
                <DropdownMenuLabel className="px-1.5 text-xs font-semibold uppercase tracking-wider text-gray-500">
                  {category.label}
                </DropdownMenuLabel>
                {filteredItems.map((item) => {
                  const ItemIcon = item.icon;
                  const itemIndex = getAllFilteredItems().indexOf(item);
                  const isActive = !!(
                    itemIndex === activeIndex ||
                    (!hasNavigated &&
                      searchQuery &&
                      item.label
                        .toLowerCase()
                        .startsWith(searchQuery.toLowerCase()) &&
                      itemIndex === 0)
                  );
                  return (
                    <div
                      key={item.id}
                      tabIndex={0}
                      role="option"
                      aria-selected={isActive}
                      className={`my-0.5 flex cursor-pointer items-center rounded-md px-2 py-1.5 text-sm outline-none transition-colors duration-150 ${
                        isActive ? "bg-gray-100" : "hover:bg-gray-50"
                      } focus:bg-gray-100 active:bg-gray-100`}
                      onClick={() => {
                        onSelect(item);
                        setSearchQuery("");
                        item.onClick?.();
                        setOpen(false);
                      }}
                      onKeyDown={handleKeyDown}
                    >
                      <div>
                        <div className="flex items-center">
                          {ItemIcon && (
                            <ItemIcon className="mr-2.5 h-4 w-4 stroke-[1.5] text-gray-500" />
                          )}
                          <span className="font-medium text-gray-700">
                            {item.label}
                          </span>
                        </div>
                        {item.description && (
                          <div className="ml-6.5 mt-0.5 text-xs text-gray-400">
                            {item.description}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}

                <DropdownMenuSeparator className="my-2 opacity-50" />
              </div>
            );
          })}
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
