import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Form,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useAuth } from "@/context/auth-context";
import {
  useCreatePromptLabel,
  usePromptLabel,
  usePromptLabelsPatch,
} from "@/queries";
import { promptLabel as schema } from "@/schemas";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import LoadingSpinner from "./LoadingSpinner";

type Props = {
  promptId: number;
  versionId: number;
  open: boolean;
  setOpen: (open: boolean) => void;
  onSuccess: () => void;
};

export const CreatePromptLabel = (props: Props) => (
  <Dialog open={props.open} onOpenChange={props.setOpen}>
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Create a Label</DialogTitle>
      </DialogHeader>
      <LabelForm {...props} />
    </DialogContent>
  </Dialog>
);

const LabelForm = (
  props: Props & {
    onSuccess: () => void;
  },
) => {
  const userToken = useAuth()?.userToken || "";
  const [openConfirm, setOpenConfirm] = useState(false);
  const createLabel = useCreatePromptLabel(userToken);
  const patch = usePromptLabelsPatch(userToken);
  const promptLabel = usePromptLabel();
  const form = useForm<schema.Create>({
    defaultValues: {
      prompt_id: props.promptId,
      name: "",
    },
    resolver: zodResolver(schema.create),
  });

  const onContinue = (values: schema.Create) => {
    if (values.promptLabel) {
      patch.mutate(
        {
          promptId: props.promptId,
          versionId: props.versionId,
          id: values.promptLabel.id,
          name: values.promptLabel.name,
        },
        {
          onSuccess: () => {
            setOpenConfirm(false);
            props.onSuccess();
          },
        },
      );
    }
  };

  const onSubmit = async (values: schema.Create) => {
    const prompt = await promptLabel.mutateAsync({
      prompt_id: props.promptId,
      name: values.name,
      authToken: userToken,
    });
    if (prompt) {
      form.setValue("promptLabel", prompt);
      setOpenConfirm(true);
      return;
    }
    createLabel.mutate(
      {
        promptId: props.promptId,
        versionId: props.versionId,
        name: values.name,
      },
      {
        onSuccess: () => {
          props.onSuccess();
        },
      },
    );
  };

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <Input placeholder="+ Add release label..." {...field} />
                <FormDescription>
                  This value will be used to uniquely identify a prompt version.
                  For example, label a version as "prod".
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <DialogFooter>
            <Button
              disabled={createLabel.isLoading}
              type="submit"
              className="mt-3"
            >
              {createLabel.isLoading && <LoadingSpinner size={3} />}
              Create Label
            </Button>
          </DialogFooter>
        </form>
      </Form>
      <AlertDialog open={openConfirm} onOpenChange={setOpenConfirm}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Are you sure you want to move the label?
            </AlertDialogTitle>
            <AlertDialogDescription>
              The selected label name is already in use. This action will remove
              the existing release label and add it to the selected prompt
              version.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              disabled={patch.isLoading}
              onClick={form.handleSubmit(onContinue)}
            >
              {patch.isLoading && <LoadingSpinner size={3} />}
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
