import { EdgeCondition } from "@/components/Workflows/types";
import { getAllLinkedDependencies } from "@/components/Workflows/utils";
import { TrashIcon, XCircleIcon } from "@heroicons/react/solid";
import { useReactFlow } from "@xyflow/react";
import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";
import { v4 } from "uuid";
import { useWorkflow } from "../../workflow-context";
import { useHistory } from "../hooks/useUndoRedo";
import Conditions from "./Conditions";
import { EdgeContextProvider } from "./edge-conditions-context";

interface EdgeContextMenuProps {
  id: string;
  onClose?: () => void;
}

const EdgeContextMenu: React.FC<EdgeContextMenuProps> = ({ id, onClose }) => {
  const { setEdges } = useReactFlow();
  const { takeSnapshot } = useHistory();

  const workflow = useWorkflow();
  const { readonly } = workflow;
  const edge = workflow.getEdge(id);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const conditions = edge?.conditionals || [];
  const targetNode = workflow.getNodeById(edge?.target_node_name!);

  const linkedDependencies = useMemo(
    () =>
      getAllLinkedDependencies(
        workflow.getNodeById.bind(workflow),
        targetNode?.id!,
      ),
    [workflow, targetNode?.id],
  );

  const deleteEdge = useCallback(() => {
    takeSnapshot();
    if (edge) workflow.removeEdge(edge.id);
    setEdges((edges) => edges.filter((edge) => edge.id !== id));
    onClose?.();
  }, [takeSnapshot, edge, workflow, setEdges, onClose, id]);

  const addCondition = useCallback(() => {
    const newCondition: EdgeCondition = {
      id: v4(),
      position: conditions.length,
      left_config: {
        source: {
          name: "",
        },
      },
      operator: "=",
      right_config: {
        static_value: "",
      },
    };
    workflow.addCondition(id, newCondition);
  }, [workflow, id, conditions.length]);

  const updateCondition = useCallback(
    (
      position: number,
      field: keyof EdgeCondition,
      value: string | (typeof conditions)[number][keyof EdgeCondition],
    ) => {
      const updatedCondition = conditions.find((c) => c.position === position);
      if (updatedCondition) {
        workflow.setCondition(id, { ...updatedCondition, [field]: value });
      }
    },
    [workflow, id, conditions],
  );

  const removeCondition = useCallback(
    (conditionId: string) => {
      workflow.removeCondition(id, conditionId);
    },
    [workflow, id],
  );

  const clearConditions = useCallback(() => {
    takeSnapshot();
    workflow.clearConditions(id);
  }, [workflow, id, takeSnapshot]);

  return (
    <div className="w-[600px]">
      <EdgeContextProvider
        value={{
          linkedDependencies,
          edge,
        }}
      >
        <Conditions
          conditions={conditions}
          onAddCondition={addCondition}
          onUpdateCondition={updateCondition}
          onRemoveCondition={removeCondition}
          readonly={readonly}
        />
        {!readonly && (
          <div className="mt-2 border-t border-gray-200">
            {conditions.length > 0 && (
              <button
                className="inline-flex w-full items-center px-4 py-2 text-left text-sm text-gray-600 hover:bg-gray-100"
                onClick={clearConditions}
              >
                <XCircleIcon className="mr-2 h-4 w-auto text-gray-600" />
                Clear All Conditions
              </button>
            )}
            <button
              className="inline-flex w-full items-center px-4 py-2 text-left text-sm text-red-600 hover:bg-gray-100"
              onClick={deleteEdge}
            >
              <TrashIcon className="mr-2 h-4 w-auto text-red-600" />
              Delete Edge
            </button>
          </div>
        )}
      </EdgeContextProvider>
    </div>
  );
};

export default observer(EdgeContextMenu);
