import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useSchemaEditor } from ".";
import { Switch } from "../switch";
import { Textarea } from "../ui/textarea";

const AutomaticSwitch = observer(({ isReadonly }: { isReadonly: boolean }) => {
  const schemaEditorStore = useSchemaEditor();
  const isChecked = schemaEditorStore.isStandardSchema;

  return (
    <div className="flex items-center gap-2 rounded-md bg-white px-2 py-1">
      <span className="text-sm text-gray-500">Manual</span>
      <Switch
        size="sm"
        checked={isChecked}
        onChange={(value) => {
          runInAction(() => schemaEditorStore.setAutomatic(!isChecked));
        }}
        disabled={isReadonly}
      />
      <span className="text-sm text-gray-500">Automatic</span>
    </div>
  );
});

const JsonEditor = observer(() => {
  const schemaEditorStore = useSchemaEditor();
  const isReadonly = schemaEditorStore.isReadonly;

  const [isJSONEditorFocused, setJSONEditorFocused] = useState(false);
  const [temporaryJSONValue, setTemporaryJSONValue] = useState(
    JSON.stringify(schemaEditorStore.definition, null, 2),
  );
  const [invalidJSON, setInvalidJSON] = useState(false);

  const jsonValue = JSON.stringify(schemaEditorStore.definition, null, 2);

  useEffect(() => {
    // If the user truly blurs away (not just clicking X),
    // we reset the temporary value back to store's definition.
    if (!isJSONEditorFocused) {
      setTemporaryJSONValue(jsonValue);
    }
  }, [isJSONEditorFocused, jsonValue]);

  return (
    <div className="relative h-full">
      <div className="absolute top-2 flex w-full items-center justify-between gap-2 px-2">
        <div className="flex items-center gap-2">
          {invalidJSON && (
            <div className="flex items-center gap-2 rounded-md bg-white px-2 py-1 text-red-500">
              <span className="text-sm">Invalid JSON format</span>
            </div>
          )}
        </div>
        <AutomaticSwitch isReadonly={isReadonly} />
      </div>
      <Textarea
        className={`h-full min-h-[500px] flex-1 overflow-auto pt-10 font-mono ${
          invalidJSON ? "!border-red-500" : ""
        }`}
        onFocus={() => !isReadonly && setJSONEditorFocused(true)}
        onBlur={() => {
          setJSONEditorFocused(false);
          setInvalidJSON(false);
        }}
        onChange={(e) => {
          if (isReadonly) return;
          try {
            setTemporaryJSONValue(e.target.value);
            schemaEditorStore.applyJsonToState(
              JSON.parse(e.target.value),
              schemaEditorStore.isStandardSchema,
            );
            if (invalidJSON) setInvalidJSON(false);
          } catch (err) {
            setInvalidJSON(true);
          }
        }}
        value={
          isJSONEditorFocused
            ? temporaryJSONValue
            : JSON.stringify(schemaEditorStore.definition, null, 2)
        }
        readOnly={isReadonly}
      />
    </div>
  );
});

export default JsonEditor;
