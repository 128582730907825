import Checkbox from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { observer } from "mobx-react-lite";
import { useSchemaEditor } from "..";
import { SchemaParameterType } from "../types";
import TypeSelector from "./TypeSelector";

interface ParameterMetadataProps {
  name: string;
  type: SchemaParameterType;
  title: string;
  required: boolean;
  onChangeName: (value: string) => void;
  onChangeType: (value: SchemaParameterType) => void;
  onChangeTitle: (value: string) => void;
  onChangeRequired: (value: boolean) => void;
}
const ParameterMetadata = observer((props: ParameterMetadataProps) => {
  const editor = useSchemaEditor();
  const isReadonly = editor.isReadonly;

  return (
    <div className="flex w-full flex-col justify-between gap-2 sm:flex-row">
      <Checkbox
        id="required-checkbox"
        checked={props.required}
        onChange={(e) => props.onChangeRequired(e.target.checked)}
        label="Required"
        disabled={isReadonly}
      />
      <Input
        placeholder="Name"
        value={props.name}
        onChange={(e) => props.onChangeName(e.target.value)}
        readOnly={isReadonly}
      />
      <div className="flex flex-row gap-2">
        <TypeSelector
          value={props.type}
          onChange={props.onChangeType}
          disabled={isReadonly}
        />
        <Input
          placeholder="Title"
          value={props.title}
          className="min-w-[100px]"
          onChange={(e) => props.onChangeTitle(e.target.value)}
          readOnly={isReadonly}
        />
      </div>
    </div>
  );
});

export default ParameterMetadata;
