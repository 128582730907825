const Checkbox = ({
  id = "",
  label = "",
  checked = false,
  onChange = (e: any) => {},
  onClick = () => {},
  disabled = false,
  required = false,
  error = false,
  className = "",
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
    onClick();
  };

  return (
    <div className={`flex items-center gap-2 space-y-0 ${className}`}>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
        required={required}
        className={`
          cursor-pointer rounded text-blue-600
          focus:ring-blue-500
          disabled:cursor-not-allowed disabled:opacity-70
          ${error ? "border-red-500" : ""}
        `}
      />
      {label && (
        <label
          htmlFor={id}
          className={`
            cursor-pointer select-none text-xs
            leading-none
            peer-disabled:cursor-not-allowed peer-disabled:opacity-70
          `}
          onClick={
            !disabled
              ? (e: React.MouseEvent<HTMLLabelElement>) => {
                  e.preventDefault();
                  handleChange({
                    target: { checked: !checked },
                  } as React.ChangeEvent<HTMLInputElement>);
                }
              : undefined
          }
        >
          {label}
          {required && <span className="ml-1 text-red-500">*</span>}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
